import React, { useState } from 'react';
import { Input as AntInput, AutoComplete as AntAutoComplete, Form as AntForm, DatePicker as AntDatePicker, Select as AntSelect, Checkbox as AntCheckbox, Radio as AntRadio } from 'antd';
import { CheckSquareOutlined, LinkOutlined, EyeOutlined, EyeInvisibleOutlined, DeleteFilled } from '@ant-design/icons';
import { Button, IconButton } from "../Button";
import dayjs from 'dayjs';
import "./style.scss"

const Minutes = [
  { value: 0, label: "00" },
  { value: 0.25, label: "15" },
  { value: 0.5, label: "30" },
  { value: 0.75, label: "45" },
]

const getValueToDuration = (value) => {
  let res = { hr: 0, min: null };
  if ((typeof value) === "object") {
    res = { ...value };
  } else if ((typeof value) === "number") {
    res.hr = Math.floor(value);
    res.min = value - res.hr
  }
  return res;
}
export const getDurationToValue = (value) => {
  let res = null
  if (value) {
    if ((typeof value === 'number')) {
      res = value
    } else if ((typeof value === 'object')) {
      res = (value.hr || 0) + (value.min || 0);
    }
  }
  return res;
}
const getMaxLengthRule = (maxLength) => {
  let max = null
  if (maxLength !== undefined) {
    max = {
      show: true, max: maxLength,
      strategy: (txt) => String(txt).split('').length,
      exceedFormatter: (txt, { max }) => String(txt).split('').slice(0, max).join(''),
    }
  }
  return max
}

const Label = (props) => {
  const { text, required, className } = props;
  const showColon = props.showColon !== undefined ? props.showColon : false;
  return (
    (text && text.length > 0) ? <span className={`f14 c00085 reg label ${className} ${required ? 'req' : ''}`}>{text}{showColon && ':'}</span> : null
  )
}

const HelpText = (props) => {
  const { helpText, error, type } = props;
  return (
    (Boolean(helpText) || Boolean(error)) ?
      <div className='col error'>
        {
          String(error || '').trim().length > 0 &&
          <React.Fragment>
            <span className='f12 cF2665E'>{error}</span><br />
          </React.Fragment>
        }
        {
          String(helpText || '').trim().length > 0 &&
          <React.Fragment>
            <span dangerouslySetInnerHTML={{ __html: helpText }} className='f10 c989898'></span>
            {
              type === "suffix-pass" &&
              <span className='f12 c989898'>Click <CheckSquareOutlined style={{ fontSize: "10px", color: '#989898' }} /> to copy the password to clipboard</span>
            }
          </React.Fragment>
        }
      </div>
      :
      null
  )
}

const TextInput = React.forwardRef((props, ref) => {
  const { type, id, label, placeholder, name, value, onChange, readOnly, maxLength, className, rootClassName, showColon, regex, ...rest } = props;
  const count = getMaxLengthRule(maxLength);
  const overflow = rest.overflow !== undefined ? rest.overflow : true
  const _className = `${rootClassName || 'text-input'} border ${Boolean(count) ? 'no-pad' : ''} ${props.font || 'f14'} ${readOnly ? 'read-only' : ''} ${overflow ? '' : 'txt-no-wrap'} ${className || ''}`;
  return (
    Boolean(readOnly) ?
      <div className={_className} title={!overflow ? value : ''}>{value}</div>
      :
      <AntInput
        ref={ref}
        type={type}
        name={name}
        autoFocus={props.autoFocus}
        className={_className}
        classNames={{ input: 'input-ele', suffix: 'input-suffix' }}
        id={`for-${id || name}`}
        value={value}
        onChange={onChange}
        count={count}
        placeholder={placeholder}
        {...rest} />
  );
})
const TextArea = (props) => {
  const { id, label, placeholder, name, value, onChange, readOnly, maxLength, className, showColon, ...rest } = props;
  const _className = `textarea-input border ${props.font || 'f14'} ${readOnly ? 'read-only' : ''} ${className || ''}`;
  return (
    Boolean(readOnly) ?
      <div className={_className}>{value}</div>
      :
      <AntInput.TextArea
        type='text'
        name={name}
        value={value}
        showCount={maxLength > 0}
        maxLength={maxLength}
        id={`for-${id || name}`}
        className={_className}
        onChange={onChange}
        placeholder={placeholder}
        {...rest} />
  )
}
const Select = (props) => {
  const { optionTemplate, label, optionLabelProp, options, value, onChange, name, placeholder, allowSearch, caretColor, disabled, multiple } = props;
  const propForLabel = optionLabelProp || 'label';
  const handleChange = (newVal) => {
    let value = newVal
    if (!multiple) {
      value = options.find(({ id }) => id === newVal);
    }
    if (Array.isArray(value)) {
      onChange && onChange({ target: { name, value: value } })
    }
  }
  const UserTemplate = ({ user, className }) => {
    return (
      <div className=''>
        <span className={`f14 c0008 5${className || ''}`}>{user[propForLabel]}</span> <br />
        <span className={`f12 c00045`}>{user.designation}, {user.department}</span>
      </div>
    )
  }
  const getValue = () => {
    if (multiple) {
      return value || [];
    }
    return (value && value.id) ? value[propForLabel] : null
  }
  const config = {
    disabled, placeholder,
    onChange: handleChange,
    showSearch: allowSearch,
    popupClassName: 'select-popup',
    value: getValue(),
    rootClassName: `select-input ${value ? value.className : ''}`,
    suffixIcon: <span className={allowSearch ? 'f16 icon-search c0133CC' : `f8 icon-caret-down ${caretColor || "cD9D9D9"}`}></span>,
    options: (options || []).map((_) => ({ ..._, value: _.id })),
    optionRender: (option) => {
      return (
        optionTemplate === 'USER' ?
          <UserTemplate user={option.data} className={option.className} key={option.key} />
          :
          <span className={`f14 ${option.className || ''}`} key={option.key}>{option[propForLabel]}</span>
      )
    },
    filterOption: (inputValue, option) => option[propForLabel].toLowerCase().indexOf(inputValue.toLowerCase()) !== -1,
    notFoundContent: (
      <div className='select-no-found col v-ctr h-ctr'>
        <span className='h12 text-center c777'>No Matching {(label) || 'Result'}</span>
      </div>
    )
  }
  if (multiple) {
    config.mode = 'multiple';
  }
  return (
    <AntSelect {...config} />
  )
}
const AutoComplete = (props) => {
  const { optionLabelProp, value, onChange, name, placeholder, search, disabled } = props;
  const propForLabel = optionLabelProp || 'label';
  const [inputValue, setInputValue] = React.useState('');
  React.useEffect(() => {
    setInputValue(value ? value[propForLabel] : '');
  }, [value, propForLabel])
  const handleOnSelect = (e, option) => {
    onChange && onChange({ target: { name, value: option } })
  }
  const getOptions = () => {
    let options = Array.isArray(props.options) ? [...props.options] : [];
    if (props.enteredAsOption && inputValue) {
      options.push({ id: inputValue, label: inputValue })
    }
    return options;
  }
  const options = getOptions()
  return (
    <AntAutoComplete
      disabled={disabled}
      value={inputValue}
      onSelect={handleOnSelect}
      onChange={(value) => {
        setInputValue(value)
      }}
      optionRender={(option) => {
        return (
          <span className={`f14 ${option.className || ''}`} key={option.id}>{option[propForLabel]}</span>
        )
      }}
      filterOption={(inputValue, option) =>
        option[propForLabel].toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
      }
      className='f-rest'
      popupClassName='select-popup'
      options={(options || []).map((_) => ({ ..._, key: _.id, value: _[propForLabel] }))}>
      <TextInput
        suffix={<span className={`cD9D9D9 ${search ? 'f14 icon-search' : 'f10 icon-caret-down'}`}></span>}
        placeholder={placeholder}
        rootClassName='select-input'
        className={`${value ? value.className : ''}`} />
    </AntAutoComplete>
  )
};

const FilesList = (props) => {
  const { value, allowDelete, name, fileClassName } = props;
  const handleRemoveFile = (index) => {
    let filtered = (value || []).filter((_, i) => {
      return i !== index
    })
    props.onChange && props.onChange({ target: { name: name, value: filtered } })
  }
  return (
    <div className='col w-100 file-list o-hide'>
      {
        Array.isArray(value) && value.map((file, i) => {
          return (
            <div className='row w-100 file-entry h-btn'>
              <span className={`f16 txt-new-line ${fileClassName}`}>{i + 1}. {file.name}</span>
              {
                allowDelete &&
                <IconButton
                  className='col v-ctr h-ctr btn-delete'
                  onClick={handleRemoveFile.bind(null, i)}
                  icon={<DeleteFilled style={{ fontSize: 12, color: '#F2665E' }} />}
                />
              }
            </div>
          )
        })
      }
    </div>
  )
}

const FileInput = (props) => {
  const _fileInputRef = React.useRef(null)
  const { value, btnClassName, name, multiple, accept, layout } = props;
  const handleOnFileChange = (e) => {
    let _files = Array.from(e.target.files);
    if (multiple) {
      _files = [...(value || []), ..._files];
    }
    if (_fileInputRef.current) {
      _fileInputRef.current.value = null;
    }
    props.onChange && props.onChange({ target: { name: name, value: _files } })
  }
  const handleSelectFile = (e) => {
    _fileInputRef.current && _fileInputRef.current.click();
  }
  return (
    <div className={`${layout === 'horizontal' ? 'row' : 'col'}`}>
      <Button
        Icon={LinkOutlined}
        iconColor='cFFF'
        type='button'
        labelClassName='c3d92e0 f14 bold-700'
        onClick={handleSelectFile}
        label={props.btnLabel || 'Browse...'}
        className={`min select-btn bgFFF ${btnClassName || ''}`} />
      <input name={name} ref={_fileInputRef} type='file' multiple={multiple} accept={accept} onChange={handleOnFileChange} hidden={true} className='hide' />
    </div>
  )
}
const DatePicker = (props) => {
  const { id, label, placeholder, name, value, onChange, readOnly, className, showColon, rootClassName, minDate, maxDate, ...rest } = props;
  const _className = `${rootClassName || 'text-input'} border ${props.font || 'f14'} ${readOnly ? 'read-only' : ''}`;
  const handleChange = (date) => {
    onChange && onChange({ target: { name, value: date } })
  }
  const handleDisabledDate = (currentDate) => {
    let disabled = false;
    const cDate = currentDate.startOf('day').toDate();
    if (minDate) {
      const minDateObj = dayjs(minDate).startOf("day").toDate();
      disabled = dayjs(cDate).isBefore(minDateObj)
    }
    if (!disabled && maxDate) {
      const maxDateObj = dayjs(maxDate).startOf("day").toDate();
      disabled = !dayjs(cDate).isSame(maxDateObj) && dayjs(cDate).isAfter(maxDateObj)
    }
    return disabled;
  }
  return (
    Boolean(readOnly) ?
      <div className={_className}>{value}</div>
      :
      <AntDatePicker
        name={name}
        format="DD/MM/YYYY"
        placeholder={placeholder}
        className={_className}
        onChange={handleChange}
        disabledDate={handleDisabledDate}
        value={value ? dayjs(value) : undefined}
        {...rest} />
  )
};
const SuffixPassword = (props) => {
  const ref = React.useRef()
  const [state, setState] = React.useState(({ showCopy: false, copied: false }))
  const { id, name, value, onChange, className, rootClassName, allowPasswordCopy, onCopyToClipboard, ...rest } = props;
  const handleCopyToClipboard = () => {
    if (allowPasswordCopy && state.showCopy) {
      navigator.clipboard.writeText(value.suffix + value.pass);
      setState((_) => ({ ..._, copied: true }))
      onCopyToClipboard && onCopyToClipboard(true)
    }
  }
  React.useEffect(() => {
    setState((_) => ({ ..._, showCopy: value.pass.length > 0, copied: false }))
  }, [value])
  return (
    <div className='row suffix-pass-input'>
      <div className='row pass-suffix v-ctr h-ctr'>
        {value ? value.suffix : ''}
      </div>
      <TextInput
        ref={ref}
        name={name}
        value={value ? value.pass : ''}
        onChange={onChange}
        {...rest}
        suffix={
          <div style={{ opacity: allowPasswordCopy && state.showCopy ? 1 : 0 }}>
            {
              state.copied ?
                <span className='f10 c44BB44'>Copied</span>
                :
                <CheckSquareOutlined
                  style={{ fontSize: "14px" }}
                  onClick={handleCopyToClipboard} />
            }
          </div>
        }
      />
    </div>
  )
}
const Password = (props) => {
  const [visible, setVisible] = useState(false)
  const { type, id, label, placeholder, name, value, onChange, readOnly, maxLength, className, rootClassName, showColon, ...rest } = props;
  const _className = `${rootClassName || 'text-input'} border ${props.font || 'f14'} ${readOnly ? 'read-only' : ''} ${className || ''}`;
  const Suffix = () => {
    const className = 'f20 cBDBDBD';
    const handleClick = (value) => {
      setVisible(value)
    }
    return visible ?
      <EyeOutlined className={className} onClick={handleClick.bind(null, false)} /> :
      <EyeInvisibleOutlined className={className} onClick={handleClick.bind(null, true)} />
  }
  return (
    <AntInput
      type={visible ? 'text' : type}
      name={name}
      className={_className}
      classNames={{ input: 'input-ele', suffix: 'input-suffix' }}
      id={`for-${id || name}`}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      suffix={<Suffix />}
      {...rest} />
  )
}
const Checkbox = (props) => {
  const { name, label, labelClassName, multiple, options, ...rest } = props;
  const handleChange = (iID, e) => {
    const value = { target: { name: name } }
    if (multiple) {
      const oldValues = [...(props.value || [])];
      let index = oldValues.findIndex((_) => (_ === iID));
      if (index === -1) {
        oldValues.push(iID)
      } else {
        oldValues.splice(index, 1);
      }
      value.target.value = oldValues;
    } else {
      value.target.value = e.target.checked
    }
    props.onChange && props.onChange(value)
  }
  const getCheckboxes = () => {
    let checkboxes = []
    if (multiple) {
      checkboxes = [...(options || [])]
    } else {
      checkboxes.push({ id: label, label: label })
    }
    return checkboxes;
  }
  const getValue = (iID) => {
    if (multiple) {
      let index = (props.value || []).findIndex((_) => (_ === iID));
      return index > -1
    }
    return props.value || false
  }
  return (
    getCheckboxes().map((checkbox) => {
      return (
        <AntCheckbox
          key={checkbox.id}
          checked={getValue(checkbox.id)}
          onChange={handleChange.bind(null, checkbox.id)}>
          <Label text={checkbox.label} className={labelClassName} {...rest} />
        </AntCheckbox>
      )
    })
  )
}
const Quantity = (props) => {
  const { disabled, name, unit, step } = props;
  const value = props.value || 0
  const getDisabledMinus = () => {
    return disabled || value === 0
  }
  const handleCount = (direction) => {
    let offset = direction === '+' ? 1 : -1;
    props.onChange && props.onChange({ target: { name, value: (value + (offset * (step || 1))) } })
  }
  return (
    <div className={`row`}>
      <Button
        label='-'
        type='button'
        className="bgFFF"
        labelClassName='f18 c333'
        disabled={getDisabledMinus()}
        onClick={handleCount.bind(null, '-')} />
      <div className='text-center text-hr'>{value || 0}</div>
      <Button
        label='+'
        type='button'
        className="bgFFF"
        disabled={disabled}
        labelClassName='f18 c333'
        onClick={handleCount.bind(null, '+')} />
      <span className='text-center text-hr caps'>{unit}</span>
    </div>
  )
}
const Duration = (props) => {
  const { disabled, name, maxDuration } = props;
  const value = getValueToDuration(props.value);
  const handleMinChange = (min) => {
    const val = { ...value };
    val.min = min;
    handleChange(val)
  }
  const getDisabledMinus = () => {
    return disabled || value.hr === 0
  }
  const isMaximux = () => {
    const val = getDurationToValue(value);
    return maxDuration && val > maxDuration
  }
  const handleChange = (val) => {
    props.onChange && props.onChange({ target: { name, value: val } })
  }
  const handleHour = (direction) => {
    const val = { ...value };
    val.hr += direction === '+' ? 1 : -1;
    handleChange(val)
  }
  return (
    <div className={`row`}>
      <Button
        label='-'
        type='button'
        className="bgFFF"
        labelClassName='f18 c333'
        disabled={getDisabledMinus()}
        onClick={handleHour.bind(null, '-')} />
      <div className='text-center text-hr'>{value.hr}</div>
      <Button
        label='+'
        type='button'
        className="bgFFF"
        disabled={disabled || isMaximux()}
        labelClassName='f18 c333'
        onClick={handleHour.bind(null, '+')} />
      <span className='text-center text-hr'>:</span>
      <AntSelect
        disabled={disabled || isMaximux()}
        onChange={handleMinChange}
        placeholder="Min"
        showSearch={false}
        value={(value) ? value.min : null}
        rootClassName={`select-input`}
        suffixIcon={<span className='f8 icon-arrow-down cD9D9D9'></span>}
        options={Minutes}
      />
    </div>
  )
}
const Radio = (props) => {
  const { options, value, name, className, } = props;
  const handleChange = (e) => {
    const value = { target: { name: name, value: e.target.value } }
    props.onChange && props.onChange(value)
  }
  return (
    <div className={`row w-100 ${className || ''}`}>
      <AntRadio.Group
        name={name}
        options={options}
        onChange={handleChange}
        value={value} />
    </div>
  )
}
export const Input = (props) => {
  const { className, id, label, name, labelClassName, layout, afterContent, ...rest } = props;
  const type = props.type || 'text';
  const showHelpText = Boolean(rest.helpText) || Boolean(rest.error);
  const canShowLabel = () => {
    return type === 'checkbox' ? Boolean(rest.multiple) : (label && label.length > 0)
  }
  const getClassName = () => {
    let _className = `form-input type-${type} w-100 ${name}`;
    if (layout) {
      _className += ` layout-${layout}`;
    }
    if (rest.multiple) {
      _className += ` multiple`;
    }
    return _className
  }
  return (
    <div className={`col form-input-container ${className || ''} type-${type}`}>
      <AntForm.Item
        colon={false}
        layout={layout}
        htmlFor={`for-${id || name}`}
        help={showHelpText ? <HelpText {...props} /> : null}
        className={getClassName()}
        label={(
          canShowLabel() ?
            <Label text={label} className={labelClassName} {...rest} />
            :
            null
        )}>
        {
          (type === 'text' || type === 'email') &&
          <TextInput {...props} className='' />
        }
        {
          type === 'select' &&
          <Select {...props} />
        }
        {
          type === 'autocomplete' &&
          <AutoComplete {...props} />
        }
        {
          type === 'textarea' &&
          <TextArea {...props} />
        }
        {
          type === 'file' &&
          <FileInput {...props} />
        }
        {
          type === 'date' &&
          <DatePicker {...props} />
        }
        {
          type === 'suffix-pass' &&
          <SuffixPassword {...props} />
        }
        {
          type === 'password' &&
          <Password {...props} />
        }
        {
          type === 'checkbox' &&
          <Checkbox {...props} />
        }
        {
          type === 'duration' &&
          <Duration {...props} />
        }
        {
          type === 'quantity' &&
          <Quantity {...props} />
        }
        {
          type === 'radio' &&
          <Radio {...props} />
        }
      </AntForm.Item>
      {Boolean(afterContent) && afterContent}
      {
        type === 'file' &&
        <FilesList {...props} />
      }
    </div>
  )
};
const isFormValueExist = (value) => {
  return value !== undefined && value !== null && value !== ''
}
const validationHelper = (fields, values) => {
  let errors = {}, formValid = true, formJson = {}, formData = new FormData();
  const validateField = (field) => {
    let fieldValid = true, error, jsonValue, dataValue;
    const { attribute, required, type, formDataKey, multiple, validator, regex } = field;
    const value = values[attribute]
    if (required && !value) {
      error = `Please fill this field`;
      fieldValid = false;
    } else if (field.minLength && value.length < field.minLength) {
      error = `${field.label} should contain atleast ${field.minLength} characters.`;
      fieldValid = false;
    } else if (regex && value && !new RegExp(regex).test(value)) {
      error = `Invalid value`;
      fieldValid = false;
    } else if (field.equalsTo) {
      const compareWith = values[field.equalsTo];
      if (compareWith && compareWith !== value) {
        let compareWithField = fields.find(({ attribute }) => attribute === field.equalsTo);
        error = `mismatch with ${compareWithField.label}`;
        fieldValid = false;
      }
    } else if (type === 'duration' && value) {
      if (value.hr === 0 && (value.min === null || value.min === 0)) {
        error = `Invalid Duration`;
        fieldValid = false;
      }
    } else if (type === 'file') {
      fieldValid = Array.isArray(value) && value.length > 0;
      error = fieldValid ? "" : "Please Select file";
    }
    if ((typeof validator) === "function") {
      const res = validator(value);
      if (!res.valid) {
        fieldValid = res.valid;
        error = res.error;
      }
    }
    formValid = formValid && fieldValid;
    if (formValid && isFormValueExist(value)) {
      const key = (formDataKey || attribute);
      if (type === 'autocomplete') {
        jsonValue = dataValue = value.id;
      } else if (type === 'date') {
        jsonValue = dataValue = dayjs(value).toISOString();
      } else if (type === 'duration') {
        jsonValue = dataValue = getDurationToValue(value);
      } else if (type === 'file') {
        jsonValue = value;
        if (multiple) {
          value.forEach((file) => {
            formData.append(key, file);
          })
        } else {
          dataValue = value;
        }
      } else {
        jsonValue = dataValue = value;
      }
      if (isFormValueExist(jsonValue)) {
        formJson[attribute] = jsonValue;
      }
      if (isFormValueExist(dataValue)) {
        formData.append(key, JSON.stringify(dataValue));
      }
    }
    errors[attribute] = error;
  }
  for (let i = 0; i < fields.length; i++) {
    const currentFields = Array.isArray(fields[i].children) ? fields[i].children : [fields[i]]
    currentFields.forEach((field) => {
      if (!field.custom) {
        validateField(field);
      }
    })
  };
  return { errors, valid: formValid, formJson, formData }
}
export const FormUI = React.forwardRef((props, ref) => {
  const [errors, setErrors] = React.useState({});
  const { show, direction, Fields, formData, onChange, getOptions, onSubmit, className, showColon, hideErrorMsg, labelAlign, ...rest } = props;
  const formDisabled = props.disabled;

  const handleSubmit = (e, skipSubmit) => {
    e && e.preventDefault && e.preventDefault();
    const { errors, ...rest } = validationHelper(Fields, formData);
    if (!rest.valid) {
      if (!Boolean(hideErrorMsg)) {
        setErrors(errors)
      }
      return { valid: rest.valid }
    }
    if (!skipSubmit) {
      onSubmit && onSubmit(e, rest.formJson);
    }
    return rest;
  }
  const handleChange = (e) => {
    const { name } = e.target;
    const _errors = { ...errors };
    _errors[name] = false;
    setErrors(_errors);
    onChange && onChange(e)
  }
  const getInputProps = (field) => {
    let { attribute, className, disabled, ...rest } = field
    let options = (typeof getOptions === 'function') ? getOptions(attribute) : [];
    const p = {
      value: formData[attribute] || '', key: attribute, error: errors && errors[attribute],
      className: `${className || 'w-100'}`, name: attribute, onChange: handleChange, options: options,
      showColon: showColon, disabled: disabled || formDisabled, ...rest
    }
    if (p.type === 'html' && (typeof props.onHtmlEditorReady === "function")) {
      p.onInstanceReady = props.onHtmlEditorReady.bind(null, attribute);
    }
    if (p.type === 'file' && props.onDeleteFile) {
      p.onDelete = props.onDeleteFile.bind(null, attribute);
    }
    if (p.type === 'suffix-pass' && props.onCopyToClipboard) {
      p.onCopyToClipboard = props.onCopyToClipboard.bind(attribute)
    }
    return p;
  }
  React.useImperativeHandle(ref, () => ({ validate: handleSubmit, resetErrors: () => setErrors({}) }));
  return (
    <AntForm labelWrap labelAlign={labelAlign || "left"} layout={direction || "horizontal"} rootClassName={`w-100 ${className || ''}`} onFinish={handleSubmit} noValidate>
      {
        Fields.map((field, i) => {
          const { children, Component, className } = field
          const content = (
            Boolean(Component) ? <Component />
              :
              Array.isArray(children) ?
                <div className={`col ${className || ''}`} key={i}>
                  {
                    children.map((_, j) => {
                      return <Input {...getInputProps(_)} key={j} />
                    })
                  }
                </div>
                :
                <Input {...getInputProps(field)} key={i} />
          )

          return content;
        })
      }
      {
        Array.isArray(rest.actions) &&
        <div className={rest.actionContainerClass}>
          {
            rest.actions.map((action, i) => {
              return <Button key={i} {...action} />
            })
          }
        </div>
      }
    </AntForm>
  )
})
