import { put, takeEvery, select } from "redux-saga/effects";
import { SagaRegistry, axios, requestHandler, setAlertAndLoading, getDateInFormat } from "../app";
import { projectActions, NAME } from "./slice";
import { getDeliverableRCExportCount, getDesignFeeUnexportCount, getProjectStatusList, isProjectStopped, getProjectUnExport } from "./helper";
import { getAllUsersOriginal } from "../user";
import {
  getBrands, getClientById, getClients, getClientContacts, getClientContactById,
  getBrandById, getRatecards, getRatecardById
} from "../misc";

function getProjectMap(iList, project) {
  const { users, clients, clientContacts, brands, rateCardLists, unexported, expand } = iList
  const statusList = getProjectStatusList(true);
  project.startDateStr = getDateInFormat(project.startDate);
  project.stats = {
    designFee: { total: 0, unbilled: 0 },
    ratecard: { total: 0, unbilled: 0 },
  }
  project.isStopped = isProjectStopped(project)
  let client = project.client,
    brand = project.clientBrand,
    user = users[project.icarusContact],
    clientContact = project.clientContact,
    status = project.status;

  if (user) {
    project.icarusContact = user;
    project.icarusContactName = user.userName
  }
  if (client) {
    client = getClientById(client, null, clients);
    if (client) {
      project.client = client;
      project.clientName = client.name;
    }
  }
  if (clientContact && client) {
    clientContact = getClientContactById(client.id, clientContact, null, clientContacts);
    if (clientContact) {
      project.clientContact = { ...clientContact, label: clientContact.name };
      project.clientContactName = clientContact.name;
    }
  }
  if (brand) {
    brand = getBrandById(brand, null, brands);
    if (brand) {
      project.clientBrand = brand;
      project.brandName = brand.title;
    }
  }
  if (status) {
    project.status = statusList.find((_) => (_.id === status));
    project.statusLabel = project.status ? project.status.label : ''
  }
  if (Array.isArray(project.documentSets)) {
    for (let i = 0; i < project.documentSets.length; i++) {
      const set = project.documentSets[i];
      if (set) {
        project.stats.designFee.total += Object.keys(set).filter(type => type !== 'note').length
        project.stats.designFee.unbilled += getDesignFeeUnexportCount(project.projectId, i, unexported);
      }
    }
  }
  if (Array.isArray(project.deliverables)) {
    project.stats.ratecard.total = 0;
    project.stats.ratecard.unbilled = 0;
    let deliverables = []
    for (let i = 0; i < project.deliverables.length; i++) {
      let deliverable = project.deliverables[i];
      if (deliverable && !deliverable.deleted) {
        const { rateCards } = deliverable;
        project.rowIndicator = Boolean(deliverable.deleted)
        project.stats.ratecard.total += (rateCards || []).length;
        project.stats.ratecard.total += deliverable.fromTask ? 1 : 0;
        deliverable.id = i;
        deliverable.deliverableIndex = i;
        deliverable.unexportedRC = getDeliverableRCExportCount(project.projectId, i, unexported);
        deliverable.expandRatecards = [];
        project.stats.ratecard.unbilled += deliverable.unexportedRC;
        if (deliverable.fromTask && deliverable.task) {
          let task = deliverable.task;
          if (task) {
            let ratecard = (typeof task.taskItemId === 'object') ? task.taskItemId : getRatecardById(task.taskItemId, null, rateCardLists)
            deliverable.projectName = (task.projectName || task.description);
            deliverable.thumbnail = task.upload;
            deliverable.rateCardItem = ratecard ? ratecard.title : '';
            deliverable.sizeORquantity = task.sizeORquantity;
            deliverable.details = task.details;
            deliverable.sendDate = getDateInFormat(task.closedTime);
            deliverable.lastUpdated = getDateInFormat(task.lastUpdated);
            deliverable.designer = task.designer;
          }
          deliverable.expandRatecards = rateCards || [];
        } else {
          if (Array.isArray(rateCards) && rateCards.length > 0) {
            let ratecard = getRatecardById(rateCards[0].rateCardId, null, rateCardLists)
            deliverable.rateCardItem = ratecard ? ratecard.title : '';
            deliverable.sizeORquantity = rateCards[0].sizeORquantity;
            deliverable.details = rateCards[0].details;
            deliverable.sendDate = getDateInFormat(rateCards[0].sendDate);
            deliverable.designer = rateCards[0].designer;
            deliverable.lastUpdated = getDateInFormat(rateCards[0].entryDate);
            deliverable.expandRatecards = rateCards.slice(1);
            deliverable.billing = rateCards[0].billing;
          }
        }
        if (expand) {
          deliverable.expandRatecards = deliverable.expandRatecards.map((_) => {
            let rc = { ..._ }, ratecard = getRatecardById(_.rateCardId, null, rateCardLists);
            rc.ratecardTitle = ratecard ? ratecard.title : '';
            rc.entryDate = getDateInFormat(rc.entryDate);
            rc.sendDate = getDateInFormat(rc.sendDate);
            rc.taskItem = deliverable.fromTask && rc.fromTask ? deliverable.taskId : ''
            rc.designer = users[rc.designer] ? users[rc.designer].userName : rc.designer;
            return rc;
          })
        }
        deliverables.push(deliverable)
      }
    }
    project.deliverables = deliverables;
  } else {
    project.deliverables = []
  }
  return project
}
function* fetchAllProjects() {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    let res = yield axios.get('/projects');
    let projects = {};
    let len = Array.isArray(res) ? res.length : 0;
    try {
      if (len > 0) {
        const iList = { expand: false }
        iList.users = yield select(getAllUsersOriginal);
        iList.clients = yield select(getClients);
        iList.clientContacts = yield select(getClientContacts)
        iList.brands = yield select(getBrands);
        iList.rateCardLists = yield select(getRatecards);
        iList.unexported = yield select(getProjectUnExport)
        for (let i = 0; i < len; i++) {
          let project = res[i];
          if (project) {
            project.id = i;
            project.label = `${i} ${project.projectName}`;
            projects[i] = getProjectMap(iList, project);
          }
        }
      }
      yield put(projectActions.fetchProjectUnexported())
      yield put(projectActions.setProjects(projects))
      yield setAlertAndLoading(false);
    } catch (error) {
      console.log("fetchAllProjects error", error);
    }
  }, "Failed to fetch projects", false);
}
function* fetchProject({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    let res = yield axios.get('/projects/' + payload);
    let project;
    if (res.project) {
      const iList = { expand: true, current: true }
      iList.users = yield select(getAllUsersOriginal);
      iList.clients = yield select(getClients);
      iList.clientContacts = yield select(getClientContacts)
      iList.brands = yield select(getBrands);
      iList.rateCardLists = yield select(getRatecards);
      iList.unexported = yield select(getProjectUnExport)
      project = res.project;
      if (project) {
        project = yield getProjectMap(iList, project)
      } else {
        project = 'NOT_FOUND'
      }
    }
    yield put(projectActions.setCurrentProject(project))
    yield setAlertAndLoading(false);
  }, "Failed to fetch project", false)
}
function* createProject({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    yield axios.post('/projects', payload);
    yield put(projectActions.fetchAllProjects())
    yield setAlertAndLoading(false, { message: "Project Created Successfully." })
  }, "Failed to create project")
}
function* updateProject({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const { projectId, body } = payload;
    yield axios.put('/projects/' + projectId, body);
    yield put(projectActions.fetchAllProjects())
    yield setAlertAndLoading(false, { message: "Project Updated Successfully." })
  }, "Failed to update project")
}
function* uploadProjectInvoices({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const { projectId, body } = payload;
    yield axios.put(`/projects/${projectId}/invoices`, body);
    yield put(projectActions.fetchAllProjects())
    yield setAlertAndLoading(false, { message: "Project Invoice Updated." })
  }, "Failed to update project invoices")
}
function* createDeliverable({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const { projectId, body } = payload;
    yield axios.post(`/projects/${projectId}/deliverables`, body);
    yield put(projectActions.fetchProject(projectId))
    yield setAlertAndLoading(false, { message: "Deliverable Created Successfully." })
  }, "Failed to create deliverable")
}
function* updateDeliverable({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const { projectId, deliverableIndex, body } = payload;
    yield axios.put(`/projects/${projectId}/deliverables/${deliverableIndex}`, body);
    yield put(projectActions.fetchProject(projectId))
    yield setAlertAndLoading(false, { message: "Deliverable Updated Successfully." })
  }, "Failed to update deliverable")
}
function* createRateCard({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const { projectId, deliverableIndex, body } = payload;
    yield axios.post(`/projects/${projectId}/deliverables/${deliverableIndex}`, body);
    yield put(projectActions.fetchProject(projectId))
    yield setAlertAndLoading(false, { message: "Ratecard Created Successfully." })
  }, "Failed to create ratecard")
}
function* updateRateCard({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const { projectId, deliverableIndex, cardIndex, body } = payload;
    yield axios.put(`/projects/${projectId}/deliverables/${deliverableIndex}/rate-cards/${cardIndex}`, body);
    yield put(projectActions.fetchProject(projectId))
    yield setAlertAndLoading(false, { message: "Ratecard Updated Successfully." })
  }, "Failed to update ratecard")
}
function* exportDeliverables() {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    yield axios.post('/projects/billing/deliverables', {});
    yield setAlertAndLoading(false, { message: "Project Ratecard Export Initiated." })
  }, "Failed to export project deliverables")
}
function* exportDesignFees() {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    yield axios.post('/projects/billing/design-fee', {});
    yield setAlertAndLoading(false, { message: "Project Design Fee Export Initiated." })
  }, "Failed to export project design fee")
}
function* fetchProjectUnexported() {
  yield requestHandler(function* () {
    const res = yield axios.get('/projects/unexported');
    yield put(projectActions.setProjectUnexported(res))
  }, "Failed to export project unexport")
}
SagaRegistry.register(function* projectTask() {
  yield takeEvery(NAME + "/fetchAllProjects", fetchAllProjects);
  yield takeEvery(NAME + "/fetchProjectUnexported", fetchProjectUnexported);
  yield takeEvery(NAME + "/fetchProject", fetchProject);
  yield takeEvery(NAME + "/createProject", createProject);
  yield takeEvery(NAME + "/updateProject", updateProject);
  yield takeEvery(NAME + "/uploadProjectInvoices", uploadProjectInvoices);
  yield takeEvery(NAME + "/createDeliverable", createDeliverable);
  yield takeEvery(NAME + "/updateDeliverable", updateDeliverable);
  yield takeEvery(NAME + "/createRateCard", createRateCard);
  yield takeEvery(NAME + "/updateRateCard", updateRateCard);
  yield takeEvery(NAME + "/exportDeliverables", exportDeliverables);
  yield takeEvery(NAME + "/exportDesginFees", exportDesignFees);
})