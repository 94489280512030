import React from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { CloseOutlined, InfoOutlined, DeleteFilled, EditFilled, CheckCircleFilled } from '@ant-design/icons';
import { getFilteredTasks, isDesigner, isFinance, isAdmin, getCurrentUser, getAssignedHHMM, getDateInFormat, getDateTimeInFormat } from "../../store";
import { DataGridView, IconButton, Button, usePub } from "../../components";
import { render } from 'react-dom';

const Columns = [
  { title: 'Task ID', dataIndex: 'taskId', key: 'taskId', width: '7%', defaultSortOrder: 'descend', sort: true },
  { title: 'EDD', dataIndex: 'estDeliveryDateStr', key: 'estDeliveryDateStr', width: '9%', sort: true },
  { title: 'Client', dataIndex: 'clientName', key: 'clientName', width: '8%', sort: true },
  { title: 'Brand', dataIndex: 'brandName', key: 'brandName', width: '9%', sort: true },
  { title: 'Icarus', dataIndex: 'icarusContactName', key: 'icarusContactName', width: '9%', sort: true },
  { title: 'Task Title', dataIndex: 'projectName', key: 'projectName', width: '14%', sort: true },
  { title: 'Task Item', dataIndex: 'ratecardTitle', key: 'ratecardTitle', width: '9%', sort: true },
  { title: 'THUMBNAIL', dataIndex: 'upload', textDataIndex: 'sizeORquantityText', key: 'upload', width: '9%', render: 'upload-view' },
]
const NonDesignerColumns = [
  { title: 'Assigned To', dataIndex: 'history', key: 'history', width: '12%' },
  { title: 'Status', dataIndex: 'status', key: 'status', width: '8%', noborder: true, render: 'html' },
  { title: '', dataIndex: 'actions', key: 'actions', width: '4%', render: 'actions', noborder: true, cellClassName: 'h-arnd' },
]

const DesignerColumns = [
  { title: 'Time', dataIndex: 'assignedFor', key: 'assignedFor', width: '6%' },
  { title: 'Update', dataIndex: 'history', key: 'history', width: '18%', noborder: true },
]

const ExpandColumns = [
  { title: '', dataIndex: 'empty', key: 'empty', width: '6%' },
  { title: '', dataIndex: 'contacts', key: 'contacts', width: '59%' },
  { title: '', dataIndex: 'empty2', key: 'empty2', width: '9%' },
  { title: '', dataIndex: 'history', key: 'history', width: '26%' },
]

const AdditionalRCColumns = [
  { title: 'RateCard', dataIndex: 'ratecardTitle', key: 'ratecardTitle', width: '25%' },
  { title: 'Size/Quantity', dataIndex: 'sizeORquantityText', key: 'sizeORquantityText', width: '20%' },
  { title: 'Details', dataIndex: 'details', key: 'details', width: '30%' },
  { title: 'File Ownner', dataIndex: 'designer', key: 'designer', width: '25%' },
]
const RowDetails = (task) => {
  const getColumns = () => {
    return ExpandColumns.map((_) => {
      return { ..._, render: Render.bind(null, _) }
    })
  }
  const getRCColumns = () => {
    return AdditionalRCColumns.map((_) => {
      return {
        ..._,
        headerClassName: 'f14',
        cellClassName: 'f14',
      }
    })
  }
  const Render = (col) => {
    return (
      <div className='col cell f14 w-100 h-100'>
        {
          col.dataIndex === 'contacts' && Boolean(task.clientContact) &&
          <div className='col w-100'>
            <div className='row reg w-100'>
              <div className='col contact pad'>
                Client Contact <br /> {task.clientContact.name}<br />
                {task.clientContact.email}<br />{task.clientContact.phone}
              </div>
              <div className='col f-rest pad'>
                <span>
                  Est. Total Duration: {getAssignedHHMM(task.estDuration)}&nbsp;
                  Est. Remain Duration: {getAssignedHHMM(task.remainDuration)} <br />
                  Client Reqt.ed Date: {getDateInFormat(task.requestDate)}<br />
                  Client Exp.ed Date: {getDateInFormat(task.clientDeliveryDate)} &nbsp;
                  {
                    !Boolean(task.isIndependent) && <Link to={`/projects/${task.projectId.projectId}`} >Project Id : {task.projectId.projectId}</Link>
                  }
                </span>
              </div>
            </div>
            {
              Array.isArray(task.rateCards) && task.rateCards.length > 0 &&
              <div className='col w-100'>
                <div className="row w-100">
                  <div className="col f-rest addline"> <span>&nbsp;</span> </div>
                  <div className="col additional"> <span>Additional Ratecards</span> </div>
                  <div className="col f-rest addline"> <span>&nbsp;</span> </div>
                </div>
                <DataGridView
                  showPagination={false}
                  className='trans-table rc-table'
                  rows={task.rateCards}
                  Columns={getRCColumns()}
                />
              </div>
            }
          </div>
        }
        {
          col.dataIndex === 'history' &&
          <div className='col w-100 history'>
            {
              Boolean(task.deleted) &&
              <div className='col assign-history'>
                <span className='bold'>Deleted</span>
                <div className='row h-btn'>
                  <span>By: {task.deleted.deletedBy.userName}</span>
                  <span>{getDateTimeInFormat(task.deleted.time)}</span>
                </div>
                <div className="row" style={{}}>
                  <span>Reason: &nbsp;</span>
                  <span className='brk-word'>{task.deleted.reason}</span>
                </div>
              </div>
            }
            {
              Boolean(task.assigns) &&
              <div className='col '>
                <span className='bold'>Assign History:</span>
                {
                  Boolean(task.assigns.current) &&
                  <div className='col w-100'>
                    <span>(c) Note: <span className='brk-word'>{task.assigns.current.updateNote || '-'}</span></span>
                  </div>
                }
                <ol className='list'>
                  {
                    Array.isArray(task.assigns.history) && task.assigns.history.length > 0 &&
                    task.assigns.history.map((assign, i) => {
                      return (
                        <li key={i} className='w-100 entry'>
                          <div className='row w-100 h-btn'>
                            <span>{assign.assignedTo.userName}</span>
                            <span>{getDateInFormat(assign.assignedTime)}</span>
                            <span>{assign.assignedForHHMM} {assign.workedHHMM ? ('/ ' + assign.workedHHMM) : ''}</span>
                          </div>
                          {
                            Boolean(assign.updateNote) &&
                            <span className='brk-word'>Note: {assign.updateNote}</span>
                          }
                        </li>
                      )
                    })
                  }
                </ol>
              </div>
            }
          </div>
        }
      </div>
    )
  }
  return (
    <div className='col bgF2F2F2 w-100'>
      <DataGridView
        className='trans-table'
        rows={[task]}
        emptyMessage='No Tasks'
        Columns={getColumns()}
        showHeader={false}
        showPagination={false}
      />
    </div>
  )
}
const AssignToCell = (currentUser, text, task) => {
  const isDesignerUser = isDesigner(currentUser)
  const isFinanceUser = isFinance(currentUser)
  const publish = usePub();
  const handleAction = (event) => {
    publish(event, task)
  }
  const getActions = () => {
    let actions = [];
    if (!task.assigns || (task.assigns && !task.assigns.current)) {
      actions.push({ label: 'Assign', onClick: handleAction.bind(null, "ASSIGN_TASK") })
    }
    if (task.assigns && task.assigns.current) {
      actions.push({ label: 'Reassign', onClick: handleAction.bind(null, "REASSIGN_TASK") })
    }
    if (task.upload) {
      actions.push({ label: 'Close', onClick: handleAction.bind(null, "CLOSE_TASK") })
    }
    return actions
  }
  return (
    <div className='col w-100 h-100 cell assign-cell h-ctr'>
      {
        isDesignerUser && !task.closed && !task.deleted &&
        <React.Fragment>
          <Button
            className='f-rest'
            normal primary
            labelClassName='cFFF f14'
            label='Update Note'
            onClick={handleAction.bind(null, "UPDATE_TASK_NOTE")}
          />
        </React.Fragment>
      }
      {
        !isDesignerUser &&
        <React.Fragment>
          {isFinanceUser && <span className='sh'>{task.closed ? 'See History' : 'No Permission'}</span>}
          {
            !isFinanceUser &&
            <React.Fragment>
              {
                (task.closed || task.deleted) ?
                  <span className='f14 reg sh'>See History</span>
                  :
                  <React.Fragment>
                    {
                      Boolean(task.assigns) && Boolean(task.assigns.current) &&
                      <div className="row h-btn">
                        <span className='f14 reg'>{task.responsible}</span>
                        <span className='row'>
                          <span className='f14 reg'>{getAssignedHHMM(task.assigns.current.assignedFor)}</span>
                          <IconButton
                            shape="circle" type='primary'
                            className='col close bgF2665E v-ctr h-ctr'
                            onClick={handleAction.bind(null, "UNASSIGN_TASK")}
                            icon={<CloseOutlined style={{ fontSize: 12 }} />}
                          />
                        </span>
                      </div>
                    }
                    <div className='row w-100 actions h-btn'>
                      {
                        getActions().map((_, i) => {
                          return (
                            <Button
                              className={`f-rest ${_.label.toLowerCase()}`}
                              {..._} normal primary key={i}
                              labelClassName='cFFF f14'>
                            </Button>
                          )
                        })
                      }
                    </div>
                  </React.Fragment>
              }
            </React.Fragment>
          }
        </React.Fragment>
      }
    </div>
  )
}
export const TasksTableScreen = (props) => {
  const user = useSelector(getCurrentUser);
  const tasks = useSelector(getFilteredTasks.bind(null, user));
  const handleDeleteTask = (task) => {
    props.onDeleteTask && props.onDeleteTask(task)
  }
  const handleEditTask = (task) => {
    props.onEditTask && props.onEditTask(task)
  }
  const getTaskRowActions = (task) => {
    let style = { fontSize: 18 }, actions = [];
    if (!task.deleted) {
      if (task.billing) {
        actions.push({ Component: CheckCircleFilled, style: { ...style, color: '#219653' } })
      } else {
        if (!task.closed) {
          actions.push({
            Component: EditFilled, onClick: handleEditTask.bind(null, task),
            style: { ...style, color: '#bdbdbd' }
          })
        }
        if (isAdmin(user)) {
          actions.push({
            Component: DeleteFilled, onClick: handleDeleteTask.bind(null, task),
            style: { ...style, color: '#f2665e' }
          })
        }
      }
    }
    return actions
  }
  const getColumns = () => {
    const columns = Columns.concat(isDesigner(user) ? DesignerColumns : NonDesignerColumns);
    return columns.map((_, i) => {
      let col = { ..._ };
      if (i === 0) {
        col.rowIndicator = <InfoOutlined />
      }
      if (col.render === 'actions') {
        col.actions = getTaskRowActions
      }
      if (col.dataIndex === 'history') {
        col.render = AssignToCell.bind(null, user);
      }
      return col;
    })
  }
  return (
    <div className='col w-100 h-100 o-hide'>
      <DataGridView
        expandable
        rows={tasks}
        emptyMessage='No Tasks'
        Columns={getColumns()}
        expandedRowRender={RowDetails}
        rowClassName={(task) => {
          return task.isIndependent ? 'task-independent' : ''
        }}
      />
    </div>
  )
}