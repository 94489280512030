import React from "react";
import AppRouter from "./routers";
import { Provider } from "react-redux";
import { createAppStore } from "./store";
import { AuthService } from "./services";
import { App } from 'antd';
import './App.scss';

function IcarusPMApp() {
  React.useEffect(() => {
    AuthService.init();
  }, [])
  return (
    <App className="col w-100 h-100 o-hide">
      <Provider store={createAppStore()}>
        <AppRouter />
      </Provider>
    </App>
  );
}

export default IcarusPMApp;
