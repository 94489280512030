import { initializeApp } from "firebase/app";
import { 
  getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut, onIdTokenChanged,
  EmailAuthProvider, reauthenticateWithCredential, sendPasswordResetEmail,
 } from "firebase/auth";

const API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
const APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
const AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const MESSEGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSEGING_SENDER_ID;

class AuthServiceModel {
  _firebaseApp = null;
  _auth = null;
  getFirebaseConfig() {
    return {
      apiKey: API_KEY,
      appId: APP_ID,
      authDomain: AUTH_DOMAIN,
      projectId: PROJECT_ID,
      storageBucket: STORAGE_BUCKET,
      messagingSenderId: MESSEGING_SENDER_ID
    }
  }
  init() {
    this._firebaseApp = initializeApp(this.getFirebaseConfig());
    this._auth = getAuth(this._firebaseApp);
  }
  logInWithEmailPassword(email, password) {
    return signInWithEmailAndPassword(this._auth, email, password)
  }
  reauthLoggedInUser(password) {
    let user = this._auth.currentUser;
    let credetial = EmailAuthProvider.credential(user.email, password);
    return reauthenticateWithCredential(user, credetial)
  }
  onAuthStateChanged(callback) {
    onAuthStateChanged(this._auth, callback)
  }
  onIdTokenChanged(callback) {
    onIdTokenChanged(this._auth, callback)
  }
  sendPasswordResetEmail(email) {
    return sendPasswordResetEmail(this._auth, email)
  }
  logout() {
    return signOut(this._auth)
  }
}

export const AuthService = new AuthServiceModel()