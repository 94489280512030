import React from 'react';
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import {
  HomeScreen, LogInScreen, RatecardsScreen, TaskGroupScreen, TasksScreen,
  TaskHomeScreen, ProjectDetailsScreen, ProjectsScreen, UserListScreen,
  ClientContactsScreen, ClientBrandsScreen, SettingScreen, ProjectHomeScreen
} from "../screens";
import { isDesigner, getCurrentUser, isAdmin } from "../store";


const TaskRouter = () => {
  const user = useSelector(getCurrentUser);
  return (
    <TaskHomeScreen>
      <Routes>
        {
          !isDesigner(user) &&
          <React.Fragment>
            {/* <Route path="groups" element={<TaskGroupScreen />} /> */}
          </React.Fragment>
        }
        <Route path="/" index element={<TasksScreen />} />
      </Routes>
    </TaskHomeScreen>
  )
}
const ProjectRouter = () => {
  return (
    <ProjectHomeScreen>
      <Routes>
        <Route path="/" index element={<ProjectsScreen />} />
        <Route path="/:projectId" element={<ProjectDetailsScreen />} />
      </Routes>
    </ProjectHomeScreen>
  )
}
const ClientRouter = () => {
  return (
    <Routes>
      <Route path="/" index element={<ClientContactsScreen />} />
      <Route path="/brands" element={<ClientBrandsScreen />} />
    </Routes>
  )
}

const MainRouter = () => {
  const user = useSelector(getCurrentUser);
  const isDesignerUser = isDesigner(user)
  const defaultPath = isDesignerUser ? '/tasks' : '/projects'
  return (
    <HomeScreen>
      <Routes>
        <Route path="tasks/*" element={<TaskRouter />} />
        {
          !isDesigner(user) &&
          <React.Fragment>
            <Route path="projects/*" element={<ProjectRouter />} />
            {
              isAdmin(user) &&
              <React.Fragment>
                <Route path="users" element={<UserListScreen />} />
                <Route path="ratecards" element={<RatecardsScreen />} />
                <Route path="clients/*" element={<ClientRouter />} />
              </React.Fragment>
            }
          </React.Fragment>
        }
        <Route path="settings" element={<SettingScreen />} />
        <Route path="/" index element={<Navigate replace to={defaultPath} />} />
      </Routes>
    </HomeScreen>
  )
}

function AppRouter() {
  return (
    <BrowserRouter >
      <Routes>
        <Route path="/login" element={<LogInScreen />} exact />
        <Route path="/*" element={<MainRouter />} />
      </Routes>
    </BrowserRouter>
  );
}
export default AppRouter;