import React from 'react';
import { DataGridView, Button, IconButton, useSub } from "../../components";
import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { getProjectDocSetNotes, getProjectInvoices, isExecutive } from "../../store";

const Columns = [
  { title: '', dataIndex: 'empty1', key: 'empty2', width: '19%' },
  { title: '', dataIndex: 'empty2', key: 'empty2', width: '9%' },
  { title: '', dataIndex: 'ratecardTitle', key: 'ratecardTitle', width: '12%' },
  { title: '', dataIndex: 'sizeORquantity', key: 'sizeORquantity', width: '8%' },
  { title: '', dataIndex: 'details', key: 'details', width: '10%' },
  { title: '', dataIndex: 'sendDate', key: 'sendDate', width: '10%' },
  { title: '', dataIndex: 'taskItem', key: 'taskItem', width: '6%' },
  { title: '', dataIndex: 'designer', key: 'designer', width: '9%' },
  { title: '', dataIndex: 'entryDate', key: 'entryDate', width: '10%' },
  { title: '', dataIndex: 'actions', key: 'actions', width: '7%', render: 'actions' },
];
const ProjectColumns = [
  { title: '', dataIndex: 'empty1', key: 'empty1', width: '7%' },
  { title: '', dataIndex: 'empty2', key: 'empty2', width: '13%' },
  { title: '', dataIndex: 'empty3', key: 'empty3', width: '10%' },
  { title: '', dataIndex: 'empty4', key: 'empty4', width: '10%' },
  { title: '', dataIndex: 'description', key: 'description', width: '15%' },
  { title: '', dataIndex: 'clientContact', key: 'clientContact', width: '14%' },
  { title: '', dataIndex: 'icarusContact', key: 'icarusContact', width: '14%' },
  { title: '', dataIndex: 'designFees', key: 'designFees', width: '16%', noborder: true },
]
const SETS = ['proposal', 'brief', 'approval', 'note'];
export const DelRowExpandDetails = (deliverable) => {
  const getRateCardActions = (rc) => {
    return []
  }
  const getColumns = () => {
    return Columns.map((_) => {
      let col = { ..._ }
      if (col.render === 'actions') {
        col.actions = getRateCardActions
      }
      return col;
    })
  }
  return (
    <React.Fragment>
      {
        deliverable.expandRatecards.length > 0 &&
        <div className='col w-100 h-100 cell'>
          <DataGridView
            className='trans-table'
            rows={deliverable.expandRatecards}
            Columns={getColumns()}
            showHeader={false}
            showPagination={false}
          />
        </div>
      }
    </React.Fragment>

  )
}
const Cell = (user, col, text, project) => {
  const { dataIndex, onFileView, onAddInvoice } = col
  const invoices = getProjectInvoices(project)
  const handleViewFile = (type, set) => {
    const data = {
      projectName: project.projectName,
      fileName: set.name,
      documentType: type,
      type: set.type,
      url: set.url
    };
    onFileView && onFileView(data)
  }
  const handleAddInvoice = (type) => {
    onAddInvoice && onAddInvoice(project, type)
  }
  return (
    <div className='cell w-100 h-100'>
      {
        dataIndex === 'clientContact' &&
        <span className='f14'>{project.clientContact.email}<br />{project.clientContact.phone}</span>
      }
      {
        dataIndex === 'icarusContact' &&
        <span className='f14'>{project.icarusContact.email}<br />{project.icarusContact.phone}</span>
      }
      {
        dataIndex === 'designFees' &&
        <div className='col w-100'>
          {
            Array.isArray(project.documentSets) &&
            project.documentSets.map((set, i) => {
              return Boolean(set) && (
                <div className='col doc-set' key={i}>
                  {
                    SETS.map((key, i) => {
                      return Boolean(set[key]) && (
                        <React.Fragment>
                          {
                            i === 3 ?
                              <div className='col'>
                                <span className='f9 bold-700'>Note:</span>
                                {
                                  getProjectDocSetNotes(set[key]).map((note, i) => {
                                    return <span className='f12 c828282 note-text' key={i}>{note}</span>
                                  })
                                }
                              </div>
                              :
                              <div className='row w-100 h-btn doc-set-row' key={key}>
                                <div className='row'>
                                  <CheckOutlined style={{ color: '#219653', fontSize: 10, marginRight: 8 }} />
                                  <span className='f14 caps title'>{key} {i + 1}</span>
                                </div>
                                <Button
                                  labelClassName='f14 c3d92e0'
                                  onClick={handleViewFile.bind(null, `${key} ${i + 1}`, set[key])}
                                  label='View' link />
                              </div>
                          }
                        </React.Fragment>

                      )
                    })
                  }
                  <div className='dotted-line'></div>
                </div>
              )
            })
          }
          {
            !isExecutive(user) &&
            <div className='col w-100'>
              <span className='text-up f14 bold c000' style={{ paddingBottom: 10 }}>INVOICES</span>
              {
                invoices.map((invoice) => {
                  const { file, label, id } = invoice;
                  return (
                    <React.Fragment>
                      {
                        Boolean(file) ?
                          <div className='row w-100 h-btn item-fee'>
                            <div className='row'>
                              <CheckOutlined style={{ color: '#219653', fontSize: 10, marginRight: 8 }} />
                              <span className='f12 caps title'>{label}</span>
                            </div>
                            <Button
                              labelClassName='f12 c3d92e0'
                              onClick={handleViewFile.bind(null, label, file)}
                              label='View' link />
                          </div>
                          :
                          <IconButton
                            type='text'
                            className='row h-start btn-fee'
                            onClick={handleAddInvoice.bind(null, id)}
                            icon={<PlusOutlined style={{ color: '#3d92e0' }} />} >
                            <span className='f12 c3d92e0'>{label}</span>
                          </IconButton>
                      }
                    </React.Fragment>
                  )
                })
              }
            </div>
          }
        </div>
      }
    </div>
  )
}
export const ProjectRowExpand = (iOptions, project) => {
  const {user, onFileView, onAddInvoice} = iOptions;
  const getColumns = () => {
    const columns = ProjectColumns.map((_, i) => {
      const col = { ..._ };
      if (i > 4) {
        col.onFileView = onFileView;
        col.onAddInvoice = onAddInvoice;
        col.render = Cell.bind(null, user, col)
      }
      return col
    });
    return columns;
  }

  return (
    <div className='col bgF2F2F2 w-100'>
      <DataGridView
        className='trans-table'
        rows={[project]}
        Columns={getColumns()}
        showHeader={false}
        showPagination={false}
        rowClassName={(task) => {
          return 'project-row-expand'
        }}
      />
    </div>
  )
}