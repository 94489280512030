import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../app";

export const NAME = 'PROJECT'
const initialState = {
  projects: null,
  currentProject: null,
  unexported: null,
  filterModel: {}
}

const projectSlice = createSlice({
  name: NAME,
  initialState: initialState,
  reducers: {
    fetchAllProjects: () => { },
    fetchProject: (state) => {
      state.currentProject = null
    },
    fetchProjectUnexported: () => {},
    createProject: () => { },
    updateProject: () => { },
    uploadProjectInvoices: () => { },
    createDeliverable: () => { },
    updateDeliverable: () => { },
    createRateCard: () => { },
    updateRateCard: () => { },
    exportDeliverables: () => { },
    exportDesginFees: () => { },
    setFilterModel: (state, action) => {
      state.filterModel = action.payload
    },
    resetFilterModel: (state) => {
      state.filterModel = {}
    },
    setProjects: (state, action) => {
      state.projects = action.payload
    },
    setCurrentProject: (state, action) => {
      state.currentProject = action.payload
    },
    setProjectUnexported: (state, action) => {
      state.unexported = action.payload
    },
    clear: () => {
      return { ...initialState }
    }
  }
})
export const projectActions = projectSlice.actions;

ReducerRegistry.register(NAME, projectSlice.reducer)