import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { taskActions, getImageDimension, getAssignedHHMM, getDesigners, getRatecards, getImageFileToBase64 } from "../../store";
import { FormModal, getDurationToValue, IconButton, FormUI } from "../../components";
import { DateService } from "../../services";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import FormFields from "./fields.json";

const DeleteActions = [
  { label: 'Delete', id: 'save', className: 'bgF2665E' },
]
const getInitialCloseFormData = (task) => {
  if (task) {
    return { sizeORquantity: task.taskItemId.initial || 1 }
  }
  return {}
}

export const DeleteTask = (props) => {
  const { task, open } = props;
  const [reason, setReason] = React.useState('')
  const _ref = React.useRef(null)
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (!open) {
      setReason('')
    }
  }, [open])
  const handleChange = (e) => {
    const { value } = e.target;
    setReason(value)
  }
  const handleDeleteTask = (e) => {
    if (_ref.current && _ref.current.validate) {
      let res = _ref.current.validate(e, true);
      if (!res.valid) {
        return
      }
      dispatch(taskActions.deleteTask({ taskId: task.taskId, reason }))
      props.onClose && props.onClose()
    }
  }
  const beforeContent = open && (
    <div className='col w-100'>
      <div className='f18 c333 reg'>You are trying to delete <span className='bold-700'>Task Id {task.taskId}</span></div>
      <ul className='f16 points'>
        <li>You cannot re-open the task once deleted</li>
        <li>If the task is already closed , it will not be get billed</li>
        <li>Once task is billed, then you cannot delete the task</li>
      </ul>
    </div>
  )
  return (
    <FormModal
      ref={_ref}
      open={open}
      className='task-del'
      fields={FormFields.TaskDelete}
      formData={{ reason }}
      onChange={handleChange}
      onSubmit={handleDeleteTask}
      onClose={props.onClose}
      title="Delete Task"
      actions={DeleteActions}
      beforeContent={beforeContent} />
  )
}

export const DesignerAssign = (props) => {
  const { task, open, type } = props;
  const isNewAssign = type === 'ASSIGN_TASK';
  const isReAssign = type === 'REASSIGN_TASK';
  const isUnAssign = type === 'UNASSIGN_TASK';
  const _ref = React.useRef(null)
  const dispatch = useDispatch();
  const designers = useSelector(getDesigners.bind(null, true))
  const time = open && task.assigns && task.assigns.current
  const [state, setState] = React.useState({
    formData: { previousFor: time ? time.assignedFor : 0 }
  })
  React.useEffect(() => {
    let formData = (open && time) ? { previousFor: time.assignedFor } : {}
    setState((_) => ({ ..._, formData }))
  }, [open])
  const getDialogTitle = () => {
    return (isNewAssign ? 'New ' : (isReAssign ? 'Re-' : 'Un ')) + `Assign`
  }
  const getOptions = () => {
    return designers.filter((_) => {
      return _.todayAssign && _.todayAssign.remainingHrs > 0
    });
  }
  const validateAssignFor = (iValue) => {
    const value = getDurationToValue(iValue);
    let res = { valid: true, error: '' }
    const { assignedTo } = state.formData
    if (value > Number(task.remainDuration)) {
      res.valid = false;
      res.error = "Assign Duration exceeds Remain Duration";
    } else if (value > Number((assignedTo.todayAssign && assignedTo.todayAssign.remainingHrs) || 8)) {
      res.valid = false;
      res.error = "Assign Duration exceeds Designer Available Duration";
    }
    return res;
  }
  const getFields = () => {
    let fields = FormFields.TaskDesignerAssign.map((_) => ({ ..._ }));
    if (isNewAssign) {
      fields = fields.slice(1)
    } else if (isUnAssign) {
      fields = [fields[0]]
    }
    return fields.map((f) => {
      let field = { ...f };
      if (f.attribute === 'assignedFor') {
        field.validator = validateAssignFor
      }
      if (fields.length < 3) {
        field.className = 'w-49'
      }
      if (isUnAssign) {
        field.disabled = false;
        field.maxDuration = time && time.assignedFor
      }
      return field
    })
  }
  const handleChange = (e) => {
    const formData = { ...state.formData };
    const { name, value } = e.target;
    formData[name] = value;
    setState((_) => ({ ..._, formData }))
  }
  const handleSubmit = (e) => {
    if (_ref.current && _ref.current.validate) {
      let res = _ref.current.validate(e, true);
      if (!res.valid) {
        return
      }
      let body = { assign: res.formJson, reassign: isReAssign, unassign: isUnAssign }
      dispatch(taskActions.assignDesigner({ taskId: task.taskId, assign: body }))
      props.onClose && props.onClose()
    }
  }
  const getAvailableHours = () => {
    const { assignedTo, assignedFor } = state.formData
    return ((assignedTo.todayAssign && assignedTo.todayAssign.remainingHrs) || 0) - getDurationToValue(assignedFor)
  }
  const beforeContent = open && (
    <div className='col w-100 c00085 bold-700'>
      <div className='f21 text-center'>Task Id {task.taskId} - {task.projectName}</div>
      {
        isUnAssign &&
        <div className='f18'>Are you sure? This action cannot be undone.</div>
      }
    </div>
  )
  const afterContent = open && (
    <React.Fragment>
      {
        (isReAssign || isUnAssign) &&
        <React.Fragment>
          <div className='f16 reg'>** Previously {task.responsible} assigned For : {getAssignedHHMM(time.assignedFor)}</div>
          {
            isReAssign &&
            <div className='f16 reg'>** Estimated Remaining Duration for this Task : {getAssignedHHMM(task.remainDuration)} </div>
          }
        </React.Fragment>
      }
      {
        isNewAssign &&
        <React.Fragment>
          <div className='f16 reg'>** Estimated Duration of Task : {getAssignedHHMM(task.estDuration)}</div>
          <div className='f16 reg'>** Estimated Remaining Duration for this Task : {getAssignedHHMM(task.remainDuration)}</div>
        </React.Fragment>
      }
      {
        Boolean(state.formData.assignedTo) &&
        <div className='f16 reg'>** {state.formData.assignedTo.userName} remaining Duration : {getAssignedHHMM(getAvailableHours())} </div>
      }
    </React.Fragment>
  )
  return (
    <FormModal
      ref={_ref}
      open={open}
      className='task-assign'
      fields={getFields()}
      formData={state.formData}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onClose={props.onClose}
      title={getDialogTitle()}
      getOptions={getOptions}
      beforeContent={beforeContent}
      afterContent={afterContent} />
  )
}

export const TaskUpdateNote = (props) => {
  const { task, open } = props;
  const _ref = React.useRef(null)
  const [state, setState] = React.useState({ formData: { isCompleted: true } })
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!open) {
      setState((_) => ({ ..._, formData: { isCompleted: true } }))
    }
  }, [open])
  const handleChange = (e) => {
    const { name, value } = e.target;
    const formData = { ...state.formData };
    formData[name] = value;
    setState((_) => ({ ..._, formData }))
  }
  const validateFile = (value) => {
    const res = { valid: true, error: '' };
    if (Array.isArray(value) && value.length > 0) {
      let pdfCount = 0, imgCount = 0;
      value.forEach((file) => {
        pdfCount += (file.type === "application/wps-office.pdf" || file.type === 'application/pdf') ? 1 : 0;
        imgCount += (file.type === "image/png" || file.type === 'image/jpg' || file.type === 'image/jpeg') ? 1 : 0;
      });
      if (!((pdfCount === 0 && imgCount > 0) || (imgCount === 0 && pdfCount === 1))) {
        res.error = "Either Single PDF or Multiple Image File";
        res.valid = false
      }
    }
    return res
  }
  const getFields = () => {
    let fields = []
    FormFields.TaskUpdateNote.map((_) => {
      let res = true, attribute = _.attribute, field = { ..._ }
      if (attribute === 'estExtraHr') {
        res = !state.formData.isCompleted && task && task.remainDuration === 0;
      } else if (attribute === 'upload') {
        field.validator = validateFile;
        res = state.formData.isCompleted;
      }
      if (res) {
        fields.push(field)
      }
    })
    return fields
  }
  const handleSubmit = async (e) => {
    if (_ref.current && _ref.current.validate) {
      let res = _ref.current.validate(e, true);
      if (!res.valid) {
        return
      }
      const body = res.formData;
      let updatedFilesPromise = [], filesInfo = [];
      if (state.formData.isCompleted && Array.isArray(state.formData.upload)) {
        for (let i = 0; i < state.formData.upload.length; i++) {
          let file = state.formData.upload[i]
          if (file.type === 'image/jpeg' || file.type === 'image/png') {
            let promise = getImageDimension(file)
              .then(layout => {
                filesInfo[i] = {
                  layout: layout,
                  lastModified: new Date(file.lastModified).toISOString()
                }
              })
            updatedFilesPromise.push(promise)
          }
        }
        try {
          await Promise.all(updatedFilesPromise)
        } catch (error) {
          console.error("handleTaskUpdate", error);
        }
      }
      body.append("filesInfo", JSON.stringify(filesInfo));
      dispatch(taskActions.updateDesignerNote({ taskId: task.taskId, body }))
      props.onClose && props.onClose()
    }
  }
  const beforeContent = open && (
    <div className='col w-100 c00085 bold-700'>
      <div className='f21 text-center'>Task Id {task.taskId} - {task.projectName}</div>
    </div>
  )
  return (
    <FormModal
      ref={_ref}
      open={open}
      className='task-update-node'
      fields={getFields()}
      formData={state.formData}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onClose={props.onClose}
      title="Update Note"
      beforeContent={beforeContent} />
  )
}
export const TaskClose = (props) => {
  const { task, open } = props;
  const [state, setState] = React.useState({
    additionalRCs: 0,
    additionalRCsFormData: [],
    formData: getInitialCloseFormData(open && task)
  })
  const _additionalRefs = React.useRef(state.additionalRCsFormData.map(() => React.createRef()));
  const _ref = React.useRef(null);
  const dispatch = useDispatch()
  const ratecards = useSelector(getRatecards);
  const designers = useSelector(getDesigners.bind(null, true))
  React.useEffect(() => {
    setState((_) => ({ ..._, formData: getInitialCloseFormData(open && task), additionalRCs: 0, additionalRCsFormData: [] }));
  }, [open])
  const getFields = () => {
    return FormFields.TaskClose.map((_) => {
      const field = { ..._ };
      if (field.attribute === "sizeORquantity") {
        field.unit = task && task.taskItemId.type
        field.step = task && task.taskItemId.initial;
      }
      return field
    })
  }
  const getAdditionalFields = () => {
    const fields = [],
      getFieldModified = (formData, iField) => {
        const field = { ...iField }
        if (Array.isArray(field.children)) {
          field.children = field.children.map(getFieldModified.bind(null, formData))
        }
        if (formData && formData.rateCardItem) {
          switch (field.attribute) {
            case "sizeORquantity":
              field.unit = formData.rateCardItem.type;
              field.step = formData.rateCardItem.initial;
              break;
            case "details":
              field.placeholder = formData.rateCardItem.detailsHint || field.placeholder;
              break;
            case "designer":
              field.type = formData.rateCardItem.ownerColumn ? 'text' : 'autocomplete';
              field.label = formData.rateCardItem.ownerColumn || field.label;
              field.placeholder = formData.rateCardItem.ownerHint || field.placeholder;
              break;
            case "rateCardItem":
              field.helpText = formData.rateCardItem.belowHint;
              break;
          }
        }
        return field
      };
    for (let i = 0; i < state.additionalRCs; i++) {
      const formData = state.additionalRCsFormData[i];
      const currentFields = FormFields.TaskAdditionalRCs.map((_) => {
        return getFieldModified(formData, _)
      })
      fields.push(currentFields)
    }
    return fields
  }
  const handleAddFieldChange = (i, e) => {
    const { value, name } = e.target;
    let additionalRCsFormData = state.additionalRCsFormData.map((formdata) => {
      return { ...formdata };
    });
    if (!additionalRCsFormData[i]) additionalRCsFormData[i] = {};
    additionalRCsFormData[i][name] = value;
    if (name === 'rateCardItem') {
      additionalRCsFormData[i].designer = '';
      additionalRCsFormData[i].sizeORquantity = value.initial || 1;
    }
    setState((_) => ({ ..._, additionalRCsFormData }));
  }
  const getOptions = (attribute) => {
    let options = [];
    switch (attribute) {
      case "rateCardItem":
        options = ratecards;
        break;
      case "designer":
        options = designers;
        break;
    }
    return options;
  }
  const handleAdditionRC = (direction) => {
    let additionalRCs = state.additionalRCs;
    let additionalRCsFormData = state.additionalRCsFormData.map((formdata) => {
      return { ...formdata };
    });
    if (direction === "-") {
      _additionalRefs.current = _additionalRefs.current.filter((_, i) => !(additionalRCsFormData[i].select));
      additionalRCsFormData = additionalRCsFormData.filter((_) => !_.select);
      additionalRCs--;
    } else {
      _additionalRefs.current.push(React.createRef());
      additionalRCsFormData.push({ sendDate: new Date() })
      additionalRCs++;
    }
    setState((_) => ({ ..._, additionalRCs, additionalRCsFormData }));
  }
  const handleChange = (e) => {
    const { value, name } = e.target;
    const formData = { ...state.formData }
    formData[name] = value
    setState((_) => ({ ..._, formData }));
  }
  const disableDelete = () => {
    let disable = true
    if (state.additionalRCs) {
      disable = state.additionalRCsFormData.filter((_) => {
        return _.select;
      }).length === 0
    }
    return disable
  }
  const handleSave = async (e) => {
    if (_ref.current && _ref.current.validate) {
      let res = _ref.current.validate(e, true), additionalJSON = [];
      let overallValid = res.valid;
      _additionalRefs.current.map((ref, i) => {
        let rcRes = ref.current.validate(e, true);
        overallValid = overallValid && rcRes.valid;
        additionalJSON[i] = rcRes.formJson;
      })
      if (!overallValid) {
        return
      }
      const body = { ...res.formJson }
      const approvalFiles = [];
      for (let i = 0; i < res.formJson.thumbnail.length; i++) {
        const file = res.formJson.thumbnail[i];
        let layout;
        let data = await getImageFileToBase64(file);
        if (file.type === "image/png" || file.type === "image/jpeg") {
          layout = await getImageDimension(file);
        }
        approvalFiles[i] = {
          layout,
          buffer: data,
          type: file.type,
          name: file.name,
        }
      }
      if (approvalFiles.length > 0) {
        body.approvalFiles = approvalFiles
      }
      delete body.thumbnail;
      const rateCards = additionalJSON.map((formJson) => {
        let rc = {};
        rc.rateCardId = formJson.rateCardItem;
        rc.sendDate = DateService(formJson.sendDate).format('YYYY-MM-DD');
        rc.designer = formJson.designer || '';
        rc.details = formJson.details || '';
        rc.sizeORquantity = formJson.sizeORquantity || '';
        rc.entryDate = DateService().toISOString();
        rc.billing = false;
        if (!task.isIndependent) {
          rc.fromTask = true
        }
        return rc;
      });
      if (rateCards.length > 0) {
        body.rateCards = rateCards
      }
      dispatch(taskActions.closeTask({ taskId: task.id, body }))
      props.onClose && props.onClose()
    }
  }
  const beforeContent = open && (
    <div className='col w-100 c00085 bold-700'>
      <div className='f21 text-center'>Task Id {task.taskId} - {task.projectName}</div>
      <div className='f18'>Are you sure? This action cannot be undone.</div>
    </div>
  )
  const afterContent = (
    <div className='col w-100'>
      <div className="row">
        <div className="col f-rest addline">
          <span>&nbsp;</span>
        </div>
        <div className="col additional">
          <span className='f11 med text-center'>Additional Ratecards related to this task</span>
          <span className='f10 text-center'>Eg: Upload, Legal Check, Hand Delivery or Printouts</span>
        </div>
        <div className="col f-rest addline">
          <span>&nbsp;</span>
        </div>
      </div>
      {
        state.additionalRCs > 0 &&
        <div className='col'>
          {
            getAdditionalFields().map((fields, i) => {
              return (
                <FormUI
                  Fields={fields}
                  getOptions={getOptions}
                  className={`row h-btn fwarp v-start`}
                  direction="vertical"
                  ref={_additionalRefs.current[i]}
                  formData={state.additionalRCsFormData[i] || {}}
                  onChange={handleAddFieldChange.bind(null, i)}
                />
              )
            })
          }
        </div>

      }
      <div className='row v-ctr h-ctr add-rc-sec'>
        <IconButton
          className='btn normal'
          onClick={handleAdditionRC.bind(null, '+')}
          icon={<PlusOutlined style={{ fontSize: 12 }} />} >
          Add
        </IconButton>
        <IconButton
          className='btn normal'
          disabled={disableDelete()}
          onClick={handleAdditionRC.bind(null, '-')}
          icon={<DeleteFilled style={{ fontSize: 12 }} />}>
          Delete
        </IconButton>
      </div>
    </div>
  )
  return (
    <FormModal
      ref={_ref}
      open={open}
      className='task-close'
      fields={getFields()}
      formData={state.formData}
      onChange={handleChange}
      onSubmit={handleSave}
      onClose={props.onClose}
      title="Close Task"
      afterContent={afterContent}
      beforeContent={beforeContent}
      actions={[{ id: 'save', label: "Close", className: 'bgF2665E' }]} />
  )
}