import "./saga";

const ROLES = [
  { label: 'Admin', id: 'Admin' },
  { label: 'Designer', id: 'Designer' },
  { label: 'Executive', id: 'Executive' },
  { label: 'Finance', id: 'Finance' }
]
export * from "./slice";

export const getUserRoles = () => {
  return ROLES
}
export const getUserState = (state) => state.user;

export const getLogInStatus = (state) => {
  let userState = getUserState(state);
  return userState.logInStatus
}
export const getCurrentUser = (state) => {
  let userState = getUserState(state);
  return userState && userState.currentUser
}
export const isDesigner = (user) => {
  return user && user.role === 'Designer'
}
export const isExecutive = (user) => {
  return user && user.role === 'Executive'
}
export const isFinance = (user) => {
  return user && user.role === 'Finance'
}
export const isAdmin = (user) => {
  return user && user.role === 'Admin'
}
export const isUserDisabled = (user) => {
  return user.status === 'disabled'
}
export const getAllUsersOriginal = (state) => {
  let userState = getUserState(state)
  return userState && userState.users
}
export const getAllUsers = (state) => {
  let users = getAllUsersOriginal(state);
  return users ? Object.values(users) : []
}
export const getUserById = (userId, state) => {
  const users = getAllUsersOriginal(state);
  return users[userId] || {};
}
export const getActiveUsers = (state) => {
  let users = getAllUsers(state);
  return users.filter((_) => !_.disabled)
}
export const getDesigners = (activeOnly, state) => {
  const activeUsers = activeOnly ? getActiveUsers(state) : getAllUsers(state);
  return activeUsers.filter((user) => isDesigner(user))
}
export const getExecutives = (activeOnly, state) => {
  const activeUsers = activeOnly ? getActiveUsers(state) : getAllUsers(state);
  return activeUsers.filter((user) => isExecutive(user))
}
export const isLoggedOut = (state) => {
  return getUserState(state).isLoggedOut
}