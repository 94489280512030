import { createSlice } from "@reduxjs/toolkit";
import { put } from "redux-saga/effects";
import SagaRegistryHelper from "./SagaRegistry";
import ReducerRegistryHelper from "./ReducerRegistry";
import moment from "moment";
import Random from "randomstring";
const DATE_FORMAT = 'DD/MMM/YYYY';
const DATE_FORMAT_TIME = 'DD/MMM/YYYY h:mm a';

const initialState = { notification: null, loading: false, fullLoading: false, progressTokens: [] }

const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setAlert: (state, actions) => {
      state.notification = actions.payload
    },
    setLoading: (state, actions) => {
      state.loading = actions.payload
    },
    setFullLoading: (state, actions) => {
      state.fullLoading = actions.payload
    },
    startProgress: (state, actions) => {
      state.progressTokens = [actions.payload, ...state.progressTokens]
    },
    stopProgress: (state, actions) => {
      const progressTokens = [...state.progressTokens]
      let index = progressTokens.indexOf(actions.payload);
      if (index > -1) {
        progressTokens.splice(index, 1)
      }
      state.progressTokens = [...progressTokens]
    }
  }
})
const actions = appSlice.actions;

export const sorter = (attribute, recordA, recordB) => {
  var nameA = recordA[attribute], nameB = recordB[attribute];
  nameA = ((typeof nameA === 'object' ? nameA.label : nameA) || '')
  nameB = ((typeof nameB === 'object' ? nameB.label : nameB) || '')
  if (typeof nameA === 'string') nameA = String(nameA).toLowerCase();
  if (typeof nameB === 'string') nameB = String(nameB).toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
}

export function* setAlertAndLoading(isLoading = null, alert = null, fullLoading = null) {
  if (alert !== null) {
    alert.severity = alert.success === false ? 'error' : 'success';
    yield put(actions.setAlert(alert));
  }
  if (isLoading !== null) {
    yield put(actions.setLoading(isLoading));
  }
  if (fullLoading !== null) {
    yield put(actions.setFullLoading(fullLoading));
  }
}

function* onSagaErrorCatch(error, errorMsg) {
  let alert;
  error = typeof error == "string" ? JSON.parse(error) : error;
  if (errorMsg) {
    alert = { success: false, message: errorMsg }
  } else if (error.data && error.data.message) {
    alert = { success: false, message: (error.data && error.data.message) || 'Request Failed' }
  }
  if (alert) {
    yield setAlertAndLoading(false, alert, false)
  }
}
export const getDateInFormat = (iDate) => {
  return moment(iDate).format(DATE_FORMAT)
}
export const getDateTimeInFormat = (iDate) => {
  return moment(iDate).format(DATE_FORMAT_TIME)
}
export const getAssignedHHMM = (iHHMM) => {
  let splitVal = String(iHHMM).split('.');
  if (splitVal.length === 0) {
    return iHHMM;
  } else if (splitVal.length === 1) {
    return `${splitVal[0]}:00`
  } else {
    let divide = Number(splitVal[1]) === 75 || Number(splitVal[1]) === 25 ? 0.01 : 0.1;
    return `${splitVal[0]}:${Number(splitVal[1]) * divide * 60}`
  }
}
export function* requestHandler(callback, errorMsg, showPregress = true) {
  let token = Random.generate(8);
  try {
    if (showPregress) {
      yield put(actions.startProgress(token))
    }
    yield callback()
    if (showPregress) {
      yield put(actions.stopProgress(token))
    }
  } catch (error) {
    console.log('requestHandler', error);
    yield onSagaErrorCatch(error, errorMsg);
    if (showPregress) {
      yield put(actions.stopProgress(token))
    }
  }
}
export const SagaRegistry = new SagaRegistryHelper();
export const ReducerRegistry = new ReducerRegistryHelper();
export const AppActions = actions;
ReducerRegistry.register('app', appSlice.reducer);

const getState = (state) => state.app;

export const isLoading = (state) => {
  const _state = getState(state);
  return _state.loading
}
export const getFullLoading = (state) => {
  const _state = getState(state);
  return _state.fullLoading
}
export const getAlert = (state) => {
  const _state = getState(state);
  return _state.notification
}
export const isProgress = (state) => {
  const _state = getState(state);
  return _state.progressTokens.length > 0
}
export const getImageDimension = (imgFile) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = window.URL.createObjectURL(imgFile)
    img.onload = function () {
      resolve({
        width: this['width'] * (72 / 96),
        height: this['height'] * (72 / 96),
      })
    }
  })
}
export const getImageFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    let fReader = new FileReader();
    fReader.onloadend = async (event) => {
      let target = event.target;
      let image = target.result;
      resolve(image)
    }
    fReader.readAsDataURL(file);
  })
}
export * from "./axios"
