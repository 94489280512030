import React, { useEffect, useState } from 'react';
import { Switch } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { EditFilled } from '@ant-design/icons';
import { AppErrorBoundry, Loading, DataGridView } from "../../components";
import { projectActions, isMiscLoaded, getDateInFormat, getCurrentProject, isProjectStopped, getProjectUnExport } from '../../store';
import { DelRowExpandDetails } from "./ExpandRow"
import "./project.scss";

const Columns = [
  { title: 'Artwork / Project', dataIndex: 'projectName', key: 'projectName', width: '19%', rowIndicator: "Exported" },
  { title: 'Thumbnail', dataIndex: 'thumbnail', key: 'thumbnail', width: '9%', render: 'upload-view' },
  { title: 'Rate Card Item', dataIndex: 'rateCardItem', key: 'rateCardItem', width: '12%' },
  { title: 'Size/Qty', dataIndex: 'sizeORquantity', key: 'sizeORquantity', width: '8%' },
  { title: 'Details', dataIndex: 'details', key: 'details', width: '10%' },
  { title: 'Sent Date', dataIndex: 'sendDate', key: 'sendDate', width: '10%' },
  { title: 'Task ID', dataIndex: 'taskId', key: 'taskId', width: '6%' },
  { title: 'File Owner', dataIndex: 'designer', key: 'designer', width: '9%' },
  { title: 'Last Update', dataIndex: 'lastUpdated', key: 'lastUpdated', width: '10%' },
  { title: '', dataIndex: 'actions', key: 'actions', render: 'actions', noborder: true, cellClassName: 'h-arnd', width: '5%' },
]

export const ProjectDetailsScreen = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const miscLoaded = useSelector(isMiscLoaded)
  const project = useSelector(getCurrentProject)
  const unexported = useSelector(getProjectUnExport);
  const [state, setState] = useState({ showDeleted: true, show: false });
  useEffect(() => {
    dispatch(projectActions.fetchProjectUnexported());
  }, []);
  useEffect(() => {
    if (project) {
      if (project === 'NOT_FOUND') {
        return
      }
      if (!state.show) {
        setState((_) => ({ ..._, show: true }))
      }
    }
  }, [project]);
  useEffect(() => {
    if (miscLoaded && Boolean(unexported)) {
      dispatch(projectActions.fetchProject(projectId)); //Dispatch the action 
    }
  }, [miscLoaded, unexported]);
  const handleDeleteSwitch = (checked) => {
    setState((_) => ({ ..._, showDeleted: checked }))
  }
  const handleEditDeliverable = (del) => {

  }
  const getDeliverableActions = (del) => {
    let actions = [], style = { fontSize: 18 };
    if (!(del.fromTask || (!del.fromTask && del.billing) || isProjectStopped(project))) {
      actions.push({
        Component: EditFilled, onClick: handleEditDeliverable.bind(null, del),
        style: { ...style, color: '#bdbdbd' }
      })
    }
    return actions;
  }
  const getColumns = () => {
    return Columns.map((_) => {
      let col = { ..._ }
      if (col.render === 'actions') {
        col.actions = getDeliverableActions
      }
      return col;
    })
  }
  const getRows = () => {
    if (!project) return null;
    if (!state.showDeleted) {
      return project.deliverables.filter((_) => {
        return !Boolean(_.deleted)
      })
    }
    return project.deliverables
  }
  const rows = getRows()
  return (
    <AppErrorBoundry>
      {
        (Boolean(project) && state.show) &&
        <div className='col w-100 h-100 project-view o-hide bgE5E5E5'>
          <div className='col w-100 bgF2F2F2 view-details c828282'>
            <div className='row w-100 h-btn f14 reg'>
              <div className='row'>
                PROJECT <span className='gap bold-700'>{project.projectId}</span>
                <span className='gap'>Started on {getDateInFormat(project.startDate)}</span>
              </div>
              <div className='row h-ctr'>
                <span className='bold-700 c333 text-up'>Client</span>
                <span className='gap bold-700'>{project.clientContact.name}</span>
                <span className='gap'>{project.clientContact.phone}</span>
                <span className='gap'>{project.clientContact.email}</span>
              </div>
              <div className='row h-end'>
                <span className='bold-700 c333 text-up'>Icarus</span>
                <span className='gap bold-700 text-up'>{project.icarusContact.userName}</span>
                <span className='gap'>{project.icarusContact.email}</span>
              </div>
            </div>
            <div className='col v-ctr name-sec line-12'>
              <div className='f28 reg c4F4F4F'>
                {project.client.name}
                <span className='gap bold-700'>{project.projectName}</span>
              </div>
              <div className="c4F4F4F f22 text-center desc"> {project.description}</div>
              <div className="f16 bold-700 c333">{project.statusLabel} Phase</div>
              {
                isProjectStopped(project) && <div className="f16 bold-700 c333" >Reason : {project.statusReason}</div>
              }
            </div>
          </div>
          <div className='col w-100 cE0E0E0 f-rest view-deliverables o-hide'>
            <div className='col w-100 h-100 del-table-container o-hide' >
              <DataGridView
                className=''
                rows={rows}
                expandable
                emptyMessage='No Deliverbles'
                Columns={getColumns()}
                initialPageSize={10}
                expandedRowRender={DelRowExpandDetails}
                rowClassName={(del) => {
                  return (del.deleted ? 'deleted' : (del.unexportedRC === 0 ? 'exported' : ''))
                }}
              />
            </div>
          </div>
        </div>
      }
      {
        !Boolean(project) &&
        <Loading />
      }
    </AppErrorBoundry>
  )
}

