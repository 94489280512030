import React from 'react';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import {
  getDesigners, getAssignedHHMM, getClients, getExecutives, getBrands, setTaskFilter, isFinance, userActions,
  getAllClientContactsAsList, getStatusOptions, getTaskFilterModel, taskActions, getCurrentUser, isAdmin
} from "../../store";
import { FormUI, Button } from "../../components";
import { Collapse } from 'antd';
import { DateService } from "../../services";
import FormFields from "./fields.json";

const { Panel } = Collapse;
const InitialState = {
  expandActiveKey: '1',
  exportRefreshing: false,
}
export const TaskFilter = () => {
  const dispatch = useDispatch()
  const clients = useSelector(getClients);
  const brands = useSelector(getBrands);
  const currentUser = useSelector(getCurrentUser);
  const isFinanceUser = isFinance(currentUser);
  const designers = useSelector(getDesigners.bind(null, false));
  const executives = useSelector(getExecutives.bind(null, false));
  const clientContacts = useSelector(getAllClientContactsAsList);
  const filterModel = useSelector(getTaskFilterModel);
  const statusList = getStatusOptions()
  const [state, setState] = React.useState({ ...InitialState, });
  React.useEffect(() => {
    if (currentUser) {
      setState((_) => ({ ..._, exportRefreshing: false }))
    }
  }, [currentUser])
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    const model = JSON.parse(JSON.stringify(filterModel));
    if (name === 'status' && value !== model.status) {
      let valueStatus = statusList.find((_) => (_.value === value));
      let modelStatus = statusList.find((_) => (_.value === model.status));
      if (Boolean(modelStatus) && Boolean(valueStatus) && valueStatus.fetchKey !== modelStatus.fetchKey) {
        dispatch(taskActions.fetchAllTasks(valueStatus.fetchKey));
      }
    }
    model[name] = value;
    dispatch(taskActions.setTaskFilterModel(model))
    setTaskFilter(currentUser.userId, model)
  }
  const getFilterOptions = (attribute) => {
    let options = [],
      removeDisable = (_) => ({ ..._, disabled: false });
    switch (attribute) {
      case 'type':
        options = [
          { label: 'Independent', id: 'independent' },
          { label: 'Non-Independent', id: 'nonIndepedent' },
        ]
        break;
      case "status": options = statusList; break;
      case "clients": options = clients; break;
      case "designer": options = designers.map(removeDisable); break;
      case "icarusContact": options = executives.map(removeDisable); break;
      case "clientBrands": options = brands; break;
      case "clientContacts": options = clientContacts; break;
      default:
        break;
    }
    return options
  }
  const handleFilterExpand = () => {
    const expandActiveKey = state.expandActiveKey === '1' ? '' : '1';
    setState((_) => ({ ..._, expandActiveKey }))
  }
  const handleFilterReset = (e) => {
    e.stopPropagation();
    setTaskFilter(currentUser.userId, {});
    dispatch(taskActions.fetchAllTasks("unbilled"));
    dispatch(taskActions.resetTaskFilterModel())
  }
  const getExportStatus = () => {
    return currentUser.exportStatus ? (currentUser.exportStatus.tasks || {}) : {}
  }
  const handleExport = () => {
    if (!getExportStatus().exporting) {
      dispatch(taskActions.exportTasks())
    }
  }
  const handleRefreshExport = () => {
    setState((_) => ({ ..._, exportRefreshing: true }))
    dispatch(userActions.validateToken())
  }
  const exportStatus = getExportStatus();
  return (
    <div className='row w-100 task-filter v-start'>
      <div className='col filter-section f-rest filter bgFFF'>
        <Collapse
          expandIconPosition='end'
          activeKey={state.expandActiveKey}
          onChange={handleFilterExpand}>
          <Panel
            key="1"
            header={(
              <div className='row h-btn w-100'>
                <span className='bold f14'>FILTER TASKS</span>
                <Button
                  labelClassName='f14 c3d92e0'
                  onClick={handleFilterReset}
                  label='Reset' link />
              </div>
            )} >
            <div className='row w-100 v-start'>
              <FormUI
                show={true}
                Fields={FormFields.TaskFilter}
                formData={filterModel}
                onChange={handleFilterChange}
                getOptions={getFilterOptions}
                className={`row v-start h-btn fwarp f-rest`}
                direction={'vertical'}
              />
            </div>
          </Panel>
        </Collapse>
      </div>
      <div className='col filter-section time-sheet bgFFF h-100'>
        <Collapse activeKey={state.expandActiveKey} className='h-100'>
          <Panel
            header={<span className='bold f14'>{isFinanceUser ? "BILLING" : "DESIGNER'S TIMESHEET"}</span>}
            key="1" className='h-100' showArrow={false}>
            {
              isFinanceUser &&
              <div className='col h-100 billing'>
                <div className='row h-end'>
                  {
                    exportStatus.exporting &&
                    <Button
                      labelClassName='f14 c3d92e0'
                      onClick={handleRefreshExport}
                      label='Refresh' link />
                  }
                </div>
                <div className='w-100 h-ctr col v-ctr'>
                  <Button
                    className='bgBDBDBD'
                    disabled={exportStatus.exporting}
                    labelClassName='f14 bold-700 text-up'
                    onClick={handleExport}
                    label={exportStatus.exporting ? 'Exporting...' : 'Export Items'}
                  />
                  <span className='f11 note'>*exports all closed unbilled items</span>
                </div>
                {
                  !exportStatus.exporting &&
                  <div className='col w-100'>
                    <div className="row h-btn f14">
                      <span>Status:</span>
                      <span className={`caps ${exportStatus.status === 'error' ? 'cF2665E' : 'c219653'}`}>{exportStatus.status}</span>
                    </div>
                    <div className="row h-btn f14">
                      <span>DateTime:</span>
                      <span>{DateService(exportStatus.exportDateTime).format('DD/MM/YYYY, h:mm a')}</span>
                    </div>
                    {
                      exportStatus.status === 'error' && <span className='cF2665E text-center note f14'>Error While Exporting, Contact Admin</span>
                    }
                    {
                      Boolean(exportStatus.status === 'success' && !exportStatus.path) && <span className='note f12 text-center'>No Unexported Items</span>
                    }
                    {
                      Boolean(exportStatus.status === 'success' && exportStatus.path) &&
                      <Link className='text-center c3d92e0 note f14' to={exportStatus.path} target='_blank' >Link to access exported folder</Link>
                    }
                  </div>
                }
                {
                  state.exportRefreshing &&
                  <div className='row v-ctr h-ctr note'>
                    <LoadingOutlined style={{ fontSize: 32, marginRight: 16 }} />
                    <span className='f16'>Refreshing...</span>
                  </div>
                }
              </div>
            }
            {
              !isFinanceUser &&
              <div className='col'>
                <div className='col f-rest'>
                  {
                    designers.map((user) => {
                      return !user.disabled && (
                        <div className='row' key={user.userId}>
                          <div className='col f-rest'>{user.userName}</div>
                          <div className='col'>{user.todayAssign ? getAssignedHHMM(user.todayAssign.remainingHrs || 0) : '-'}</div>
                        </div>
                      )
                    })
                  }
                </div>
                {/*
                  isAdmin(currentUser) &&
                  <div className='col group-link h-ctr v-ctr'>
                    <Link to='groups' className='f14' >View Indepedent Task Grouping</Link>
                  </div>
                */}
              </div>
            }
          </Panel>
        </Collapse>
      </div>
    </div>
  )
}