import { put, takeEvery, select } from "redux-saga/effects";
import { SagaRegistry, axios, requestHandler, setAlertAndLoading, getDateInFormat, getAssignedHHMM } from "../app";
import { taskActions, TASK_STORE_NAME } from "./slice";
import { getAllUsersOriginal, isFinance, getCurrentUser, userActions, isDesigner } from "../user";
import { Buffer } from "buffer";
import { getTaskFilter, getStatusOptions, isTaskDelayed } from "./helper";
import {
  getBrands, getClientById, getClients, getClientContacts, getClientContactById,
  getBrandById, getRatecards, getRatecardById
} from "../misc";

const StatusList = getStatusOptions();
const getAllProjects = (state) => {
  return state.PROJECT.projects
}
const getSizeOrQuantity = (ratecard, sizeORquantity, isFinanceUser) => {
  let text = ''
  if (ratecard && sizeORquantity) {
    if (ratecard.unit === 'currency') {
      text = `Rs. ${isFinanceUser ? sizeORquantity : 'XXXX'}`;
    } else {
      let unit = '';
      switch (ratecard.unit) {
        case "number": 
          unit = 'No';
          if (sizeORquantity > 1) unit = "No's";
          break;
        case "day": 
          unit = 'Day';
          if (sizeORquantity > 1) unit = "Days";
          break;
        default: unit = 'mb'
      }

      text = `${sizeORquantity} ${unit}`
    }
  }
  return text;
}
function getTaskMap(_, iList) {
  const { users, clients, clientContacts, brands, ratecards, projects, currentUser } = iList
  let task = { ..._, id: String(_.taskId), key: String(_.taskId) },
    client, clientContact, brand, ratecard, user, responsible = 'See History';
  const project = projects ? projects[task.projectId] : {};
  if (task.deleted) {
    task.status = 'Deleted'
  } else if (task.billing || task.closed) {
    let text = task.billing ? 'Exported' : 'Closed';
    let date = getDateInFormat(task.billing ? task.billingTime : task.closedTime)
    task.status = `${text}<br/><span class='sh f12'>(${date})</span>`
  } else {
    task.status = isTaskDelayed(task) ? 'Delayed' : 'Opened';
  }
  ratecard = getRatecardById(task.taskItemId, null, ratecards);
  user = users[task.icarusContact];

  if (!task.isIndependent) {
    client = project.client;
    clientContact = project.clientContact;
    brand = project.clientBrand;
    task.projectId = project;
  } else {
    client = task.client;
    clientContact = task.clientContact;
    brand = task.clientBrand;
    if (client) {
      client = getClientById(client, null, clients);
    }
    if (clientContact && client) {
      clientContact = getClientContactById(client.id, clientContact, null, clientContacts);
    }
    if (brand) {
      brand = getBrandById(brand, null, brands);
    }
  }
  if (client) {
    task.client = client;
    task.clientName = client.name;
  }
  if (clientContact) {
    task.clientContact = { ...clientContact, label: clientContact.name };
    task.clientContactName = clientContact.name;
  }
  if (brand) {
    task.clientBrand = brand;
    task.brandName = brand.title;
  }
  if (ratecard) {
    task.taskItemId = ratecard;
    task.ratecardTitle = ratecard.title
  }
  if (user) {
    task.icarusContact = user;
    task.icarusContactName = user.userName
  }
  task.sizeORquantityText = getSizeOrQuantity(ratecard, task.sizeORquantity, isFinance(currentUser))
  task.estDeliveryDateStr = getDateInFormat(task.estDeliveryDate)
  if (task.deliverableId !== undefined && project.deliverables) {
    task.rateCards = project.deliverables[task.deliverableId] ? project.deliverables[task.deliverableId].rateCards : []
  }
  if (Array.isArray(task.rateCards) && task.rateCards.length) {
    task.rateCards = task.rateCards.map((_) => {
      let ratecard = getRatecardById(_.rateCardId, null, ratecards);
      return {
        ..._,
        ratecardTitle: ratecard ? ratecard.title : '',
        sizeORquantityText: getSizeOrQuantity(ratecard, _.sizeORquantity, isFinance(currentUser)),
        designer: users[_.designer] ? users[_.designer].userName : _.designer
      }
    })
  }
  if (task.deleted) {
    task.deleted.deletedBy = users[task.deleted.deletedBy];
  }
  if (_.assigns && _.assigns.current) {
    responsible = users[_.assigns.current.assignedTo];
    responsible = (responsible && responsible.userName) || '-';
    task.assignedFor = task.closed ? "See History" : getAssignedHHMM(_.assigns.current.assignedFor)
    if (_.assigns.current.assignedFor) {
      // responsible = `${responsible} ${getAssignedHHMM(_.assigns.current.assignedFor)}`
    }
  }
  if (_.assigns && _.assigns.history) {
    _.assigns.history = _.assigns.history.map((assign) => {
      return {
        ...assign,
        assignedTo: users[assign.assignedTo],
        assignedForHHMM: getAssignedHHMM(assign.assignedFor),
        workedHHMM: assign.worked ? getAssignedHHMM(assign.worked) : null,
      }
    })
  }
  _.estDurationHHMM = getAssignedHHMM(_.estDuration);
  _.remainDurationHHMM = getAssignedHHMM(_.remainDuration);
  task.responsible = responsible;
  return task
}
function* fetchAllTasks({ payload }) {
  yield requestHandler(function* () {
    const currentUser = yield select(getCurrentUser)
    let filter = getTaskFilter(currentUser.userId)
    let status = payload;
    if (!status && filter.status) {
      let option = StatusList.find(({ value }) => value === filter.status)
      status = option ? option.fetchKey : status
    }
    if (!status) {
      status = isDesigner(currentUser) ? 'designer' : 'unbilled'
    }
    const PATH = `/tasks/${status}`
    let res = yield axios.get(PATH);
    try {
      const iList = { currentUser }
      iList.users = yield select(getAllUsersOriginal);
      iList.clients = yield select(getClients);
      iList.clientContacts = yield select(getClientContacts)
      iList.brands = yield select(getBrands);
      iList.ratecards = yield select(getRatecards);
      iList.projects = yield select(getAllProjects);
      for (const taskId in res) {
        if (Object.hasOwnProperty.call(res, taskId)) {
          const _ = res[taskId];
          if (_) {
            try {
              res[taskId] = getTaskMap(_, iList);
            } catch (error) {
              console.log(`fetchAllTasks => ${taskId}`, error);
            }
          }
        }
      }
    } catch (error) {
      console.log("fetchAllProjects error", error);
    }
    yield put(taskActions.setTasks(res))
  }, "Failed to fetch tasks", false);
}
function* fetchTaskById({ payload }) {
  yield requestHandler(function* () {
    let task = yield axios.get('/tasks/' + payload);
    if (task) {
      const currentUser = yield select(getCurrentUser)
      task = yield getTaskMap(task, currentUser)
      yield put(taskActions.setTaskById(task))
    }
  }, "Failed to fetch task")
}
function* createTask({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    yield axios.post('/tasks', payload);
    yield put(taskActions.fetchAllTasks())
    yield setAlertAndLoading(null, { message: "Task Created Successfully." })
  }, "Failed to create task")
}
function* updateTask({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    yield axios.put('/tasks/' + payload.taskId, payload);
    yield put(taskActions.fetchAllTasks())
    yield setAlertAndLoading(false, { message: "Task Updated Successfully." })
  }, "Failed to update task")
}
function* deleteTask({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const { taskId, reason } = payload
    yield axios.delete('/tasks/' + taskId + '?reason=' + reason);
    yield put(taskActions.fetchAllTasks())
    yield setAlertAndLoading(false, { message: "Task Deleted Successfully." })
  }, "Failed to delete task")
}
function* updateDesignerNote({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const { taskId, body } = payload
    yield axios.put(`/tasks/${taskId}/designer/update`, body, { ...axios.getFormDataContentType() });
    yield put(taskActions.fetchAllTasks())
    yield setAlertAndLoading(false, { message: "Task Updated Successfully." })
  }, "Failed to update task")
}
function* assignDesigner({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const { taskId, assign } = payload
    yield axios.put(`/tasks/${taskId}/assign`, assign);
    yield put(taskActions.fetchAllTasks())
    yield setAlertAndLoading(false, { message: "Task Updated Successfully." })
  }, "Failed to update task")
}
function* closeTask({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true, null, true);
    const { taskId, body } = payload
    for (let i = 0; i < body.approvalFiles.length; i++) {
      const { buffer, type } = body.approvalFiles[i];
      if (type === "image/png" || type === "image/jpeg") {
        let compressed = yield axios.post('/helper/compress-image', { file: buffer, type });
        compressed = `${type === "image/png" ? "data:image/png;base64," : "data:image/jpeg;base64,"}${Buffer.from(compressed.buffer).toString("base64")}`
        body.approvalFiles[i].buffer = compressed
      }
    }
    yield axios.put(`/tasks/${taskId}/close`, body);
    yield put(taskActions.fetchAllTasks('unbilled'))
    yield setAlertAndLoading(false, { message: "Task Closed Successfully." }, false)
  }, "Failed to close task")
}
function* exportTasks() {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    yield axios.post(`/tasks/billing`, {});
    yield put(userActions.validateToken())
    yield setAlertAndLoading(false, { message: "Task Export initiated successfully." })
  }, "Failed to export tasks")
}
SagaRegistry.register(function* taskSaga() {
  yield takeEvery(TASK_STORE_NAME + "/fetchAllTasks", fetchAllTasks);
  yield takeEvery(TASK_STORE_NAME + "/fetchTaskById", fetchTaskById);
  yield takeEvery(TASK_STORE_NAME + "/createTask", createTask);
  yield takeEvery(TASK_STORE_NAME + "/updateTask", updateTask);
  yield takeEvery(TASK_STORE_NAME + "/deleteTask", deleteTask);
  yield takeEvery(TASK_STORE_NAME + "/assignDesigner", assignDesigner);
  yield takeEvery(TASK_STORE_NAME + "/closeTask", closeTask);
  yield takeEvery(TASK_STORE_NAME + "/updateDesignerNote", updateDesignerNote);
  yield takeEvery(TASK_STORE_NAME + "/exportTasks", exportTasks);
})