import "./saga";
export * from "./slice";

const getState = (state) => state.misc;

export const getRateCardTypes = () => {
  return [
    { label: 'Artwork', value: 'Artwork' },
    { label: 'Render', value: 'Render' },
    { label: 'Printout', value: 'Printout' },
    { label: 'Mockup', value: 'Mockup' },
    { label: 'Image', value: 'Image' },
    { label: 'Keyline', value: 'Keyline' },
    { label: 'Day', value: 'Day' },
    { label: 'CD', value: 'CD' },
    { label: 'DVD', value: 'DVD' },
    { label: 'Trip', value: 'Trip' },
    { label: 'Unit', value: 'Unit' },
    { label: 'File', value: 'File' }
  ]
}
export const getRateCardUnits = () => {
  return [
    { label: 'Number', value: 'number' },
    { label: 'Currency', value: 'currency' },
    { label: 'Day', value: 'day' },
    { label: 'MB', value: 'mb' },
  ]
}
export const getRateCardsAllowedInTasks = () => {
  return [
    { label: 'Yes', id: 'Yes' },
    { label: 'No', id: 'No' },
  ]
}

export const getRatecards = (state) => getState(state).ratecards;

export const getClients = (state) => getState(state).clients;

export const getClientContacts = (state) => getState(state).clientContacts;

export const getBrands = (state) => getState(state).brands;

export const getBrandsByClient = (clientId, state) => {
  let brands = getState(state).brands;
  if (Array.isArray(brands)) {
    brands = brands.filter((_) => {
      if (Array.isArray(clientId)) {
        return clientId.indexOf(_.clientId) > -1
      } else {
        return _.clientId === clientId
      }
    })
  }
  return brands
};
export const getRatecardById = (rcId, state, ratecards) => {
  const _ratecards = ratecards ? ratecards : getRatecards(state);
  let rc;
  if (Array.isArray(_ratecards) && _ratecards.length > 0) {
    rc = _ratecards.find((_) => (_.rcId === rcId))
  }
  return rc;
}
export const getClientById = (clientId, state, clients) => {
  let _clients = clients ? clients : getClients(state);
  return _clients.find((_) => (clientId === _.id)) || '';
}
export const getClientContactById = (clientId, contactId, state, contacts) => {
  const _contacts = contacts ? contacts : getClientContacts(state);
  return _contacts && _contacts[clientId] ? _contacts[clientId][contactId] : '';
}
export const getBrandById = (brandId, state, brands) => {
  const _brands = brands ? brands : getBrands(state);
  return _brands.find((_) => (brandId === _.id)) || '';
}
export const getClientContactsAsArray = (clientId, state) => {
  let contacts = getClientContacts(state);
  if (Array.isArray(clientId) && Boolean(contacts)) {
    let res = {};
    clientId.forEach((id) => {
      res = { ...res, ...(contacts[id] || {}) }
    })
    contacts = res;
  } else {
    contacts = contacts && contacts[clientId];
  }
  return Object.values(contacts || {})
}
export const isClientMiscLoaded = (state) => {
  const clients = getClients(state);
  const brands = getBrands(state);
  const contacts = getClientContacts(state);
  return Boolean(clients) && Boolean(brands) && Boolean(contacts);
}
export const isMiscLoaded = (state) => {
  const ratecards = getRatecards(state);
  return isClientMiscLoaded(state) && Boolean(ratecards);
}
export const getAllClientContactsAsList = (state) => {
  const contacts = getClientContacts(state);
  let result = []
  Object.values(contacts || {}).forEach((_) => {
    result = result.concat(Object.values(_))
  })
  return result
}
