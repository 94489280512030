import React from "react";
import { Button } from "../Button";
import { FormUI } from "../Input";
import { Modal as AntModal } from 'antd';
import "./style.scss";

const Actions = [
  { label: 'Cancel', id: 'cancel', labelClassName: 'f16 bold', className: 'bgE0E0E0', type: 'button' },
  { label: 'Save', id: 'save', labelClassName: 'f16 bold', className: 'bg3B92E0', type: 'button' },
]

export const Modal = (props) => {
  const { open, onClose, title, className, ...rest } = props;
  return (
    <AntModal
      className={`form-dialog ${className || ''}`}
      open={open}
      closable={false}
      onCancel={onClose}
      footer={null}
      title={rest.title || null}
      {...rest}>
      {props.children}
    </AntModal>
  )
}
export const FormModal = React.forwardRef((props, ref) => {
  const { title, open, onSubmit, onClose, formClassName, beforeContent, afterContent, className, actions, width, ...rest } = props;
  const getActions = () => {
    const findActionsFromForm = (defaultAction) => {
      let res = { ...defaultAction };
      if (Array.isArray(actions) && actions.length > 0) {
        let defined = actions.find((_) => res.id === _.id);
        if (defined) {
          res = { ...res, ...defined }
        }
      }
      return res;
    }
    return Actions.map((_, index) => {
      return {
        ...findActionsFromForm(_),
        onClick: index === 0 ? handleClose : onSubmit
      }
    })
  }
  const handleClose = () => {
    if (ref && ref.current && ref.current.resetErrors) {
      ref.current.resetErrors()
    }
    onClose && onClose()
  }
  return (
    <Modal onClose={handleClose} title={null} open={open} className={`form-dialog ${className || ''}`} width={width}>
      <div className="col w-100">
        <div className='row h-btn title-sec'>
          <span className='f36 bold c00085 line-12' dangerouslySetInnerHTML={{ __html: title }}></span>
          <div className='row dialog-actions'>
            {
              getActions().map((btn, i) => {
                return (
                  <Button {...btn} key={i} />
                )
              })
            }
          </div>
        </div>
        <div className={`col container oy-auto`}>
          {beforeContent}
          {
            open &&
            <FormUI
              ref={ref}
              show={open}
              Fields={rest.fields}
              formData={rest.formData}
              onChange={rest.onChange}
              onSubmit={onSubmit}
              getOptions={rest.getOptions}
              className={`row h-btn fwarp ${formClassName || ''}`}
              direction={props.direction || 'vertical'}
            />
          }
          {afterContent}
        </div>
      </div>
    </Modal>
  )
});