import React from 'react';
import { useDispatch } from "react-redux";
import { FormModal } from "../../components";
import { getUserRoles, userActions, AppActions } from "../../store";
import { AuthService } from "../../services";
import Fields from "./fields.json";

const getFormInitialData = (user) => {
  let res = {}
  if (Boolean(user)) {
    res = Object.assign({}, user);
    res.role = { id: user.role, label: user.role }
  }
  return res;
}
export const UserCEDialog = (props) => {
  const { user, open } = props;
  const dispatch = useDispatch();
  const isEdit = Boolean(user);
  const _ref = React.useRef(null)
  const [state, setState] = React.useState({ user: getFormInitialData(user) })
  React.useEffect(() => {
    let formData = getFormInitialData(user)
    setState((_) => ({ ..._, user: formData }))
  }, [open])
  const getOptions = () => {
    return getUserRoles()
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    const user = { ...state.user }
    user[name] = value;
    setState((_) => ({ ..._, user }))
  }
  const handleSaveUser = (e) => {
    if (_ref.current && _ref.current.validate) {
      let res = _ref.current.validate(e, true);
      if (!res.valid) {
        return
      }
      if (isEdit) {
        dispatch(userActions.updateUser({ body: res.formJson, userId: user.userId }));
      } else {
        dispatch(userActions.createUser(res.formJson));
      }
      props.onClose && props.onClose()
    }
  }
  const getFields = () => {
    let fields = Fields.UserNew;
    if (isEdit) {
      fields = Fields.UserNew.map((_) => {
        return { ..._, disabled: _.attribute === 'email' }
      });
      if (user.setting) {
        fields = fields.filter(({ attribute }) => attribute !== 'role')
      }
    }
    return fields
  }
  const getTitle = () => {
    if (isEdit) {
      return `Edit ${user.setting ? 'Details' : 'User'}`
    }
    return 'New User'
  }
  return (
    <FormModal
      ref={_ref}
      open={open}
      formData={state.user}
      onChange={handleChange}
      onSubmit={handleSaveUser}
      getOptions={getOptions}
      onClose={props.onClose}
      fields={getFields()}
      className="user-crud"
      title={getTitle()} >
    </FormModal>
  )
}
export const UserDisable = (props) => {
  const { user, open } = props;
  const dispatch = useDispatch();
  const afterContent = open && (
    <div className="col c333">
      <div className="f20 reg">You are trying to delete <span className='bold'>{user.userName}</span></div>
      <ul className='points'>
        <li className='f20 reg'>The user will no longer be able to login to the Icarus Project Managment Software</li>
        <li className='f20 reg'>The user cannot be assigned to manage new projects</li>
        <li className='f20 reg'>Existing projects to which they are assigned will remain, but will have to be reassigned manually.</li>
      </ul>
      <div className='f20 bold-700'>Are you sure? This action cannot be undone.</div>
    </div>
  )
  const handleDisable = (e) => {
    dispatch(userActions.disableUser({ userId: user.userId }));
    props.onClose && props.onClose()
  }
  return (
    <FormModal
      open={open}
      onClose={props.onClose}
      fields={[]}
      actions={[
        { id: 'save', label: 'Disable', className: 'bgF2665E' }
      ]}
      onSubmit={handleDisable}
      afterContent={afterContent}
      className='user-disable-modal'
      title="Disable User" />
  )
}
export const ChangePassword = (props) => {
  const { open } = props;
  const dispatch = useDispatch();
  const _ref = React.useRef(null)
  const [formData, setFormData] = React.useState({})
  React.useEffect(() => {
    if (!open) {
      setFormData({})
    }
  }, [open])
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value })
  }
  const handleSaveUser = async (e) => {
    if (_ref.current && _ref.current.validate) {
      let res = _ref.current.validate(e, true);
      if (!res.valid) {
        return
      }
      const { oldPassword, newPassword } = res.formJson;
      AuthService.reauthLoggedInUser(oldPassword)
        .then(() => {
          dispatch(userActions.changePassword(newPassword))
          props.onClose && props.onClose()
        }).catch((e) => {
          if (e.code === 'auth/wrong-password') {
            dispatch(AppActions.setAlert({ success: false, message: 'Old password is wrong' }))
          }
        })
    }
  }
  return (
    <FormModal
      ref={_ref}
      open={open}
      formData={formData}
      onChange={handleChange}
      onSubmit={handleSaveUser}
      onClose={props.onClose}
      fields={Fields.ChangePassword}
      width='40vw'
      title="Change Password" />
  )
}
export const ForgetPassword = (props) => {
  const { open } = props;
  const _ref = React.useRef(null)
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState(null);
  React.useEffect(() => {
    if (!open) {
      setEmail('')
    }
  }, [open])
  const handleChange = (e) => {
    setEmail(e.target.value);
  }
  const showMessage = (success, message) => {
    setMessage({ success, message });
    let timer = setTimeout(() => {
      clearTimeout(timer);
      setMessage(null);
      success && props.onClose && props.onClose()
    }, 2500)
  }
  const handleSendEmail = (e) => {
    if (_ref.current && _ref.current.validate) {
      let res = _ref.current.validate(e, true);
      if (!res.valid) {
        return
      }
      AuthService.sendPasswordResetEmail(email)
        .then(() => {
          showMessage(true, "Password reset email sent successfully.")
        })
        .catch((e) => {
          if (e.code == 'auth/user-not-found') {
            showMessage(false, "Invalid Email address");
          }
        })
    }
  }
  const afterContent = (
    <div className='reg f18 line-12 c4F4F4F' style={{ marginTop: 16 }}>
      You will recieve a link to reset your password.
      <div className='col v-ctr h-ctr message'>
        {
          Boolean(message) &&
          <span className={`f14 blinking ${message.success ? 'c219653' : 'cF2665E'}`}>{message.message}</span>
        }
      </div>
    </div>
  )
  return (
    <FormModal
      ref={_ref}
      open={open}
      formData={{ email }}
      onChange={handleChange}
      onSubmit={handleSendEmail}
      onClose={props.onClose}
      fields={Fields.ForgotPassword}
      width='50vw'
      actions={[
        { label: 'Send Email', id: 'save', className: 'bg27AE60' },
      ]}
      className='forget-user-modal'
      afterContent={afterContent}
      title="Forgot Password" />
  )
}