import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../app";

const initialState = {
  ratecards: null,
  clients: null,
  brands: null
}

const miscSlice = createSlice({
  name: "misc",
  initialState: initialState,
  reducers: {
    fetchRatecards: () => { },
    createRatecard: () => { },
    updateRatecard: () => { },
    fetchClientsMisc: () => { },
    createBrand: () => { },
    updateBrand: () => { },
    createContact: () => { },
    updateContact: () => { },
    setRatecards: (state, action) => {
      state.ratecards = action.payload
    },
    setClients: (state, action) => {
      state.clients = action.payload
    },
    setClientContacts: (state, action) => {
      state.clientContacts = action.payload
    },
    setBrands: (state, action) => {
      state.brands = action.payload
    },
    clear: () => {
      return { ...initialState }
    }
  }
})
export const miscActions = miscSlice.actions;

ReducerRegistry.register("misc", miscSlice.reducer)