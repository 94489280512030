import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from "react-redux";
import { FormModal, IconButton } from "../../components";
import { DateService } from "../../services";
import FormFields from "./fields.json"
import {
  projectActions, getClients, getExecutives, getBrandsByClient, getClientContactsAsArray,
  getProjectStatusList, getImageFileToBase64, getImageDimension, getProjectDocSetNotes, isProjectStopped
} from "../../store";

const getFormInitialData = (project) => {
  let res = { startDate: DateService().toISOString() }
  if (Boolean(project)) {
    res = Object.assign({}, project);
    if (Array.isArray(project.documentSets)) {
      for (let i = 0; i < project.documentSets.length; i++) {
        const set = project.documentSets[i];
        if (set) {
          for (let j = 0; j < FormFields.DocumentSet.children.length - 1; j++) {
            const { attribute } = FormFields.DocumentSet.children[j];
            let file = set[attribute];
            res[`docset-${i}-${attribute}`] = file ? [file] : null;
          }
        }
      }
      delete res.documentSets;
    }
  }
  return res;
}
const getInitialDocSize = (project) => {
  let size = 1;
  if (project && Array.isArray(project.documentSets)) {
    size = project.documentSets.length;
  }
  return size;
}
const EmptyField = () => {
  return (
    <div className='col w-32 h-ctr v-ctr'></div>
  )
}
export const ProjectCEModal = (props) => {
  const { project, open } = props;
  const isEdit = Boolean(project);
  const dispatch = useDispatch();
  const _ref = React.useRef(null)
  const [state, setState] = React.useState({
    project: getFormInitialData(project),
    documentSetsSize: getInitialDocSize(project)
  });
  const users = useSelector(getExecutives.bind(null, true));
  const clients = useSelector(getClients);
  const statusList = getProjectStatusList(isEdit)
  const projectClient = state.project.client && state.project.client.id;
  const brands = useSelector(getBrandsByClient.bind(null, projectClient));
  const clientContacts = useSelector(getClientContactsAsArray.bind(null, projectClient));
  React.useEffect(() => {
    let formData = getFormInitialData(project),
      size = getInitialDocSize(project)
    setState((_) => ({ ..._, project: formData, documentSetsSize: size }))
  }, [open])
  const DocSetLabel = () => {
    return <div className='f14 c333 w-100 doc-set-label bold'>Documents (PDF / DOC / JPG / PNG)</div>
  }
  const DocSetAddBtn = () => {
    return (
      <div className='col w-32 h-ctr v-ctr add-set'>
        <IconButton
          icon={<PlusOutlined style={{ color: '#FFF', fontSize: 14 }} />}
          className='bg828282'
          shape="circle"
          onClick={() => {
            setState((_) => ({ ..._, documentSetsSize: _.documentSetsSize + 1 }))
          }}
        />
        <div className='f12 bold c828282 label'>Add revised set</div>
      </div>
    )
  }
  const getOldNotes = (setNo) => {
    let notes, history = [];;
    if (project && project.documentSets && project.documentSets[setNo]) {
      notes = project.documentSets[setNo].note;
    }
    history = getProjectDocSetNotes(notes);
    return (history.length > 0 ?
      <div className='col notes-history-sec'>
        <span className='bold f14 c333 label'>Old Notes</span>
        <div className='col w-100'>
          {
            history.map((_) => {
              return <span className='f10 c777 entry'>{_}</span>
            })
          }
        </div>
      </div>
      : null
    )
  }
  const getFields = () => {
    if (!open) return [];
    let fields = [];
    FormFields.NewProject.forEach((_, i) => {
      let field = { ..._ };
      if (field.custom) {
        field.Component = DocSetLabel
      }
      if (
        _.attribute === 'statusReason' &&
        (!isEdit ||
          (isEdit && _.attribute === 'statusReason' && state.project.status && statusList[4] &&
            state.project.status.id !== statusList[4].id))
      ) {
        return;
      }
      fields.push(field);
    })

    fields.push({ "custom": "documentSetLabel", Component: DocSetLabel })
    for (let j = 0; j < FormFields.DocumentSet.children.length; j++) {
      for (let i = 0; i < 3; i++) {
        let field = JSON.parse(JSON.stringify(FormFields.DocumentSet.children[j]));
        if (i < state.documentSetsSize) {
          const attr = field.attribute;
          field.className = 'w-32';
          field.label += ` ${i + 1}`;
          if (isEdit) {
            let fieldVal = project.documentSets && project.documentSets[i] && project.documentSets[i][field.attribute];
            if (attr === 'note') {
              field.afterContent = getOldNotes(i)
            } else if (fieldVal) {
              field.btnLabel = 'Update';
              field.btnClassName = ' update-file';
            }
          }
          field.attribute = `docset-${i}-${field.attribute}`;
        } else {
          field = { custom: true, Component: EmptyField, attribute: field.attribute }
          if (state.documentSetsSize === i && field.attribute === 'brief') {
            field.Component = DocSetAddBtn
          }
        }
        fields.push(field);
      }
    }

    return fields;
  }
  const getTitle = () => {
    let title = 'New Project';
    if (isEdit) {
      title = `Edit Project <span class='f14 reg'>Project Code: <span class='bold'>${project.projectId}</span></span>`
    }
    return title;
  }
  const getOptions = (attribute) => {
    let options;
    switch (attribute) {
      case "status": options = statusList; break;
      case "icarusContact": options = users; break;
      case "client": options = clients; break;
      case "clientBrand": options = brands; break;
      case "clientContact": options = clientContacts; break;
      default: options = [];
    }
    return options;
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    const project = { ...state.project };
    project[name] = value;
    setState((_) => ({ ..._, project }));
  }
  const getDocumentSetBody = async (body) => {
    let docSetFile = [], docJsonSet = [];
    for (const key in body) {
      if (Object.hasOwnProperty.call(body, key) && key.indexOf("docset") === 0) {
        let val = body[key], brokenKey = key.split('-'), setNo = Number(brokenKey[1]),
          setKey = brokenKey[2], fileValue = null, jsonVal = undefined, layout = null;
        val = Array.isArray(val) && val.length > 0 ? val[0] : val;
        if (val instanceof File) {
          jsonVal = val.name;
          const buffer = await getImageFileToBase64(val);
          if (val.type == 'image/png' || val.type == 'image/jpeg') {
            layout = await getImageDimension(val).catch(() => {
              layout = null
            });
          }
          fileValue = {
            buffer: buffer, layout: layout, type: val.type,
            name: val.name, lastModified: new Date(val.lastModified).toISOString()
          }
          if (!docSetFile[setNo]) docSetFile[setNo] = {};
          docSetFile[setNo][setKey] = fileValue;
        } else {
          jsonVal = setKey === "note" ? val : null;
        }
        if (jsonVal !== undefined) {
          if (!docJsonSet[setNo]) {
            docJsonSet[setNo] = {}
          }
          docJsonSet[setNo][setKey] = jsonVal;
        }
        delete body[key];
      }
    };
    body.documentSets = docJsonSet;
    return docSetFile;
  }
  const handleSaveProject = async (e) => {
    if (_ref.current && _ref.current.validate) {
      let res = _ref.current.validate(e, true);
      if (!res.valid) {
        return
      }
      let projectData = res.formJson
      const documentSetsFiles = await getDocumentSetBody(projectData)
      const body = { project: projectData, documentSetsFiles }
      if (isEdit) {
        dispatch(projectActions.updateProject({ body, projectId: project.projectId }));
      } else {
        dispatch(projectActions.createProject(body));
      }
      props.onClose && props.onClose()
    }
  }

  const afterContent = open && state.documentSetsSize == 3 && (
    <div className='f14 line-12 c828282 italic reg'>
      You cannot add any further revisions to this project. Edit an existing document or create a new project.
    </div>
  )
  return (
    <FormModal
      width='60vw'
      ref={_ref}
      open={open}
      formClassName="v-start"
      className='project-new'
      fields={getFields()}
      formData={state.project}
      getOptions={getOptions}
      onChange={handleChange}
      onSubmit={handleSaveProject}
      onClose={props.onClose}
      afterContent={afterContent}
      title={getTitle()} />
  )
}
export const ProjectInvoiceModal = (props) => {
  const { project, invoiceType, open } = props;
  const [invoice, setInvoice] = React.useState(null)
  const dispatch = useDispatch();
  const _ref = React.useRef(null);
  React.useEffect(() => {
    if (!open) {
      setInvoice(null)
    }
  }, [open])
  const handleSaveInvoice = async (e) => {
    if (_ref.current && _ref.current.validate) {
      let res = _ref.current.validate(e, true);
      if (!res.valid) return;
      let fileContent = invoice[0];
      const body = { type: fileContent.type }
      body.invoiceType = Number.isNaN(invoiceType) ? 'part' : invoiceType
      body.fileContent = await getImageFileToBase64(fileContent);
      dispatch(projectActions.uploadProjectInvoices({ projectId: project.projectId, body }))
      props.onClose && props.onClose();
    }
  }
  const getFields = () => {
    if (!open) return [];
    let label = Number.isNaN(invoiceType) ? invoiceType : ('Part ' + invoiceType)
    let fields = FormFields.Invoice.map((_) => ({ ..._ }));
    fields[0].label = `Design Fee (${label})`
    return fields
  }
  const beforeContent = open && (
    <div className='row v-ctr h-ctr w-100'>
      <span className='bold f20'>Project Id {project.projectId} - {project.projectName}</span>
    </div>
  )
  return (
    <FormModal
      width='60vw'
      ref={_ref}
      open={open}
      formClassName="v-start"
      className='project-invoice-add'
      fields={getFields()}
      formData={{ invoice }}
      onChange={(e) => setInvoice(e.target.value)}
      onSubmit={handleSaveInvoice}
      onClose={props.onClose}
      beforeContent={beforeContent}
      title="Add Project Invoice" />
  )
}