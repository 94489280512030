import React from 'react';
import { Modal } from 'antd';
import { saveAs } from "file-saver";
import { getBinaryContent } from "jszip-utils";
import { ArrowLeftOutlined, DownloadOutlined } from '@ant-design/icons';
import "./style.scss";

export const UploadViewer = (props) => {
  const { fileDetails, open } = props
  const handleCancel = () => {
    props.onClose && props.onClose()
  }
  const handleGoogleDocViewURL = () => {
    // https://docs.google.com/a/[DOMINIO]/viewer?url=[FILE_URL] 
    let domain = 'firebasestorage.googleapis'
    let url = `https://docs.google.com/a/${domain}/viewer?url=${fileDetails.url}&embedded=true`;
    return `${fileDetails.url}#toolbar=0&scrollbar=0&navpanes=1`
  }
  const handleDownload = () => {
    getBinaryContent(fileDetails.url, (err, data) => {
      if (!err && data) {
        let blob = new Blob([data], {
          type: fileDetails.type
        })
        saveAs(blob, fileDetails.fileName)
      }
    });
  }
  return (
    <Modal open={open} onCancel={handleCancel} className='file-view-modal col' footer={null}>
      {
        Boolean(open) &&
        <React.Fragment>
          <div className="row w-100 v-ctr h-btn header">
            <div className="row">
              <div onClick={handleCancel} className='pointer'>
                <ArrowLeftOutlined style={{ color: '#FFF', fontSize: 18 }} />
              </div>
              <span className="f22 cFFF file-name">{fileDetails.fileName}</span>
            </div>
            <div className="row">
              <span className="f22 cFFF">{fileDetails.projectName} - <span className='caps'>{fileDetails.documentType}</span></span>
            </div>
            <div className='row' onClick={handleDownload}>
              <DownloadOutlined style={{ color: '#FFF', fontSize: 28 }} className='pointer download' />
            </div>
          </div>
          <div className={`col file-view`}>
            {
              Boolean((fileDetails.type == 'image/png' || fileDetails.type == 'image/jpeg' || fileDetails.type == 'application/pdf')) ?
                <div className={`fview ${fileDetails.type == 'application/pdf' ? 'o-hide' : ''}`} >
                  {
                    Boolean((fileDetails.type == 'image/png' || fileDetails.type == 'image/jpeg')) ?
                      <img src={fileDetails.url} />
                      :
                      <iframe src={handleGoogleDocViewURL()} frameborder="0"></iframe>
                  }
                </div>
                :
                <div className='col v-ctr h-ctr'>
                  Only Images, PDF's are viewable, for doc please download the file
                </div>
            }
          </div>
        </React.Fragment>
      }
    </Modal>
  )
}