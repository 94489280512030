import React from 'react';
import { useSelector } from "react-redux";
import { getAllUsers } from "../../store";
import { EditFilled } from '@ant-design/icons';
import { usePub, DataGridView, Button } from "../../components";
import { UserDisable } from "./Dialogs";
import { DateService } from "../../services";

const Columns = [
  { title: 'User ID', dataIndex: 'slNo', key: 'slNo', width: '10.5%', defaultSortOrder: 'descend', sort: true  },
  { title: 'Name', dataIndex: 'userName', key: 'userName', width: '22%', sort: true },
  { title: 'Email address', dataIndex: 'email', key: 'email', width: '21%', sort: true },
  { title: 'Phone number', dataIndex: 'phone', key: 'phone', width: '16%', sort: true },
  { title: 'Role', dataIndex: 'role', key: 'role', width: '11%', sort: true },
  { title: '', dataIndex: 'edit', key: 'edit', width: '6%', render: 'actions', cellClassName: 'v-ctr h-ctr' },
  { title: '', dataIndex: 'disable', key: 'disable', width: '13.5%', render: 'actions', cellClassName: 'v-ctr h-ctr' }
]

export const UserListScreen = () => {
  const publish = usePub();
  const users = useSelector(getAllUsers);
  const [state, setState] = React.useState({ selected: null })
  const handleEditUser = (user) => {
    publish("USER_DIALOG", user)
  }
  const handleDisableUser = (user) => {
    setState((_) => ({ ..._, selected: user }))
  }
  const getRowActions = (dataIndex, user) => {
    let actions = [];
    if (dataIndex === 'disable') {
      let action = {}
      if (user.disabled) {
        action.Component = () => (
          <span className='f14 c333'>Disabled on {DateService(user.disabledOn).format("DD/MM/YYYY")}</span>
        );
      } else {
        action.Component = Button;
        action.onClick = handleDisableUser.bind(null, user)
        action = {
          ...action, normal: true, className: 'no-shadow bgTrans w-auto',
          label: 'Disable', labelClassName: 'cF2665E f14 med'
        }
      }
      actions.push(action);
    }
    if (dataIndex === 'edit') {
      actions.push({
        disabled: user.disabled,
        Component: EditFilled, onClick: handleEditUser.bind(null, user),
        style: { fontSize: 18, color: '#bdbdbd' }
      })
    }
    return actions
  }
  const getColumns = () => {
    return Columns.map((_) => {
      const col = { ..._ };
      if (col.render === 'actions') {
        col.actions = getRowActions.bind(null, col.dataIndex)
      }
      return col
    })
  }
  const getRows = () => {
    return users.map((_, i) => {
      return { ..._, slNo: i + 1 }
    })
  }
  return (
    <div className='col w-100 h-100 screen-pad o-hide bgE5E5E5'>
      <DataGridView
        className='user-table'
        rows={getRows()}
        emptyMessage='No users'
        Columns={getColumns()}
        rowClassName={(user) => {
          return user.disabled ? 'row-disable' : ''
        }}
      />
      <UserDisable
        user={state.selected}
        open={Boolean(state.selected)}
        onClose={handleDisableUser.bind(null, null)} />
    </div>
  )
}

export * from "./Dialogs";
export * from "./Settings";
export * from "./LogIn";