import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { isProjectStopped, isProjectCompleted, taskActions, getClients, getExecutives, getRatecards, getAllProjects, getBrandsByClient, getClientContactsAsArray } from "../../store";
import { FormModal } from "../../components";
import { DateService } from "../../services";
import FormFields from "./fields.json";

const getFormInitialData = (task) => {
  let res = { isIndependent: false }
  if (Boolean(task)) {
    res = Object.assign({}, task);
  }
  return res;
}
export const NewTaskModal = (props) => {
  const { task, open } = props;
  const isEdit = Boolean(task);
  const dispatch = useDispatch();
  const _ref = React.useRef(null)
  const [state, setState] = React.useState({ task: getFormInitialData(task) });
  const rateCardItems = useSelector(getRatecards);
  const projects = useSelector(getAllProjects);
  const users = useSelector(getExecutives.bind(null, true));
  const clients = useSelector(getClients);
  const taskClient = state.task.client && state.task.client.id;
  const brands = useSelector(getBrandsByClient.bind(null, taskClient));
  const clientContacts = useSelector(getClientContactsAsArray.bind(null, taskClient));
  React.useEffect(() => {
    let formData = getFormInitialData(task)
    setState((_) => ({ ..._, task: formData }))
  }, [open])
  const getFields = () => {
    let fields = FormFields.TaskCommon.concat(state.task.isIndependent ? FormFields.TaskIndependent : FormFields.TaskProject);
    fields = fields.map((_) => {
      const field = {..._};
      if (_.attribute === "requestDate") {
        field.minDate = DateService().subtract(2, 'day').toDate()
        field.maxDate = DateService().toDate()
      }
      if (state.task.requestDate && (_.attribute === "clientDeliveryDate" || _.attribute === "estDeliveryDate")) {
        field.minDate = state.task.requestDate;
      }
      return field;
    })
    return fields;
  }
  const getTitle = () => {
    let title = 'New Task';
    if (isEdit) {
      title = `Edit Task <span class='f14 reg'>Task Id: <span class='bold'>${task.taskId}</span></span>`
    }
    return title;
  }
  const getOptions = (attribute) => {
    let options;
    switch (attribute) {
      case "taskItemId": options = rateCardItems; break;
      case "icarusContact": options = users; break;
      case "client": options = clients; break;
      case "clientBrand": options = brands; break;
      case "clientContact": options = clientContacts; break;
      case "projectId":
        options = Object.values(projects || {}).filter((proj) => {
          return !(isProjectStopped(proj) || isProjectCompleted(proj))
        })
        break;
      default: options = [];
    }
    return options;
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    const task = { ...state.task };
    task[name] = value;
    if (name === 'requestDate' && value) {
      let requestDate = DateService(value).startOf("day").toDate().getTime();
      if (task.clientDeliveryDate) {
        let clientDeliveryDate = DateService(task.clientDeliveryDate).startOf("day").toDate().getTime();
        if (requestDate > clientDeliveryDate) {
          task.clientDeliveryDate = null
        }
      }
      if (task.estDeliveryDate) {
        let estDeliveryDate = DateService(task.estDeliveryDate).startOf("day").toDate().getTime();
        if (requestDate > estDeliveryDate) {
          task.estDeliveryDate = null
        }
      }
    }
    setState((_) => ({ ..._, task }));
  }
  const handleSaveTask = (e) => {
    if (_ref.current && _ref.current.validate) {
      let res = _ref.current.validate(e, true);
      if (!res.valid) {
        return
      }
      if (isEdit) {
        res.formJson.taskId = task.taskId;
        dispatch(taskActions.updateTask(res.formJson));
      } else {
        dispatch(taskActions.createTask(res.formJson));
      }
      props.onClose && props.onClose()
    }
  }
  return (
    <FormModal
      ref={_ref}
      open={open}
      fields={getFields()}
      formData={state.task}
      getOptions={getOptions}
      onChange={handleChange}
      onSubmit={handleSaveTask}
      onClose={props.onClose}
      title={getTitle()} >
    </FormModal>
  )
}