import React, { useEffect, useState } from 'react';
import { FormModal } from "../../components";
import { useDispatch } from "react-redux";
import { miscActions, getRateCardTypes, getRateCardUnits, getRateCardsAllowedInTasks } from "../../store";


const Fields = [
  { label: 'Rate card Title', attribute: 'title', placeholder: 'Title', required: true },
  { label: 'Type', attribute: 'type', placeholder: 'Type', type: 'autocomplete', required: true, className: "w-49" },
  { label: 'Unit', attribute: 'unit', placeholder: 'Unit', type: 'autocomplete', required: true, className: "w-49" },
  { label: 'Allowed In Task Creation', attribute: 'allowedInTask', type: 'autocomplete', placeholder: 'Select', required: true, className: "w-49" },
  { label: 'Initial Value', attribute: 'initial', placeholder: 'Initial Value', className: "w-49", regex: '/^(\d+(\.\d*)?|\.\d+)$/' },
  { label: 'Details Placeholder', attribute: 'detailsHint', placeholder: 'Placeholder', required: true },
  { label: 'Ratecard Below Hint', attribute: 'belowHint', placeholder: 'Hint', type: 'textarea', resize: false },
  { label: 'Ratecard Owner Hint', attribute: 'ownerHint', placeholder: 'Hint', type: 'textarea', resize: false },
]
export const RateCardDialog = (props) => {
  const dispatch = useDispatch()
  const _ref = React.useRef(null)
  const { ratecard, onClose, open } = props;
  const isEdit = Boolean(ratecard);
  const [state, setState] = useState({ ratecard: {}, show: false });
  const ratecardTypes = getRateCardTypes()
  const ratecardUnits = getRateCardUnits()
  const ratecardTaskItems = getRateCardsAllowedInTasks()
  useEffect(() => {
    let formData = {};
    if (open && isEdit) {
      Fields.forEach(({ attribute }) => {
        let value = ratecard[attribute];
        switch (attribute) {
          case "type":
            value = ratecardTypes.find((_) => _.value === value);
            break;
          case "unit":
            value = ratecardUnits.find((_) => _.value === value);
            break;
          case "allowedInTask":
            value = ratecardTaskItems.find((_) => _.id === value);
            break;
        }
        formData[attribute] = value;
      })
    }
    setState((_) => ({ ..._, ratecard: formData, show: open }))
  }, [open])
  const getOptions = (attribute) => {
    switch (attribute) {
      case 'type': return ratecardTypes;
      case 'unit': return ratecardUnits
      case 'allowedInTask': return ratecardTaskItems;
      default: return [];
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    const ratecard = { ...state.ratecard };
    ratecard[name] = value;
    setState((_) => ({ ..._, ratecard: ratecard }))
  }
  const handleSave = (e) => {
    if (_ref.current && _ref.current.validate) {
      let res = _ref.current.validate(e, true);
      if (!res.valid) {
        return
      }
      const body = res.formJson
      body.initial = Number(body.initial);
      body.taskItem = body.allowedInTask === 'Yes';
      delete body.allowedInTask;
      if (isEdit) {
        dispatch(miscActions.updateRatecard({ ratecard: body, id: ratecard.rcId }))
      } else {
        dispatch(miscActions.createRatecard({ ratecard: body }))
      }
      onClose && onClose()
    }
  }
  return (
    <FormModal
      ref={_ref}
      open={open}
      fields={Fields}
      getOptions={getOptions}
      onChange={handleChange}
      formData={state.ratecard}
      onClose={onClose}
      onSubmit={handleSave}
      title={isEdit ? 'Edit Ratecard' : 'Create New Ratecard'}
    />
  )
}