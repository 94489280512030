import "./saga";
import { isTaskDelayed } from "./helper";
import { DateService } from "../../services";
export * from "./slice";
export * from "./helper";

const getTaskState = (state) => state.TASK;

export const getAllTasks = (state) => getTaskState(state).tasks;

export const getTaskFilterModel = (state) => {
  return getTaskState(state).filterModel;
}

export const getAllTasksAsList = (state) => {
  const tasks = getAllTasks(state);
  return tasks ? Object.values(tasks) : null;
}

export const getTaskById = (taskId, state) => {
  const tasks = getAllTasks(state)
  return tasks ? tasks[taskId] : null
}

export const getTasksByClientBrand = (client, brand, state) => {
  let tasks = getAllTasksAsList(state);
  if (Array.isArray(tasks) && client && brand) {
    tasks = tasks.filter((_) => {
      return _.isIndependent && _.client === client && (brand ? _.clientBrand === brand : true)
    })
  }
  return tasks
}

export const getFilteredTasks = (currentUser, state) => {
  let tasks = getAllTasksAsList(state);
  const filter = getTaskFilterModel(state);
  if (Array.isArray(tasks) && Object.keys(filter).length > 0) {
    const today = new Date().setHours(0, 0, 0, 0);
    let { type, clients, clientContacts, clientBrands, status, clientRequestDate, icarusContact, designer, estDeliveryDate } = filter;
    tasks = tasks.filter((task) => {
      let inStatus = true;
      let inClient = Array.isArray(clients) && clients.length > 0 ? clients.includes(task.client.id) : true;
      let inExecutive = Array.isArray(icarusContact) && icarusContact.length > 0 ? icarusContact.includes(task.icarusContact.id) : true;
      let inClientContact = Array.isArray(clientContacts) && clientContacts.length > 0 ? clientContacts.includes(task.clientContact.id) : true;
      let inClientBrand = Array.isArray(clientBrands) && clientBrands.length > 0 ? clientBrands.includes(task.clientBrand.id) : true;
      let inType = !type ? true : (type.id === 'independent' && task.isIndependent) || (type.id === 'nonIndepedent' && !task.isIndependent);
      let inDesigner = Array.isArray(designer) && designer.length > 0 ? designer.includes((task.assigns && task.assigns.current && task.assigns.current.assignedTo) || '') !== -1 : true;
      let inReqDate = clientRequestDate ? (DateService(clientRequestDate).isSame(task.requestDate) || DateService(clientRequestDate).isAfter(task.requestDate)) : true;
      let inEstDate = estDeliveryDate ? (DateService(estDeliveryDate).isSame(task.requestDate) || DateService(estDeliveryDate).isAfter(task.estDeliveryDate)) : true;
      switch (status) {
        case "closed":
        case "billing":
        case "deleted": inStatus = task[status]; break;
        case "delayed":
        case "opened": inStatus = String(task.status).toLowerCase() === status; break;
        default: inStatus = true; break;
      }
      return inType && inClient && inClientContact && inClientBrand && inExecutive && inDesigner && inStatus && inReqDate && inEstDate;
    })
  }
  return tasks;
}
