import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { FormModal } from "../../components";
import { getClients, miscActions } from "../../store";
import Fields from "./fields.json";

const getBrandInitialData = (brand) => {
  let res = {}
  if (Boolean(brand)) {
    res = {
      brandTitle: brand.title,
      clientId: { ...brand.client, label: brand.client.name }
    }
  }
  return res;
}
const getContactInitialData = (contact) => {
  let res = {}
  if (Boolean(contact)) {
    res = Object.assign({}, contact);
    res.client = { ...res.client, label: contact.client.name };
  }
  return res;
}
export const ClientBrandDialog = (props) => {
  const { brand, open } = props;
  const dispatch = useDispatch();
  const isEdit = Boolean(brand);
  const _ref = React.useRef(null)
  const clients = useSelector(getClients)
  const [state, setState] = React.useState({ brand: getBrandInitialData(open && brand) })
  React.useEffect(() => {
    let formData = getBrandInitialData(open && brand);
    setState((_) => ({ ..._, brand: formData }))
  }, [open])
  const getOptions = () => {
    return clients
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    const brand = { ...state.brand }
    brand[name] = value;
    setState((_) => ({ ..._, brand }))
  }
  const handleSaveBrand = (e) => {
    if (_ref.current && _ref.current.validate) {
      let res = _ref.current.validate(e, true);
      if (!res.valid) {
        return
      }
      if (isEdit) {
        dispatch(miscActions.updateBrand({ brand: res.formJson, brandId: brand.id }));
      } else {
        dispatch(miscActions.createBrand(res.formJson));
      }
      props.onClose && props.onClose()
    }
  }
  const getFields = () => {
    if (isEdit) {
      return Fields.BrandNew.map((_) => {
        return { ..._, disabled: _.attribute === 'clientId' }
      })
    }
    return Fields.BrandNew
  }
  return (
    <FormModal
      ref={_ref}
      open={open}
      width="50vw"
      formData={state.brand}
      onChange={handleChange}
      onSubmit={handleSaveBrand}
      getOptions={getOptions}
      onClose={props.onClose}
      fields={getFields()}
      title={`${isEdit ? 'Edit' : 'New'} Client Brand`} />
  )
}
export const ClientContactDialog = (props) => {
  const { contact, open } = props;
  const dispatch = useDispatch();
  const isEdit = Boolean(contact);
  const _ref = React.useRef(null)
  const clients = useSelector(getClients)
  const [state, setState] = React.useState({ contact: getContactInitialData(open && contact) })
  React.useEffect(() => {
    let formData = getContactInitialData(open && contact)
    setState((_) => ({ ..._, contact: formData }))
  }, [open])
  const getOptions = () => {
    return clients
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    const contact = { ...state.contact }
    contact[name] = value;
    setState((_) => ({ ..._, contact }))
  }
  const handleSaveContact = (e) => {
    if (_ref.current && _ref.current.validate) {
      let res = _ref.current.validate(e, true);
      if (!res.valid) {
        return
      }
      let { client, ...rest } = res.formJson;
      if (isEdit) {
        dispatch(miscActions.updateContact({ clientId: client, contactId: contact.id, contact: rest }));
      } else {
        let index = clients.findIndex((_) => (client === _.id));
        if (index > -1) {
          client = { id: client }
        }
        dispatch(miscActions.createContact({ client, contact: rest }));
      }
      props.onClose && props.onClose()
    }
  }
  const getFields = () => {
    if (isEdit) {
      let fields = Fields.ContactNew.map((_) => {
        return { ..._, disabled: _.attribute === 'client' }
      })
      return fields
    }
    return Fields.ContactNew
  }
  return (
    <FormModal
      ref={_ref}
      open={open}
      width="50vw"
      formData={state.contact}
      onChange={handleChange}
      onSubmit={handleSaveContact}
      getOptions={getOptions}
      onClose={props.onClose}
      fields={getFields()}
      title={`${isEdit ? 'Edit' : 'New'} Client Contact`} />
  )
}