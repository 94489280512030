import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, isFinance, projectActions, miscActions, isMiscLoaded, getAllProjectsAsArray, getProjectFilterFromStorage, isProjectCompleted, isProjectStopped, isAdmin } from "../../store";
import { AppErrorBoundry, useSub, DataGridView, usePub } from "../../components";
import { EditFilled } from '@ant-design/icons';
import { ProjectCEModal, ProjectInvoiceModal } from "./Dialogs";
import { ProjectRowExpand } from "./ExpandRow";
import { ProjectFilter } from "./Filter";
import "./project.scss";

const Columns = [
  { title: 'Code', dataIndex: 'projectId', key: 'projectId', width: '7%', defaultSortOrder: 'descend', sort: true },
  { title: 'Start Date', dataIndex: 'startDateStr', key: 'estDeliveryDateStr', width: '13%', sort: true },
  { title: 'Client', dataIndex: 'clientName', key: 'clientName', width: '10%', sort: true },
  { title: 'Brand', dataIndex: 'brandName', key: 'brandName', width: '10%', sort: true },
  { title: 'Project Name', dataIndex: 'projectName', key: 'projectName', width: '15%', sort: true },
  { title: 'Client Contact', dataIndex: 'clientContactName', key: 'clientContactName', width: '14%', sort: true },
  { title: 'EXECUTIVE', dataIndex: 'icarusContactName', key: 'icarusContactName', width: '14%', sort: true },
  { title: 'Status', dataIndex: 'statusLabel', key: 'statusLabel', width: '13%', noborder: true },
  { title: '', dataIndex: 'actions', key: 'actions', width: '3%', render: 'actions', noborder: true },
]
export const ProjectHomeScreen = (props) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(miscActions.fetchRatecards())
    dispatch(projectActions.fetchProjectUnexported());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <React.Fragment>{props.children}</React.Fragment>
  );
}
const getIndicatorClassName = (stats, isStopped) => {
  let className = '';
  className = stats.unbilled === 0 && stats.total > 0 && !isStopped ? 'bg219653' : className; //all billed
  className = stats.total === 0 || isStopped ? 'bgBDBDBD' : className; // none exist
  className = stats.unbilled > 0 && !isStopped ? 'bgF2C94C' : className; //some
  return className
}
const ExecutiveCell = (text, project) => {
  const stats = project.stats.ratecard;
  let className = getIndicatorClassName(stats, project.isStopped)
  return (
    <div className='row h-100 cell h-btn'>
      <span className='f14 reg'>{text}</span>
      <div className={`indicator col h-ctr v-ctr ${className}`}>
        <span className='bold cFFF f12'>
          {stats.unbilled === 0 ? stats.total : (stats.total === -1 ? 0 : stats.unbilled)}
        </span>
      </div>
    </div>
  )
}

const StatusCell = (text, project) => {
  const stats = project.stats.designFee;
  let className = getIndicatorClassName(stats, project.isStopped)
  return (
    <div className='row h-100 cell h-btn'>
      <span className='f14 reg'>{text}</span>
      <div className={`indicator col h-ctr v-ctr ${className}`}>
        <span className='bold cFFF f12'>{stats.unbilled === 0 ? stats.total : (stats.total === -1 ? 0 : stats.unbilled)}</span>
      </div>
    </div>
  )
}

export const ProjectsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const publish = usePub()
  const user = useSelector(getCurrentUser);
  const projects = useSelector(getAllProjectsAsArray); //Get the "selected data" from the selector function
  const isLoaded = useSelector(isMiscLoaded);
  const [state, setState] = useState({ selected: null, showAction: '' })
  useSub('PROJECT_DIALOG', (project) => {
    handleEditProject(project)
  })
  useEffect(() => {
    if (isLoaded) {
      let filterModel = getProjectFilterFromStorage(user.userId);
      if (isFinance(user) && !Boolean(filterModel.projectExport)) {
        filterModel.projectExport = ['unexported'] //default filter
      }
      dispatch(projectActions.setFilterModel(filterModel))
      dispatch(projectActions.fetchAllProjects()); //Dispatch the action 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);
  const handleEditProject = (project, e) => {
    e && e.stopPropagation();
    setState((_) => ({ ..._, selected: project, showAction: "PROJECT_DIALOG" }))
  }
  const getProjectActions = (project) => {
    const actions = [];
    const completed = isProjectCompleted(project) || isProjectStopped(project);
    if (!completed || (completed && isAdmin(user))) {
      actions.push({
        Component: EditFilled, onClick: handleEditProject.bind(null, project),
        style: { fontSize: 18, color: '#bdbdbd' }
      })
    }
    return actions;
  }
  const getColumns = () => {
    const columns = Columns.map((_) => ({ ..._ }));
    columns[6].render = ExecutiveCell;
    columns[7].render = StatusCell;
    columns[8].actions = getProjectActions;
    return columns;
  }
  const handleProjectClick = (project) => {
    navigate(String(project.id))
  }
  const handleViewFile = (file) => {
    publish("VIEW_FILE", file);
  }
  const handleClose = () => {
    setState((_) => ({ ..._, selected: null, showAction: "" }))
  }
  const handleAddInvoice = (project, invoiceType) => {
    setState((_) => ({ ..._, selected: project, showAction: "ADD_INVOICE", invoiceType }))
  }
  const iOptions = { user, onFileView: handleViewFile, onAddInvoice: handleAddInvoice }
  return (
    <AppErrorBoundry>
      <div className='row w-100 h-100 o-hide bgE5E5E5 screen-pad v-start projects'>
        <div className='col h-100 f-rest'>
          <DataGridView
            expandable
            rows={projects}
            emptyMessage='No Projects'
            Columns={getColumns()}
            expandedRowRender={ProjectRowExpand.bind(null, iOptions)}
            rowClassName={() => {
              return 'project-row'
            }}
            onRow={(project) => {
              return { onClick: handleProjectClick.bind(null, project) }
            }}
          />
        </div>
        <ProjectFilter />
      </div>
      <ProjectCEModal
        project={state.selected}
        open={state.showAction === 'PROJECT_DIALOG'}
        onClose={handleClose} />
      <ProjectInvoiceModal
        project={state.selected}
        invoiceType={state.invoiceType}
        open={state.showAction === 'ADD_INVOICE'}
        onClose={handleClose} />
    </AppErrorBoundry >
  )
}

export * from "./Details";
