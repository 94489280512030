import { put, takeEvery } from "redux-saga/effects";
import { SagaRegistry, setAlertAndLoading, axios, requestHandler, KEY_ICARUS_PM } from "../app";
import { userActions } from "./slice";

const validateTokenAPI = async (token) => {
  const header = { 'Authorization': `Bearer ${token}` };
  const res = await axios.get('/users/logged', header);
  let user = res.errors ? 'Error' : res
  if (user !== 'Error') {
    localStorage.setItem(KEY_ICARUS_PM, token);
  }
  return user
}
function* validateToken({ payload }) {
  yield setAlertAndLoading(true)
  try {
    let token = payload && payload.token;
    if (!token) {
      token = localStorage.getItem(KEY_ICARUS_PM);
    }
    let user = 'Error'
    if (token) {
      user = yield validateTokenAPI(token)
    }
    yield put(userActions.logInSuccess({ current_ent_usr: user, status: user !== 'Error' }));
    yield setAlertAndLoading(false)
  } catch (error) {
    if (error.status === 401) {
      let message = {
        autoClose: false,
        title: "LogIn is required",
        subtitle: "Your session is successfully logged out."
      }
      yield setAlertAndLoading(false, message);
      yield put(userActions.logoutSuccess());
      localStorage.removeItem(KEY_ICARUS_PM);
    } else {
      yield put(userActions.logInError());
      yield setAlertAndLoading(false)
    }
  }
}
function* fetchAllUsers() {
  yield requestHandler(function* () {
    let users = yield axios.get('/users');
    users = users || {}
    for (const userId in users) {
      if (Object.hasOwnProperty.call(users, userId)) {
        users[userId].id = userId;
        users[userId].label = users[userId].userName;
      }
    }
    yield put(userActions.setAllUsers(users))
  }, "Failed to fetch users", false);
}
function* createUser({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true)
    yield axios.post('/users', payload);
    yield put(userActions.fetchAllUsers())
    yield setAlertAndLoading(false, { message: "User Created Successfully." })
  }, "Failed to create user");
}
function* updateUser({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true)
    const { userId, body } = payload
    yield axios.put('/users/' + userId, body);
    yield put(userActions.fetchAllUsers())
    yield setAlertAndLoading(false, { message: "User Updated Successfully." })
  }, "Failed to update user");
}
function* disableUser({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true, null, true)
    const { userId } = payload
    yield axios.put(`/users/${userId}/disable`);
    yield put(userActions.fetchAllUsers())
    yield setAlertAndLoading(false, { message: "User Disabled Successfully." }, false)
  }, "Failed to update user");
}
function* changePassword({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true)
    yield axios.put(`/users/change-password`, { newPassword: payload });
    yield setAlertAndLoading(false, { message: "Password Updated Successfully." })
    localStorage.removeItem(KEY_ICARUS_PM);
    yield axios.wait(1000)
    yield put(userActions.logoutSuccess());
  }, "Failed to update password");
}
SagaRegistry.register(function* userSaga() {
  yield takeEvery("user/validateToken", validateToken);
  yield takeEvery("user/fetchAllUsers", fetchAllUsers);
  yield takeEvery("user/createUser", createUser);
  yield takeEvery("user/updateUser", updateUser);
  yield takeEvery("user/disableUser", disableUser);
  yield takeEvery("user/changePassword", changePassword);
})