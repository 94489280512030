import React, { useEffect } from 'react';
import { App } from 'antd';
import { LinearProgress } from '@mui/material';
import { AuthService } from "../../services";
import { AppErrorBoundry, Loading, AppNav, useSub, UploadViewer, LoadingModal } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { UserCEDialog } from "../Users";
import { RateCardDialog } from "../Ratecards";
import { ClientBrandDialog, ClientContactDialog } from "../Clients";
import {
  userActions, getCurrentUser, getAlert, AppActions, isProgress, miscActions, isDesigner, projectActions,
  taskActions, isLoggedOut, isAdmin, getFullLoading, isClientMiscLoaded, getAllUsersOriginal
} from "../../store";

export const HomeScreen = (props) => {
  const { notification } = App.useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getCurrentUser);
  const appAlert = useSelector(getAlert)
  const showProgress = useSelector(isProgress);
  const loggedOut = useSelector(isLoggedOut)
  const fullLoading = useSelector(getFullLoading)
  const isClienLoaded = useSelector(isClientMiscLoaded);
  const users = useSelector(getAllUsersOriginal);
  const [state, setState] = React.useState({ showAction: '', selected: null })
  useSub('USER_DIALOG', (user) => {
    handleShowAction('USER_DIALOG', user)
  })
  useSub('CONTACT_DIALOG', (contact) => {
    handleShowAction('CONTACT_DIALOG', contact)
  })
  useSub('BRAND_DIALOG', (brand) => {
    handleShowAction('BRAND_DIALOG', brand)
  })
  useSub('RATECARD_DIALOG', (brand) => {
    handleShowAction('RATECARD_DIALOG', brand)
  })
  useSub('VIEW_FILE', (file) => {
    handleShowAction('VIEW_FILE', file)
  })
  useSub('LOGOUT', () => {
    logoutSession()
  })
  useEffect(() => {
    document.title = 'ICARUS | Project Manager';
    if (user === null) {
      AuthService.init();
    }
    AuthService.onAuthStateChanged(handleAuthStateChange);
    AuthService.onIdTokenChanged(handleTokenChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (loggedOut) {
      logoutSession()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedOut]);
  useEffect(() => {
    if (appAlert) {
      const config = { duration: 2, message: appAlert.message, onClose: clearNotification }
      if (appAlert.success === false) {
        notification.error(config)
      } else {
        notification.success(config)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appAlert]);
  useEffect(() => {
    if (user === 'Error') {
      return navigate('/login')
    } else if (user) {
      if (Object.keys(users).length === 0) {
        dispatch(userActions.fetchAllUsers())
      }
      if (!isClienLoaded) {
        dispatch(miscActions.fetchClientsMisc())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const logoutSession = () => {
    AuthService.logout()
      .catch(() => {
        dispatch(AppActions.setAlert({ success: false, message: 'Logout failed' }));
      })
  }
  const handleShowAction = (dialog, selected) => {
    setState((_) => ({ ..._, showAction: dialog, selected }))
  }
  const clearNotification = (e, reason) => {
    if (reason !== 'clickaway') {
      dispatch(AppActions.setAlert(null));
    }
  }
  const handleTokenChange = (user) => {
    if (user) {
      user.getIdToken(true).then(idToken => {
        dispatch(userActions.validateToken({ token: idToken }))
      });
    } else {
      handleAuthStateChange(null)
    }
  }
  const handleAuthStateChange = (user) => {
    if (!user) {
      dispatch(miscActions.clear())
      dispatch(projectActions.clear())
      dispatch(taskActions.clear())
      dispatch(userActions.clear())
      navigate('/login')
    } else {
      dispatch(userActions.validateToken({ token: user.accessToken }))
    }
  }
  return (
    <div className='col w-100 h-100 screen o-hide'>
      {
        Boolean(user && user !== 'Error') ?
          <React.Fragment>
            <AppNav user={user} hideMenu={isDesigner(user)} />
            <div className='col flex-full o-hide' style={{ position: 'relative' }}>
              <LinearProgress classes={{ root: `api-progress c333 ${showProgress ? 'show' : ''}` }} color="inherit" />
              <main className='col flex-full o-hide'>
                <div className='col w-100 h-100 o-hide'>
                  <AppErrorBoundry>
                    {props.children}
                  </AppErrorBoundry>
                </div>
              </main>
            </div>
            {
              isAdmin(user) &&
              <React.Fragment>
                <UserCEDialog
                  user={state.selected}
                  open={state.showAction === 'USER_DIALOG'}
                  onClose={handleShowAction} />
                <ClientBrandDialog
                  brand={state.selected}
                  open={state.showAction === 'BRAND_DIALOG'}
                  onClose={handleShowAction} />
                <ClientContactDialog
                  contact={state.selected}
                  open={state.showAction === 'CONTACT_DIALOG'}
                  onClose={handleShowAction} />
                <RateCardDialog
                  ratecard={state.selected}
                  open={state.showAction === 'RATECARD_DIALOG'}
                  onClose={handleShowAction} />
              </React.Fragment>
            }
            <UploadViewer
              fileDetails={state.selected}
              open={state.showAction === 'VIEW_FILE'}
              onClose={handleShowAction}
            />
          </React.Fragment>
          :
          <Loading />
      }
      <LoadingModal open={fullLoading} />
    </div>
  )
}