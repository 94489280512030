import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Button as AntButton, Spin } from "antd";
import "./style.scss";

export const Button = (props) => {
  const { label, type, className, labelClassName, normal, primary, link, loading, disabled, ...rest } = props;
  const _labelClassName = `label cFFF f21 ${labelClassName || ''}`
  const getClassName = () => {
    let _className = `btn `;
    if (disabled) _className += `bgE5E5E5 `;
    if (primary) _className += `bg3B92E0 `;
    if (normal) _className += `normal v-ctr h-ctr col `;
    if (link) _className += `link `;
    _className += className || '';
    return _className;
  }
  return (
    <button
      className={getClassName()}
      onClick={rest.onClick}
      type={type}
      disabled={loading || disabled}
      {...rest}>
      {
        loading ?
          <Spin indicator={<LoadingOutlined spin style={{ color: '#FFF' }} />} />
          :
          <span className={_labelClassName}>{label}</span>
      }
    </button>
  )
}
export const IconButton = (props) => {
  return (
    <AntButton {...props} />
  )
}