import React from 'react';
import { Collapse } from 'antd';
import { useSelector } from "react-redux";
import { getAllUsersOriginal, getAllClientContactsAsList } from "../../store";
import { EditFilled } from '@ant-design/icons';
import { usePub, DataGridView, FormUI, Button } from "../../components";
import Fields from "./fields.json";

const Columns = [
  { title: 'Client', dataIndex: 'clientName', key: 'clientName', width: '15%', defaultSortOrder: 'descend', sort: true },
  { title: 'Name', dataIndex: 'name', key: 'name', width: '15%', sort: true },
  { title: 'Email', dataIndex: 'email', key: 'email', width: '23%', sort: true },
  { title: 'Phone', dataIndex: 'phone', key: 'phone', width: '14%', sort: true },
  { title: 'Added By', dataIndex: 'createdBy', key: 'createdBy', width: '12%', sort: true },
  { title: 'LAST UPDATED BY', dataIndex: 'lastUpdatedBy', key: 'lastUpdatedBy', width: '16%', sort: true },
  { title: '', dataIndex: 'actions', key: 'actions', width: '5%', render: 'actions', cellClassName: 'v-ctr h-arnd' }
]

export const ClientContactsScreen = () => {
  const publish = usePub();
  const [filter, setFilter] = React.useState({})
  const contacts = useSelector(getAllClientContactsAsList);
  const users = useSelector(getAllUsersOriginal);
  const handleEditContact = (contact) => {
    publish("CONTACT_DIALOG", contact)
  }
  const getRowActions = (contact) => {
    let actions = [], style = { fontSize: 18 };
    actions.push({
      Component: EditFilled, onClick: handleEditContact.bind(null, contact),
      style: { ...style, color: '#bdbdbd' }
    })
    return actions
  }
  const getColumns = () => {
    return Columns.map((_) => {
      const col = { ..._ };
      if (col.render === 'actions') {
        col.actions = getRowActions.bind(null)
      }
      return col
    })
  }
  const getContacts = () => {
    if (Array.isArray(contacts)) {
      let rows = [];
      for (let i = 0; i < contacts.length; i++) {
        let contact = { ...contacts[i] }, shouldPush = true;
        if (contact.createdBy) {
          contact.createdBy = users[contact.createdBy];
          contact.createdBy = contact.createdBy ? contact.createdBy.userName : '-'
        }
        if (contact.lastUpdatedBy) {
          contact.lastUpdatedBy = users[contact.lastUpdatedBy];
          contact.lastUpdatedBy = contact.lastUpdatedBy ? contact.lastUpdatedBy.userName : '-'
        }
        Object.keys(filter).every((key) => {
          let searchVal = String(filter[key]).toLowerCase();
          let rowVal = String(contact[key]).toLowerCase();
          if (searchVal && !rowVal.includes(searchVal)) {
            shouldPush = false;
          }
          return shouldPush
        })
        if (shouldPush) {
          rows.push(contact)
        }
      }
      return rows
    }
    return null
  }
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value })
  }
  return (
    <div className='row w-100 h-100 screen-pad o-hide bgE5E5E5 v-start'>
      <DataGridView
        className='contacts-table'
        rows={getContacts()}
        emptyMessage='No Contacts'
        Columns={getColumns()}
        initialPageSize={25}
        rowClassName={(user) => {
          return user.disabled ? 'row-disable' : ''
        }}
      />
      <div className='col filter-section bgFFF'>
        <Collapse
          expandIconPosition='end'
          activeKey='1'>
          <Collapse.Panel
            key="1"
            header={(
              <div className='row h-btn w-100'>
                <span className='bold f14'>FILTER CONTACTS</span>
                <Button
                  labelClassName='f14 c3d92e0'
                  onClick={() => setFilter({})}
                  label='Reset' link />
              </div>
            )} >
            <FormUI
              show={true}
              Fields={Fields.ContactFilter}
              formData={filter}
              onChange={handleFilterChange}
              className={`row h-btn fwarp`}
              direction={'vertical'}
            />
          </Collapse.Panel>
        </Collapse>
      </div>
    </div>
  )
}