import React from 'react';
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../store";
import { EditFilled } from '@ant-design/icons';
import { usePub, DataGridView, Button } from "../../components";
import { ChangePassword } from "./Dialogs";

const Columns = [
  { title: 'Name', dataIndex: 'userName', key: 'userName', width: '27%', defaultSortOrder: 'descend', sort: true },
  { title: 'Email address', dataIndex: 'email', key: 'email', width: '26.5%', sort: true },
  { title: 'Phone number', dataIndex: 'phone', key: 'phone', width: '16%', sort: true },
  { title: 'Role', dataIndex: 'role', key: 'role', width: '11%', sort: true },
  { title: '', dataIndex: 'edit', key: 'edit', width: '6%', render: 'actions', cellClassName: 'v-ctr h-ctr' },
  { title: '', dataIndex: 'change', key: 'change', width: '13.5%', render: 'actions', cellClassName: 'v-ctr h-ctr' }
]

export const SettingScreen = () => {
  const publish = usePub()
  const user = useSelector(getCurrentUser);
  const [showChangePass, setShowChangePass] = React.useState(false)
  const handleDisableUser = (dataIndex) => {
    if (dataIndex === 'edit') {
      publish("USER_DIALOG", { ...user, setting: true })
    } else if (dataIndex === 'change') {
      setShowChangePass(true)
    }
  }
  const getRowActions = (dataIndex) => {
    let action = {
      normal: true,
      className: 'no-shadow bgTrans w-auto',
      label: 'Change Password', labelClassName: 'cF2665E f16 med',
      Component: dataIndex === 'change' ? Button : EditFilled,
      onClick: handleDisableUser.bind(null, dataIndex),
      style: dataIndex === 'edit' ? { fontSize: 18, color: '#bdbdbd' } : {}
    }
    return [action]
  }
  const getColumns = () => {
    return Columns.map((_) => {
      const col = { ..._ };
      if (col.render === 'actions') {
        col.actions = getRowActions.bind(null, col.dataIndex)
      }
      return col
    })
  }
  return (
    <div className='col w-100 h-100 screen-pad o-hide bgE5E5E5'>
      <DataGridView
        className='user-table'
        rows={[user]}
        emptyMessage='No users'
        Columns={getColumns()}
      />
      <ChangePassword
        open={showChangePass}
        onClose={() => setShowChangePass(false)} />
    </div>
  )
}

export * from "./Dialogs";