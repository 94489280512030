import React, { useEffect, useState } from 'react';
import { EditFilled } from '@ant-design/icons';
import { DataGridView, AppErrorBoundry, usePub } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { miscActions, getRatecards } from "../../store";
export * from "./New"

const Columns = [
  { title: 'Title', dataIndex: 'title', key: 'title', width: '35%', sort: true, render: 'btn', type: 'link' },
  { title: 'Type', dataIndex: 'type', key: 'type', width: '18%', sort: true, },
  { title: 'Unit', dataIndex: 'unit', key: 'unit', width: '18%', sort: true, cellClassName: 'caps' },
  { title: 'Initial Value', dataIndex: 'initial', key: 'initial', width: '10%', sort: true, cellClassName: 'caps' },
  { title: 'Allowed In Task', dataIndex: 'allowedInTask', key: 'allowedInTask', width: '14%', cellClassName: 'caps' },
  { title: '', dataIndex: 'edit', key: 'edit', width: '5%', render: 'actions', cellClassName: 'caps' },
]
export const RatecardsScreen = () => {
  const dispatch = useDispatch();
  const publish = usePub();
  const ratecards = useSelector(getRatecards)
  useEffect(() => {
    dispatch(miscActions.fetchRatecards())
  }, []);

  const handleEditRC = (rc) => {
    publish("RATECARD_DIALOG", rc)
  }
  const getActions = (rc) => {
    return [{
      Component: EditFilled, onClick: handleEditRC.bind(null, rc),
      style: { fontSize: 14, color: '#bdbdbd' }
    }]
  }
  const getColumns = () => {
    const cols = Columns.map((_) => ({ ..._ }));
    cols[4].actions = getActions
    return cols
  }
  return (
    <AppErrorBoundry>
      <div className='col w-100 h-100 o-hide screen-pad bgE5E5E5'>
        <DataGridView
          noSelection
          initialPageSize={25}
          Columns={getColumns()}
          rows={ratecards} />
      </div>
    </AppErrorBoundry>
  )
}