import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useSub, AppErrorBoundry } from "../../components";
import { TasksTableScreen } from "./Table";
import { NewTaskModal } from "./New";
import { TaskFilter } from "./Filter";
import { DeleteTask, DesignerAssign, TaskUpdateNote, TaskClose } from "./Dialogs";
import {
  miscActions, projectActions, taskActions, isMiscLoaded, isDesigner, getCurrentUser,
  getAllProjects, getTaskFilter, getStatusOptions, isFinance
} from "../../store";
export * from "./Groups";

export const TaskHomeScreen = (props) => {
  const dispatch = useDispatch();
  const isLoaded = useSelector(isMiscLoaded);
  React.useEffect(() => {
    dispatch(miscActions.fetchRatecards())
  }, [])
  React.useEffect(() => {
    if (isLoaded) {
      dispatch(projectActions.fetchAllProjects())
    }
  }, [isLoaded])
  return (
    <React.Fragment>{props.children}</React.Fragment>
  );
}

export const TasksScreen = () => {
  const dispatch = useDispatch();
  const isLoaded = useSelector(isMiscLoaded);
  const user = useSelector(getCurrentUser);
  const projects = useSelector(getAllProjects);
  const [state, setState] = React.useState({ showTaskAction: '' })
  const statusList = getStatusOptions()
  React.useEffect(() => {
    if (isLoaded && Boolean(projects)) {
      let status = isFinance(user) ? statusList[5].fetchKey : "unbilled"
      if (isDesigner(user)) {
        status = 'designer';
      } else {
        const filterModel = getTaskFilter(user.userId);
        if (filterModel.status) {
          let index = statusList.findIndex(({ value }) => (value === filterModel.status));
          if (index > -1) {
            status = statusList[index].fetchKey
          }
        } else {
          filterModel.status = statusList[0].value
        }
        dispatch(taskActions.setTaskFilterModel(filterModel))
      }
      dispatch(taskActions.fetchAllTasks(status));
    }
  }, [isLoaded, projects]);

  useSub('NEW_TASK', () => {
    handleShowTaskAction('EDIT_TASK')
  })
  useSub('ASSIGN_TASK', (task) => {
    handleShowTaskAction('ASSIGN_TASK', task)
  })
  useSub('REASSIGN_TASK', (task) => {
    handleShowTaskAction('REASSIGN_TASK', task)
  })
  useSub('UNASSIGN_TASK', (task) => {
    handleShowTaskAction('UNASSIGN_TASK', task)
  })
  useSub('CLOSE_TASK', (task) => {
    handleShowTaskAction('CLOSE_TASK', task)
  })
  useSub('UPDATE_TASK_NOTE', (task) => {
    handleShowTaskAction('UPDATE_TASK_NOTE', task)
  })

  const handleShowTaskAction = (dialog, task) => {
    setState((_) => ({ ..._, showTaskAction: dialog, selectedTask: task }))
  }
  const handleCloseAction = () => {
    handleShowTaskAction('', null)
  }
  return (
    <AppErrorBoundry>
      <div className='col w-100 h-100 screen-pad o-hide bgE5E5E5'>
        <div className='col f-rest o-hide'>
          <div className='col h-100 oy-auto'>
            {
              !isDesigner(user) && <TaskFilter />
            }
            <div className='col f-rest task-table-container'>
              <TasksTableScreen
                onEditTask={(task) => {
                  handleShowTaskAction('EDIT_TASK', task)
                }}
                onDeleteTask={(task) => {
                  handleShowTaskAction("DELETE_TASK", task)
                }}
              />
            </div>
          </div>
        </div>
        <NewTaskModal
          task={state.selectedTask}
          open={state.showTaskAction === 'EDIT_TASK'}
          onClose={handleCloseAction} />
        <DeleteTask
          task={state.selectedTask}
          open={state.showTaskAction === 'DELETE_TASK'}
          onClose={handleCloseAction} />
        <DesignerAssign
          task={state.selectedTask}
          type={state.showTaskAction}
          open={
            state.showTaskAction === 'REASSIGN_TASK' ||
            state.showTaskAction === 'ASSIGN_TASK' ||
            state.showTaskAction === 'UNASSIGN_TASK'
          }
          onClose={handleCloseAction} />
        <TaskUpdateNote
          task={state.selectedTask}
          open={state.showTaskAction === 'UPDATE_TASK_NOTE'}
          onClose={handleCloseAction} />
        <TaskClose
          task={state.selectedTask}
          open={state.showTaskAction === 'CLOSE_TASK'}
          onClose={handleCloseAction} />
      </div>
    </AppErrorBoundry>
  )
}