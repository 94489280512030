import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { IcarusLogo } from "../Assets";
import { usePub } from "../Event";
import { Button } from "../Button";
import { Dropdown } from 'antd';
import { CaretDownOutlined } from "@ant-design/icons";
import "./style.scss";

const NavItems = [
  { label: 'Projects', path: '/projects' },
  { label: 'Tasks', path: '/tasks' },
]
const AdminMenus = [
  { label: 'Users', link: "/users", addEvent: "USER_DIALOG" },
  { label: 'Clients', link: "/clients", addEvent: "CONTACT_DIALOG" },
  { label: 'Brands', link: "/clients/brands", addEvent: "BRAND_DIALOG" },
  { label: 'Rate Cards', link: '/ratecards', addEvent: "NEW_RC" },
  { type: 'divider' }
]
const UserMenus = [
  { label: 'Settings', link: "/settings" },
  { label: 'Logout', link: '#', event: "LOGOUT" }
]
const LinkMenu = (props) => {
  const publish = usePub();
  const { label, link, addEvent, event } = props
  const handleClick = (e) => {
    // e.stopPropagation();
    publish(addEvent || event)
  }
  return (
    <div className='menu-item row h-btn'>
      <Link to={link} className='f18 c333 f-rest' onClick={event ? handleClick : undefined}>{label}</Link>
      {
        addEvent &&
        <Button className='menu-new bg219653' labelClassName='f14 bold-700' label="+ new" onClick={handleClick} />
      }
    </div>
  )
}
export const AppNav = (props) => {
  const { user, hideMenu } = props;
  const location = useLocation();
  const publish = usePub();
  const isAdmin = user.role === 'Admin';
  const getMenus = () => {
    let menus = [...(isAdmin ? AdminMenus : []), ...UserMenus];
    return menus.map((_) => {
      return (_.type ? _ : { key: _.label, label: (<LinkMenu {..._} />) })
    })
  }
  const getNewButton = () => {
    let label = '', event = '', className = '';
    const handleClick = () => {
      publish(event)
    }
    if (location.pathname === NavItems[0].path) {
      label = '+ New Project';
      event = 'PROJECT_DIALOG';
    } else if (location.pathname === NavItems[1].path) {
      label = '+ New Task'
      event = 'NEW_TASK';
    }
    if (isAdmin) {
      className = 'bg27AE60';
      switch (location.pathname) {
        case AdminMenus[0].link:
          label = '+ New User';
          event = 'USER_DIALOG';
          break;
        case AdminMenus[1].link:
          label = '+ New Client Contact';
          event = 'CONTACT_DIALOG';
          break;
        case AdminMenus[2].link:
          label = '+ New Brand';
          event = 'BRAND_DIALOG';
          break;
        case AdminMenus[3].link:
          label = '+ New RateCard';
          event = 'RATECARD_DIALOG';
          break;
      }
    }
    if (label && !hideMenu) {
      return { label, className, type: 'button', labelClassName: 'bold', onClick: handleClick }
    }
    return null
  }
  const newButton = getNewButton()
  return (
    <nav className="row navbar navbar-shadow h-btn">
      <div className="row" style={{ width: "100%" }}>
        <Link to='/'><IcarusLogo alt="Icarus-Logo" className="logo-icarus" /></Link>  
        {
          !hideMenu && NavItems.map((item) => {
            const isActive = location.pathname.indexOf(item.path) > -1
            return (
              <Link to={item.path} className={`link-btn ${isActive ? 'bgFFF' : 'bgTrans'}`} key={item.path} >
                <span className={`f14 cFFF bold text-up ${isActive ? 'c333' : 'cFFF'}`}>{item.label}</span>
              </Link>
            )
          })
        }
      </div>
      <div className='row'>
        {
          Boolean(newButton) &&
          <Button  {...newButton} className={`new-btn ${newButton.className || 'bg3B92E0'}`} />
        }
        <Dropdown overlayClassName='nav-menu' menu={{ items: getMenus() }} trigger={['click']} >
          <div className='row v-start h-end pointer' >
            <div className='col v-end cFFF'>
              <span className='f21 line-12 no-wrap bold'>{user.userName}</span>
              <span className='f14 line-1'>{user.role}</span>
            </div>
            <CaretDownOutlined className='cFFF f10 caret' />
          </div>
        </Dropdown>
      </div>
    </nav>
  )
}