import { DateService } from "../../services";
const PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;

const getFilterKey = (userId) => {
  return `icarus-pm-task-filter-${PROJECT_ID}-${userId}`
}
export const getTaskFilter = (userId) => {
  let filter = localStorage.getItem(getFilterKey(userId));
  return JSON.parse(filter || '{}')
}
export const setTaskFilter = (userId, filter) => {
  localStorage.setItem(getFilterKey(userId), JSON.stringify(filter));
}
export const getStatusOptions = () => {
  return [
    { label: 'All', value: 'all', fetchKey: 'unbilled' },
    { label: 'Opened', value: 'opened', fetchKey: 'unbilled' },
    { label: 'Delayed', value: 'delayed', fetchKey: 'unbilled' },
    { label: 'Unexported', value: 'closed', fetchKey: 'closed' },
    { label: 'Exported', value: 'billing', fetchKey: 'billed' },
    { label: 'Deleted', value: 'deleted', fetchKey: 'deleted' },
  ]
}
export const isTaskDelayed = (task) => {
  const today = new Date().setHours(0, 0, 0, 0);
  return !task.closed && !task.deleted && (DateService(task.estDeliveryDate).isSame(today) || DateService(task.estDeliveryDate).isBefore(today))
}