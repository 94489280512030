import React, { useState, useRef, useEffect } from 'react';
import { FilePdfFilled, FileImageFilled } from '@ant-design/icons';
import { Table } from 'antd';
import { Loading } from "../Screen";
import { SearchComponent } from "./Search";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { usePub } from "../Event";
import "./style.scss"

const ExpandColumn = { title: '', dataIndex: 'expand', key: 'expand', width: "2%" }

const getClassName = (col) => {
  return `col h-100 h-ctr cell ${col.cellClassName || 'f14 '} ${col.noborder ? 'no-border' : ''} c000 reg line-22`
}
const EmptyData = (props) => {
  const { height, message } = props
  return (
    <div className='bgTrans col h-ctr v-ctr' style={{ height }}>
      <h6 className='f14 med c00085'>{message || 'No Data'}</h6>
    </div>
  )
}
const CustomHighlighter = (props) => {
  const { searchText, text, ...rest } = props
  return (
    <Highlighter
      highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ''}
      {...rest}
    />
  )
}
const HTMLCell = (col, text, record) => {
  const content = (text && typeof text === "object" && text.label) ? text.label : text;
  return (
    <div className={getClassName(col)} dangerouslySetInnerHTML={{ __html: content }} ></div>
  )
}
const Cell = (col, text, record) => {
  const content = (text && typeof text === "object" && text.label) ? text.label : text;
  const showIndicator = Boolean(col.rowIndicator)
  return (
    <React.Fragment>
      <div className={`col h-ctr cell f14`} >
        {
          col.searchedColumn === col.dataIndex ?
            <CustomHighlighter searchText={col.searchText} text={content} />
            :
            content
        }
      </div>
      {
        showIndicator && <div className='pr-indicator'>{record.rowIndicator || col.rowIndicator}</div>
      }
    </React.Fragment>
  )
}
const ExpandCell = (col, expandedRows, text, record) => {
  const isExpanded = expandedRows.includes(String(record.id))
  const handleClick = (e) => {
    e.stopPropagation()
    col.onExpand && col.onExpand(record)
  }
  return (
    <div onClick={handleClick} className={`pointer expand-cell col v-ctr h-ctr ${isExpanded ? 'expand' : ''}`}>
      <i className='icon-arrow-up f12 cBDBDBD' />
    </div>
  )
}
const Sorter = (dataIndex, recordA, recordB) => {
  var nameA = recordA[dataIndex], nameB = recordB[dataIndex];
  nameA = ((typeof nameA === 'object' ? nameA.label : nameA) || '')
  nameB = ((typeof nameB === 'object' ? nameB.label : nameB) || '')
  if (typeof nameA === 'string') nameA = String(nameA).toLowerCase();
  if (typeof nameB === 'string') nameB = String(nameB).toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
}
const FileViewer = (col, text, record) => {
  const publish = usePub()
  const upload = record[col.dataIndex];
  const handleFileShow = () => {
    const data = {
      projectName: '',
      fileName: upload.name,
      documentType: 'thumbnail',
      type: upload.type,
      url: upload.url
    };
    publish("VIEW_FILE", data);
  }
  return (
    <div className='row cell w-100 h-100'>
      {
        Boolean(upload) &&
        <div className="col f-rest h-100 h-ctr pointer thumb-view" onClick={handleFileShow}>
          {upload.type === 'application/pdf' ? <FilePdfFilled className='f20' style={{ color: '#BDBDBD' }} /> : <FileImageFilled className='f1' style={{ color: '#BDBDBD' }} />}
          <span className='f9'>Click to View</span>
        </div>
      }
      {
        Boolean(col.textDataIndex) && <div>{record[col.textDataIndex]}</div>
      }
    </div>
  )
}
const Actions = (col, text, record) => {
  const actions = Array.isArray(col.actions) ? col.actions :
    (typeof (col.actions) === 'function' ? col.actions(record) : []);
  return (
    <div className={`row w-100 ${col.cellClassName || ''}`}>
      {
        actions.map((action, i) => {
          const { Component, ...rest } = action
          return (
            <Component {...rest} />
          )
        })
      }
    </div>
  )
}
export const DataGridView = (props) => {
  const { rows, EmptyComponent, emptyProps, Columns, expandable, showHeader, className, rowClassName, initialPageSize, onRow } = props;
  const showPagination = props.showPagination === undefined ? true : props.showPagination
  const [state, setState] = useState({
    searchedColumn: null, searchText: '', height: 100, showfilterColumn: '', expandedRows: [],
    pagination: {
      pageSize: initialPageSize || 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '75', '100']
    }
  })
  const searchInput = useRef(null)
  const tableRef = useRef(null)
  useEffect(() => {
    let tableRefCurrent = tableRef.current;
    const setHeight = (height) => {
      if ((height) < 400) {
        height = Array.isArray(rows) && rows.length === 0 ? 300 : 360
      }
      setState((_) => ({ ..._, height: height }))
    }
    const handleResize = (entries) => {
      for (let entry of entries) {
        if (entry.target === tableRefCurrent) {
          setHeight(entry.contentRect.height)
        }
      }
    }
    const resizeObserver = new ResizeObserver(handleResize);
    if (tableRefCurrent) {
      resizeObserver.observe(tableRefCurrent);
      setHeight(tableRefCurrent.clientHeight)
    }
    return () => {
      if (tableRefCurrent) {
        resizeObserver.unobserve(tableRefCurrent);
      }
    };
  }, [rows]);
  const getFilterOptions = (column) => {
    return ({
      filterMultiple: false,
      filterDropdownOpen: state.showfilterColumn === column.dataIndex,
      filterDropdown: (({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <SearchComponent
            {...column}
            value={selectedKeys[0]}
            searchInput={searchInput}
            onSearchChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }}
            onFilter={() => {
              confirm();
              setState((_) => ({ ..._, searchText: selectedKeys[0], searchedColumn: column.dataIndex }))
            }}
            onReset={() => {
              setState((_) => ({ ..._, searchText: '' }))
              clearFilters();
            }}
            onClear={() => {
              setState((_) => ({ ..._, searchText: '', searchedColumn: null }))
              clearFilters();
              confirm();
            }}
            onSearch={() => {
              confirm({ closeDropdown: false });
              setState((_) => ({ ..._, searchText: selectedKeys[0], searchedColumn: column.dataIndex }))
            }}
          />
        )
      }),
      filterIcon: (filtered) => (
        <SearchOutlined style={filtered ? { color: '#1890ff' } : {}} />
      ),
      onFilter: (value, record) => {
        let attributeVal = record[column.dataIndex];
        attributeVal = (typeof attributeVal === "object") ? attributeVal.label : (attributeVal || '');
        return attributeVal.toString().toLowerCase().includes(value.toLowerCase())
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setState((_) => ({ ..._, showfilterColumn: column.dataIndex }))
          setTimeout(() => searchInput.current && searchInput.current?.focus(), 100);
        } else {
          setState((_) => ({ ..._, showfilterColumn: '' }))
        }
      }
    })
  }
  const Title = (title, col) => {
    return <div className={`cell ${col.noborder ? 'no-border' : ''} ${col.headerClassName || 'f16 '} c00085 bold`}>{title}</div>
  }
  const getColumns = () => {
    let columns = Columns;
    columns = columns.map((_) => {
      let col = { ..._, titleText: _.title }
      col.title = Title.bind(null, _.title, col)
      col.searchedColumn = state.searchedColumn;
      col.searchText = state.searchText
      if (col.render) {
        if (typeof col.render === "string") {
          switch (col.render) {
            case 'upload-view':
              col.render = FileViewer.bind(null, col);
              break;
            case 'actions':
              col.render = Actions.bind(null, col);
              break;
            case 'html':
              col.render = HTMLCell.bind(null, col);
              break;
            default: col.render = Cell.bind(null, col);
          }
        }
      } else {
        col.render = Cell.bind(null, col)
      }
      if (col.sort) {
        col.sorter = Sorter.bind(null, col.dataIndex);
      }
      if (col.search) {
        col = { ...col, ...getFilterOptions(col) }
      }
      return col;
    });
    if (expandable) {
      const expandableCol = { ...ExpandColumn, onExpand: handleRowExpand }
      expandableCol.render = ExpandCell.bind(null, expandableCol, state.expandedRows)
      columns.push(expandableCol)
    }
    return columns;
  }
  const handleRowExpand = (record) => {
    let expandedRows = [...state.expandedRows]
    let index = expandedRows.indexOf(record.id)
    if (index === -1) {
      expandedRows.push(record.id)
    } else {
      expandedRows.splice(index, 1)
    }
    setState((_) => ({ ..._, expandedRows: expandedRows }))
  }
  const getExapandable = () => {
    let expandableConfig;
    if (expandable) {
      expandableConfig = {
        showExpandColumn: false,
        expandIcon: () => null,
        onExpand: handleRowExpand,
        expandedRowKeys: state.expandedRows,
        expandedRowRender: props.expandedRowRender,
      }
    }
    return expandableConfig
  }
  const handleTableChange = (pagination) => {
    setState((_) => ({ ..._, pagination: { ...pagination } }))
  }
  const getScrollY = () => {
    let offset = state.height, paginationOffset = 84, header = 40;
    // if (state.pagination.current === 1 && state.pagination.pageSize >= rows.length) {
    //   paginationOffset = 0;
    // }
    offset -= (header + paginationOffset)
    return offset
  }
  return (
    <div className={`col w-100 h-100 table-ct o-hide ${className || ''}`} ref={tableRef} >
      {
        state.height &&
        <React.Fragment>
          <Table
            className="doc-table bgTrans"
            sticky
            locale={{
              emptyText: (
                rows === null ?
                  <div className='col w-100 v-ctr h-ctr' style={{ height: getScrollY() }}>
                    <Loading isSmall />
                  </div>
                  : !Boolean(state.searchedColumn) && Boolean(EmptyComponent) ?
                    <EmptyComponent />
                    :
                    <EmptyData message={Boolean(state.searchedColumn) ? "No Matching Data" : props.emptyMessage} height={emptyProps && emptyProps.height ? emptyProps.height : state.height - 140} />
              )
            }}
            scroll={{
              y: getScrollY(),
            }}
            pagination={
              showPagination ? {
                ...state.pagination,
                hideOnSinglePage: false,
                className: 'bgFFF v-ctr'
              } : false
            }
            columns={getColumns()}
            dataSource={rows || []}
            expandable={getExapandable()}
            rowClassName={rowClassName}
            rowKey={(record) => {
              return record.id
            }}
            onChange={handleTableChange}
            showHeader={showHeader}
            onRow={onRow}
          />
        </React.Fragment>
      }
    </div>
  )
}