import { put, takeEvery } from "redux-saga/effects";
import { SagaRegistry, setAlertAndLoading, axios, sorter, requestHandler } from "../app";
import { miscActions } from "./slice";

function* fetchRatecards() {
  yield requestHandler(function* () {
    let res = yield axios.get('/ratecards');
    res = (res || []).map((_) => {
      return {
        ..._, id: _.rcId, label: _.title,
        allowedInTask: _.taskItem ? 'Yes' : 'No',
      }
    });
    res.sort(sorter.bind(null, 'label'));
    yield put(miscActions.setRatecards(res))
  }, "Failed to Fetch Ratecards", false);
}
function* createRatecard({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true)
    const { ratecard } = payload
    yield axios.post('/ratecards', { ratecard });
    yield put(miscActions.fetchRatecards())
    yield setAlertAndLoading(null, { message: 'Ratecard Created Successfully.' })
  }, "Failed to create Ratecard");
}
function* updateRatecard({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true)
    const { ratecard, id } = payload
    yield axios.put(`/ratecards/${id}`, { ratecard });
    yield put(miscActions.fetchRatecards())
    yield setAlertAndLoading(false, { message: 'Ratecard Updated Successfully.' })
  }, "Failed to update Ratecard");
}
function* fetchClientsMisc() {
  yield requestHandler(function* () {
    let clients = yield axios.get('/clients');
    //contacts
    let clientContacts = yield axios.get('/clients/contacts');
    for (const clientId in clientContacts) {
      if (Object.hasOwnProperty.call(clientContacts, clientId)) {
        for (const contactId in clientContacts[clientId]) {
          if (Object.hasOwnProperty.call(clientContacts[clientId], contactId)) {
            let contact = clientContacts[clientId][contactId];
            const client = clients[clientId];
            clientContacts[clientId][contactId] = { ...contact, label: contact.name, client, clientName: client.name }
          }
        }
      }
    }
    yield put(miscActions.setClientContacts(clientContacts))
    // brands
    let brands = yield axios.get('/clients/brands');
    brands = Object.values(brands).map((_) => {
      const brand = { ..._, key: _.id, label: _.title };
      const client = clients[brand.clientId];
      if (client) {
        brand.client = client;
        brand.clientName = client.name
      }
      return brand;
    });
    brands.sort(sorter.bind(null, 'label'));
    yield put(miscActions.setBrands(brands))
    //clients
    clients = Object.values(clients).map((_) => ({ ..._, key: _.id, label: _.name }));
    clients.sort(sorter.bind(null, 'label'));
    yield put(miscActions.setClients(clients))
  }, "Failed to fetch Clients", false);
}
function* createBrand({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true)
    yield axios.post('/clients/brands', payload);
    yield put(miscActions.fetchClientsMisc())
    yield setAlertAndLoading(false, { message: 'Brand Created Successfully.' })
  }, "Failed to create brand", false);
}
function* updateBrand({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true)
    const { brandId, brand } = payload
    yield axios.put('/clients/brands/' + brandId, brand);
    yield put(miscActions.fetchClientsMisc())
    yield setAlertAndLoading(false, { message: 'Brand Updated Successfully.' })
  }, "Failed to create brand");
}
function* createContact({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true)
    yield axios.post('/clients/contacts', payload);
    yield put(miscActions.fetchClientsMisc())
    yield setAlertAndLoading(false, { message: 'Contact Created Successfully.' })
  }, "Failed to create contact");
}
function* updateContact({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true)
    const { contactId, clientId, contact } = payload
    yield axios.put(`/clients/contacts/${clientId}/${contactId}`, contact);
    yield put(miscActions.fetchClientsMisc())
    yield setAlertAndLoading(false, { message: 'Contact Updated Successfully.' })
  }, "Failed to update contact");
}
SagaRegistry.register(function* miscSaga() {
  yield takeEvery("misc/fetchRatecards", fetchRatecards);
  yield takeEvery("misc/createRatecard", createRatecard);
  yield takeEvery("misc/updateRatecard", updateRatecard);
  yield takeEvery("misc/fetchClientsMisc", fetchClientsMisc);
  yield takeEvery("misc/createBrand", createBrand);
  yield takeEvery("misc/updateBrand", updateBrand);
  yield takeEvery("misc/createContact", createContact);
  yield takeEvery("misc/updateContact", updateContact);
})