import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { userActions, getLogInStatus, getCurrentUser, isUserDisabled, AppActions, isLoading } from "../../store";
import { IcarusLogo, FormUI } from "../../components";
import { AuthService, DateService } from "../../services";
import { ForgetPassword } from "./Dialogs";
import Fields from "./fields.json";

const Actions = [
  { label: 'Login', primary: true, type: 'submit', labelClassName: 'bold', className: 'btn-login' },
  { label: 'Forgot Password?', link: true, type: 'button', labelClassName: 'f16', className: "btn-forgot" },
]

export const LogInScreen = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginStatus = useSelector(getLogInStatus);
  const user = useSelector(getCurrentUser)
  const [searchParams] = useSearchParams();
  const loading = useSelector(isLoading)
  const sessionToken = searchParams.get('sessionToken');
  const [state, setState] = React.useState({ formData: {}, errors: {}, showForget: false })
  useEffect(() => {
    document.title = 'ICARUS | Project Manager';
    if (!sessionToken) {
      // return navigate('/')
    }
    // dispatch(userActions.validateToken({ token: sessionToken }))
  }, []);
  useEffect(() => {
    if (loginStatus && user) {
      dispatch(userActions.logInSuccessClear())
      if (isUserDisabled(user)) {
        const errors = state.errors || {};
        errors.email = `Account Disabled On ${DateService(user.disabledOn).format('DD/MM/YYYY')}, Contact Admin`
        return setState((_) => ({ ..._, errors }));
      }
      navigate("/")
    }
  }, [loginStatus]);

  const handleChange = (e) => {
    const formData = { ...state.formData };
    const { name, value } = e.target;
    formData[name] = value;
    setState((_) => ({ ..._, formData }))
  }
  const handleShowForgotPassword = (value) => {
    setState((_) => ({ ..._, showForget: value }))
  }
  const handleSubmitLogIn = () => {
    const { email, password } = state.formData;
    dispatch(AppActions.setLoading(true))
    AuthService.logInWithEmailPassword(email, password)
      .then((res) => {
        dispatch(userActions.validateToken({ token: res._tokenResponse.idToken }))
      })
      .catch((error) => {
        const { code } = error;
        const errors = { email: '', password: '' };
        switch (code) {
          case "auth/user-not-found": errors.email = 'User not Found'; break;
          case "auth/wrong-password": errors.password = 'Invalid Password'; break;
          case "auth/user-disabled": errors.email = 'Account Disabled. Contact Admin'; break;
          default: errors.email = 'Error While Logging';
        }
        setState((_) => ({ ..._, errors }))
        dispatch(AppActions.setLoading(false))
      })
  }
  const getActions = () => {
    let actions = [...Actions];
    actions[0].loading = loading;
    actions[1].onClick = handleShowForgotPassword.bind(null, true)
    return actions;
  }
  return (
    <div className='col w-100 h-100 login-screen bg333 v-ctr h-ctr'>
      <div className='col'>
        <div className='row w-100 v-end'>
          <IcarusLogo className="logo-icarus" />
          <div className="col f18 med line-1 cFFF">
            Project <br />Management
          </div>
        </div>
        <FormUI
          formDisabled={loading}
          className='login-form'
          Fields={Fields.LogIn}
          errors={state.errors}
          formData={state.formData}
          onChange={handleChange}
          actions={getActions()}
          onSubmit={handleSubmitLogIn}
          actionContainerClass='col v-ctr h-ctr'
        />
      </div>
      <ForgetPassword
        open={state.showForget}
        onClose={handleShowForgotPassword.bind(null, false)}
      />
    </div>
  )
}