import React from 'react';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import {
  getClients, getExecutives, getBrandsByClient, setProjectFilterToStorage, isFinance, userActions, isAdmin,
  getClientContactsAsArray, getProjectStatusList, getProjectFilter, projectActions, getCurrentUser,
} from "../../store";
import { FormUI, Button } from "../../components";
import { Collapse } from 'antd';
import { DateService } from "../../services";
import FormFields from "./fields.json";

const { Panel } = Collapse;
const InitialState = {
  expandActiveKey: '1',
  exportRefreshing: { deliverable: false, designFee: false },
  billingTab: 'DELIVERABLE'
}
const getSelectedClients = (filter) => {
  let clientIds = []
  if (filter && Array.isArray(filter.clients)) {
    clientIds = filter.clients;
  }
  return clientIds;
}
export const ProjectFilter = () => {
  const dispatch = useDispatch()
  const clients = useSelector(getClients);
  const filterModel = useSelector(getProjectFilter);
  const selectedClients = getSelectedClients(filterModel)
  const currentUser = useSelector(getCurrentUser);
  const executives = useSelector(getExecutives.bind(null, false));
  const clientContacts = useSelector(getClientContactsAsArray.bind(null, selectedClients));
  const brands = useSelector(getBrandsByClient.bind(null, selectedClients));
  const statusList = getProjectStatusList(true)
  const isFinanceRole = isFinance(currentUser);
  const isAdminRole = isAdmin(currentUser);
  const [state, setState] = React.useState({ ...InitialState, });
  React.useEffect(() => {
    if (currentUser) {
      handleRefreshExport(false, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])
  const getFields = () => {
    if (isFinanceRole || isAdminRole) {
      return FormFields.ProjectFilter.filter(({ attribute }) => {
        return (attribute !== 'toDate' && attribute !== 'fromDate')
      })
    }
    return FormFields.ProjectFilter
  }
  const getDateRangeFields = () => {
    let fields = [], len = FormFields.ProjectFilter.length;
    fields.push({ ...FormFields.ProjectFilter[len - 2], label: null })
    fields.push({ ...FormFields.ProjectFilter[len - 1] })
    return fields
  }
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    const model = JSON.parse(JSON.stringify(filterModel));
    model[name] = value;
    dispatch(projectActions.setFilterModel(model))
    setProjectFilterToStorage(currentUser.userId, model)
  }
  const getFilterOptions = (attribute) => {
    let options = [],
      removeDisable = (_) => ({ ..._, disabled: false });
    switch (attribute) {
      case "projectExport": options = [{ id: 'unexported', label: 'Unexported' }]; break;
      case "status": options = statusList; break;
      case "clients": options = clients; break;
      case "icarusContact": options = executives.map(removeDisable); break;
      case "clientBrands": options = brands; break;
      case "clientContacts": options = clientContacts; break;
      default: break;
    }
    return options
  }
  const handleFilterExpand = () => { }
  const handleFilterReset = (e) => {
    e.stopPropagation();
    dispatch(projectActions.resetFilterModel())
    setProjectFilterToStorage(currentUser.userId, {})
  }
  const getSelectedExportStatus = () => {
    let key = state.billingTab === 'DELIVERABLE' ? 'projects' : 'designFees';
    return currentUser.exportStatus ? (currentUser.exportStatus[key] || {}) : {}
  }
  const handleTabToggle = (key) => {
    if (isAdminRole) {
      handleFilterChange({ target: { name: 'dateRangeFor', value: key } })
    }
    setState((_) => ({ ..._, billingTab: key }))
  }
  const handleExport = () => {
    if (!getSelectedExportStatus().exporting) {
      if (state.billingTab === 'DELIVERABLE') {
        dispatch(projectActions.exportDeliverables())
      } else {
        dispatch(projectActions.exportDesginFees())
      }
    }
  }
  const getExportButtonProps = () => {
    let btnProps = {};
    if (state.billingTab === 'DELIVERABLE') {
      btnProps.disabled = state.exportRefreshing.deliverable
      btnProps.label = btnProps.disabled ? 'Exporting ...' : 'EXPORT DELIVERABLES';
      btnProps.hint = "*exports all unbilled the design fee's in all projects"
    } else {
      btnProps.disabled = state.exportRefreshing.designFee
      btnProps.label = btnProps.disabled ? 'Exporting ...' : 'EXPORT DESIGN FEE';
      btnProps.hint = "*exports all unbilled ratecards in all projects";
    }
    return btnProps
  }
  const handleRefreshExport = (val, invoke) => {
    let exportRefreshing = { ...state.exportRefreshing }
    let key = state.billingTab === 'DELIVERABLE' ? 'deliverable' : 'designFee';
    exportRefreshing[key] = val;
    setState((_) => ({ ..._, exportRefreshing: exportRefreshing }))
    if (invoke) {
      dispatch(userActions.validateToken())
    }
  }
  const exportStatus = getSelectedExportStatus();
  const btnProps = getExportButtonProps()
  return (
    <div className='col filter-section bgFFF o-hide h-100'>
      <Collapse activeKey={['1']} onChange={handleFilterExpand} className='h-100 o-hide'>
        <Panel
          key="1"
          className='col h-100 o-hide'
          showArrow={false}
          header={(
            <div className='row h-btn w-100'>
              <span className='bold f14'>FILTER PROJECT</span>
              <Button
                labelClassName='f14 c3d92e0'
                onClick={handleFilterReset}
                label='Reset' link />
            </div>
          )} >
          <FormUI
            show={true}
            Fields={getFields()}
            formData={filterModel}
            onChange={handleFilterChange}
            getOptions={getFilterOptions}
            className={`row h-btn fwarp f-rest-2`}
            direction={'vertical'}
          />
          {
            (isFinanceRole || isAdminRole) &&
            <div className='col w-100 billing'>
              <label className='text-up'>{isAdminRole ? 'DATE RANGE' : 'Billing'}</label>
              <div className='row'>
                <Button
                  normal
                  label='Design Fees'
                  labelClassName='f14 bold-700 c777'
                  onClick={handleTabToggle.bind(null, 'DESIGNFEE')}
                  className={`no-shadow w-50 ${state.billingTab === 'DESIGNFEE' ? 'bgE0E0E0' : 'bgTrans'}`}
                />
                <Button
                  normal
                  label='Rate Cards'
                  labelClassName='f14 bold-700 c777'
                  onClick={handleTabToggle.bind(null, 'DELIVERABLE')}
                  className={`no-shadow w-50 ${state.billingTab === 'DELIVERABLE' ? 'bgE0E0E0' : 'bgTrans'}`}
                />
              </div>
              {
                isFinanceRole &&
                <React.Fragment>
                  <div className='w-100 h-ctr col v-ctr'>
                    <Button
                      className='bgBDBDBD'
                      disabled={btnProps.disabled}
                      labelClassName='f14 bold-700 text-up'
                      onClick={handleExport}
                      label={btnProps.label}
                    />
                    <span className='f9 note'>{btnProps.hint}</span>
                  </div>
                  {
                    !exportStatus.exporting &&
                    <div className='col w-100'>
                      <div className="row h-btn f14">
                        <span>Status:</span>
                        <span className={`caps ${exportStatus.status === 'error' ? 'cF2665E' : 'c219653'}`}>{exportStatus.status}</span>
                      </div>
                      <div className="row h-btn f14">
                        <span>DateTime:</span>
                        <span>{DateService(exportStatus.exportDateTime).format('DD/MM/YYYY, h:mm a')}</span>
                      </div>
                      {
                        exportStatus.status === 'error' && <span className='cF2665E text-center note f14'>Error While Exporting, Contact Admin</span>
                      }
                      {
                        Boolean(exportStatus.status === 'success' && !exportStatus.path) && <span className='note f12 text-center'>No Unexported Items</span>
                      }
                      {
                        Boolean(exportStatus.status === 'success' && exportStatus.path) &&
                        <Link className='text-center c3d92e0 note f14' to={exportStatus.path} target='_blank' >Link to access exported folder</Link>
                      }
                    </div>
                  }
                  {
                    state.exportRefreshing[(state.billingTab === 'DELIVERABLE' ? 'deliverable' : 'designFee')] &&
                    <div className='row v-ctr h-ctr note'>
                      <LoadingOutlined style={{ fontSize: 32, marginRight: 16 }} />
                      <span className='f16'>Refreshing...</span>
                    </div>
                  }
                </React.Fragment>
              }
              {
                isAdminRole &&
                <FormUI
                  show={true}
                  Fields={getDateRangeFields()}
                  formData={filterModel}
                  onChange={handleFilterChange}
                  getOptions={getFilterOptions}
                  className={`row h-btn fwarp`}
                  direction={'vertical'}
                />
              }
            </div>
          }
        </Panel>
      </Collapse>
    </div>
  )
}