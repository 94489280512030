import React, { forwardRef } from 'react';
import { Button } from '../Button';
import { Input } from "../Input";

const FilterSearchComponent = (props, ref) => {
  const { value, titleText } = props;
  return (
    <div className='col data-table-search'>
      <Input
        id='search'
        ref={ref}
        placeholder={`Search ${titleText}`}
        value={value || ''}
        onChange={props.onSearchChange} />
      <div className='row'>
        <Button label='Search' onClick={props.onSearch} />
        <Button label='Reset' type='link' onClick={props.onReset} />
        <Button label='Filter' type='link' onClick={props.onFilter} />
        <Button label='Clear' type='link' onClick={props.onClear} />
      </div>
    </div>
  )
}
export const SearchComponent = forwardRef(FilterSearchComponent)