import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../app";

export const TASK_STORE_NAME = 'TASK';
const InitialFilter = { status: 'all' }
const initialState = {
  tasks: null,
  filterModel: Object.assign({}, InitialFilter)
}

const taskSlice = createSlice({
  name: TASK_STORE_NAME,
  initialState: initialState,
  reducers: {
    fetchAllTasks: (state, action) => {
      if (action.payload) {
        state.tasks = null
      }
    },
    fetchTaskById: () => { },
    createTask: () => { },
    updateTask: () => { },
    deleteTask: () => { },
    closeTask: () => { },
    updateDesignerNote: () => { },
    assignDesigner: () => { },
    exportTasks: () => { },
    setTaskFilterModel: (state, action) => {
      state.filterModel = action.payload
    },
    resetTaskFilterModel: (state) => {
      state.filterModel = Object.assign({}, InitialFilter)
    },
    setTasks: (state, action) => {
      state.tasks = action.payload
    },
    setTaskById: (state, action) => {
      const task = action.payload;
      state.tasks = { ...(state.tasks || {}), [task.taskId]: task }
    },
    clear: () => {
      return { ...initialState }
    }
  }
})
export const taskActions = taskSlice.actions;

ReducerRegistry.register(TASK_STORE_NAME, taskSlice.reducer)