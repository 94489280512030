import React from 'react';
import { LoadingGIF } from "../Assets";
import { Modal } from "../Modal";

export class AppErrorBoundry extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo)
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className='col full flex-ctr h-ctr v-ctr'>
          <h1>{this.props.message || 'Something went wrong. Please check logs'}</h1>
        </div>
      );
    }
    return this.props.children;
  }
}
export const Loading = (props) => {
  return (
    <div className={`col w-100 h-100 h-ctr v-ctr loading-indicator ${props.isSmall ? 'small' : ''}`}>
      <LoadingGIF className="loading" />
      <span className={`exo2 ${props.messageClassName || ''}`}>{props.message || 'Please Wait...'}</span>
    </div >
  )
}
export const LoadingModal = (props) => {
  const { open, ...rest } = props
  return (
    <Modal open={open} className='transparent-dialog'>
      <div className='col w-100 h-100 v-ctr h-ctr'>
        <Loading {...rest} messageClassName='cFFF' />
      </div>
    </Modal>
  )
}

