import React, { useState } from 'react';
import LoadingGIFImg from "../../assets/images/loading.gif";
import IcarusLogoImg from "../../assets/images/logo-icarus-white.png";

export const LoadingGIF = (props) => {
  return (
    <img src={LoadingGIFImg} {...props} />
  )
}
export const IcarusLogo = (props) => {
  return (
    <img src={IcarusLogoImg} {...props} />
  )
}