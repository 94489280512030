import { DateService } from "../../services";
const PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID || '';
const getProjectState = (state) => state.PROJECT;
export const getProjectStatusList = (stopped = false) => {
  let list = [
    { label: 'Research', id: 'Research' },
    { label: 'Design', id: 'Design' },
    { label: 'Artworking', id: 'Artworking' },
    { label: 'Completed', id: 'Completed' },
  ]
  if (stopped) {
    list.push({ label: "Stopped", id: 'Stopped' })
  }
  return list
}

export const getAllProjects = (state) => getProjectState(state).projects;

export const getAllProjectsAsArray = (state) => {
  let projects = getAllProjects(state);
  projects = Boolean(projects) ? Object.values(projects) : null;
  const filterModel = getProjectFilter(state);
  if (Array.isArray(projects) && Object.values(filterModel).length) {
    let { dateRangeFor, fromDate, toDate, icarusContact, clients, clientContacts, clientBrands, status, projectExport } = filterModel;
    projects = projects.filter((project) => {
      let inDates = true;
      let inClient = Array.isArray(clients) && clients.length > 0 ? clients.includes(project.client.id) : true;
      let inExecutive = Array.isArray(icarusContact) && icarusContact.length > 0 ? icarusContact.includes(project.icarusContact.id) : true;
      let inClientContact = Array.isArray(clientContacts) && clientContacts.length > 0 ? clientContacts.includes(project.clientContact.id) : true;
      let inClientBrand = Array.isArray(clientBrands) && clientBrands.length > 0 && project.clientBrand ? clientBrands.includes(project.clientBrand.id) : true;
      let inStatus = Array.isArray(status) && status.length > 0 ? status.includes(project.status.id) : true;
      let inUnexported = Array.isArray(projectExport) && projectExport.length > 0 ? (project.stats.designFee.unbilled > 0 || project.stats.ratecard.unbilled > 0) : true;
      if (dateRangeFor && fromDate && toDate) {
        let fromDateObj = DateService(fromDate), toDateObj = DateService(toDate);
        if (dateRangeFor === 'DELIVERABLE' && Array.isArray(project.deliverables)) {
          inDates = project.deliverables.filter((del) => {
            let ratecards = [...(del.expandRatecards || [])];
            ratecards.unshift({ sendDate: del.sendDate });
            return ratecards.filter(({ sendDate }) => {
              let send = DateService(sendDate);
              return (send.isSame(fromDateObj) || send.isSame(toDateObj) || (send.isAfter(fromDateObj) && send.isBefore(toDateObj)));
            }).length > 0
          }).length > 0
        } else if (Array.isArray(project.documentSets)) {
          inDates = project.documentSets.filter((set) => {
            if (!set) {
              return false
            }
            return Object.keys(set).filter((key) => {
              if (key != "note") return false;
              let { uploadDateTime, time } = set[key];
              let dateObj = DateService(uploadDateTime || time || project.startDate);
              return (dateObj.isSame(fromDateObj) || dateObj.isSame(toDateObj) || (dateObj.isAfter(fromDateObj) && dateObj.isBefore(toDateObj)));
            }).length > 0
          })
        }
      }
      return inClient && inExecutive && inClientContact && inClientBrand && inStatus && inUnexported && inDates;
    })
  }
  return projects
}
export const getCurrentProject = (state) => {
  return getProjectState(state).currentProject
}

export const isProjectStopped = (project) => {
  let status = project && project.status;
  status = status && status.id ? status.id : status; 
  return status === 'Stopped'
}
export const isProjectCompleted = (project) => {
  let status = project && project.status;
  status = status && status.id ? status.id : status; 
  return status === 'Completed';
}
export const getProjectUnExport = (state) => {
  return getProjectState(state).unexported
}
export const getProjectDocSetNotes = (notes) => {
  let history = []
  if (typeof notes === 'string') {
    history.push(notes)
  } else {
    if (notes && Array.isArray(notes.history)) {
      history = history.concat(notes.history)
    }
    if (notes && notes.current) {
      history.push(notes.current)
    }
  }
  return history
}
export const getProjectInvoices = (project) => {
  let invoices = [{ label: 'Design Fee (Adv)', id: 'advance' }];
  if (project.invoices && project.invoices.advance) {
    invoices[0].file = project.invoices.advance;
  }
  let parts = (project.invoices && project.invoices.part) || [];
  parts = parts.map((_, i) => {
    return { label: `Design Fee (Part ${i + 1})`, file: _ }
  });
  invoices = invoices.concat(parts);
  invoices.push({ label: `Design Fee (Part ${parts.length + 1})`, id: parts.length + 1 });
  invoices.push({ label: `Design Fee (Full)`, id: "full" });
  if (project.invoices && project.invoices.full) {
    invoices[invoices.length - 1].file = project.invoices.full;
  }
  return invoices
}
export const getDeliverableRCExportCount = (projectId, delId, unexported) => {
  const deliverables = unexported && unexported.deliverables && unexported.deliverables[projectId];
  return deliverables && deliverables[delId] ? Object.keys(deliverables[delId]).length : 0
}
export const getDesignFeeUnexportCount = (projectId, setNo, unexported) => {
  const documentSets = unexported && unexported.documentSets && unexported.documentSets[projectId];
  return documentSets && documentSets[setNo] ? Object.keys(documentSets[setNo]).length : 0
}
export const getProjectFilter = (state) => {
  return getProjectState(state).filterModel
}
const getFilterKey = (userId) => {
  return `icarus-pm-project-filter-${PROJECT_ID}-${userId}`
}
export const getProjectFilterFromStorage = (userId) => {
  let filter = localStorage.getItem(getFilterKey(userId));
  return JSON.parse(filter || '{}')
}
export const setProjectFilterToStorage = (userId, filter) => {
  localStorage.setItem(getFilterKey(userId), JSON.stringify(filter));
}