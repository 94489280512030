import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../app";

const initialState = {
  currentUser: null,
  users: {},
}

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    fetchCurrentUser: (state) => {
      state.currentUser = null
    },
    createUser: () => { },
    updateUser: () => { },
    disableUser: () => { },
    fetchAllUsers: () => { },
    changePassword: () => { },
    setAllUsers: (state, action) => {
      state.users = action.payload
    },
    login: (state) => {
      state.logInStatus = 'wait';
      state.loggedOut = false;
    },
    logout: () => { },
    validateToken: () => { },
    logInSuccess: (state, action) => {
      state.currentUser = action.payload.current_ent_usr;
      state.orgLogo = action.payload.ent_org_logo;
      state.logInStatus = action.payload.status ? 'success' : "failed";
    },
    logInSuccessClear: (state) => {
      state.logInStatus = null
    },
    logInError: (state) => {
      state.logInStatus = 'Error';
    },
    logoutSuccess: (state) => {
      state.isLoggedOut = true;
    },
    clear: () => {
      return { ...initialState }
    }
  }
})
export const userActions = userSlice.actions;

ReducerRegistry.register("user", userSlice.reducer)