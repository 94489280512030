import React from 'react';
import { Collapse } from 'antd';
import { useSelector } from "react-redux";
import { getAllUsersOriginal, getBrands } from "../../store";
import { EditFilled } from '@ant-design/icons';
import { usePub, DataGridView, FormUI, Button } from "../../components";
import { DateService } from "../../services";
import Fields from "./fields.json";

const Columns = [
  { title: 'Client', dataIndex: 'clientName', key: 'clientName', width: '25%', defaultSortOrder: 'descend', sort: true },
  { title: 'Brand Title', dataIndex: 'title', key: 'title', width: '25%', sort: true },
  { title: 'Added', dataIndex: 'createdBy', key: 'createdBy', width: '23%', sort: true },
  { title: 'LAST UPDATED', dataIndex: 'lastUpdatedBy', key: 'lastUpdatedBy', width: '23%', sort: true },
  { title: '', dataIndex: 'actions', key: 'actions', width: '7%', render: 'actions', cellClassName: 'v-ctr h-ctr' }
]

export const ClientBrandsScreen = () => {
  const publish = usePub();
  const brands = useSelector(getBrands);
  const users = useSelector(getAllUsersOriginal);
  const [filter, setFilter] = React.useState({})
  const handleEditBrand = (brand) => {
    publish("BRAND_DIALOG", brand)
  }
  const getRowActions = (brand) => {
    let actions = [], style = { fontSize: 18 };
    actions.push({
      Component: EditFilled, onClick: handleEditBrand.bind(null, brand),
      style: { ...style, color: '#bdbdbd' }
    })
    return actions
  }
  const getColumns = () => {
    return Columns.map((_) => {
      const col = { ..._ };
      if (col.render === 'actions') {
        col.actions = getRowActions.bind(null)
      }
      return col
    })
  }
  const getBrandRows = () => {
    if (Array.isArray(brands)) {
      let rows = [];
      for (let i = 0; i < brands.length; i++) {
        const brand = { ...brands[i] };
        let shouldPush = true;
        if (brand.createdBy) {
          brand.createdBy = users[brand.createdBy];
          brand.createdBy = brand.createdBy ? brand.createdBy.userName : '';
          if (brand.createdTime && brand.createdBy) {
            brand.createdBy += `, ${DateService(brand.createdTime).format('DD/MM/YY, HH:mm')}`
          }
        }
        if (brand.lastUpdatedBy) {
          brand.lastUpdatedBy = users[brand.lastUpdatedBy];
          brand.lastUpdatedBy = brand.lastUpdatedBy ? brand.lastUpdatedBy.userName : '';
          if (brand.lastUpdatedTime) {
            brand.lastUpdatedBy += `, ${DateService(brand.lastUpdatedTime).format('DD/MM/YY, HH:mm')}`
          }
        }
        Object.keys(filter).every((key) => {
          let searchVal = String(filter[key]).toLowerCase();
          let rowVal = String(brand[key]).toLowerCase();
          if (searchVal && !rowVal.includes(searchVal)) {
            shouldPush = false;
          }
          return shouldPush
        })
        if (shouldPush) {
          rows.push(brand);
        }
      }
      return rows;
    }
    return null
  }
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value })
  }
  return (
    <div className='row w-100 h-100 screen-pad v-start o-hide bgE5E5E5'>
      <DataGridView
        className='contacts-table'
        rows={getBrandRows()}
        emptyMessage='No Brands'
        Columns={getColumns()}
        initialPageSize={25}
        rowClassName={(user) => {
          return user.disabled ? 'row-disable' : ''
        }}
      />
      <div className='col filter-section bgFFF'>
        <Collapse
          expandIconPosition='end'
          activeKey='1'>
          <Collapse.Panel
            key="1"
            header={(
              <div className='row h-btn w-100'>
                <span className='bold f14'>FILTER TASKS</span>
                <Button
                  labelClassName='f14 c3d92e0'
                  onClick={() => setFilter({})}
                  label='Reset' link />
              </div>
            )} >
            <FormUI
              show={true}
              Fields={Fields.BrandFilter}
              formData={filter}
              onChange={handleFilterChange}
              className={`row h-btn fwarp`}
              direction={'vertical'}
            />
          </Collapse.Panel>
        </Collapse>
      </div>
    </div>
  )
}